<lgv-loading-bar></lgv-loading-bar>
<mat-toolbar [color]="themeService.isDarkMode() ? 'primary' : 'accent'" style="height: 64px">
  <button mat-icon-button (click)="expectedChanged()"><mat-icon>menu</mat-icon></button>
  <div *ngIf="!isProduction" style="color: #F44336; font-size: 36px; font-weight: 800;">DEV</div>
  <div style="padding-left: 10px">
    <xng-breadcrumb [separator]="separatorTemplate" [preserveQueryParams]="false">
      <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container>{{ breadcrumb | translate | titlecase }}</ng-container>
      </ng-container>
    </xng-breadcrumb>
    <ng-template #separatorTemplate>
      <mat-icon>chevron_right</mat-icon>
    </ng-template>
  </div>
  <div style="flex-grow: 1"></div>
  <img src="assets/images/logo-black.png" style="max-height: 100%" />
</mat-toolbar>
<div style="flex-grow: 1; display: flex">
  <mat-sidenav #sidenav mode="side" opened="true" id="sidenav">
    <lgv-navigation-item [isExpanded]="isExpanded" [item]="item" *ngFor="let item of navItems"> </lgv-navigation-item>
    <div style="flex-grow: 1"></div>
    <mat-divider></mat-divider>
    <lgv-navigation-item [isExpanded]="isExpanded" [item]="navItemsBottom[0]"> </lgv-navigation-item>
    <lgv-navigation-item [isExpanded]="isExpanded" [item]="navItemsBottom[1]" [matMenuTriggerFor]="menu">
    </lgv-navigation-item>
  </mat-sidenav>
  <div style="flex-grow: 1; display: flex; flex-direction: column">
    <router-outlet></router-outlet>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="updateUserProfileEvent()">
      <mat-icon>edit</mat-icon>
      <span translate>dashboard_nav_menu_editprofile</span>
    </button>
    <button mat-menu-item (click)="switchMode()">
      <mat-icon>bedtime</mat-icon>
      <span translate>dashboard_nav_menu_switchmode</span>
    </button>
    <button mat-menu-item (click)="changePassword()">
      <mat-icon>lock</mat-icon>
      <span translate>dashboard_nav_menu_changepassword</span>
    </button>
    <button mat-menu-item routerLink="/logout">
      <mat-icon>logout</mat-icon>
      <span translate>dashboard_nav_menu_logout</span>
    </button>
  </mat-menu>
</div>