import { Component, Input } from '@angular/core';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss'],
})
export class AuthWrapperComponent {
  @Input() text: MessageIDS;
}
