/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { LocaleManagerSingleton } from '@bryntum/calendar';
import { TranslateService } from '@ngx-translate/core';
import { CalendarLocaleDE } from '@shared/utils/bryntum-locales/custom.locale.De';
import { CalendarLocaleIT } from '@shared/utils/bryntum-locales/custom.locale.It';

import { Locale, de, enGB, it } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  selectedLocale: Locale;
  weekArrayLocale = [];
  monthArrayLocale = [];
  browserLang = 'en';
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'it', 'de']);
    this.initLocaleDateFns();
    this.initMonthArray();
    this.initWeekArray();
    this.initTranslate();
    this.browserLang = this.translate.getBrowserLang();
    this.changeLanguage(this.browserLang);
  }

  changeLanguage(lang: string): void {
    this.browserLang = lang;
    this.initTranslate();
    this.initLocaleDateFns();
    this.initMonthArray();
    this.initWeekArray();
  }

  initCalendar(): string {
    switch (this.browserLang) {
      case 'de':
        return 'de';
      case 'it':
        return 'it';
      default:
        return 'en';
    }
  }

  initTranslate() {
    if (this.translate.getLangs().includes(this.browserLang)) {
      this.translate.use(this.browserLang);
    }
  }

  initCalendarPro(localeManager: LocaleManagerSingleton, localeHelper: any): void {
    switch (this.browserLang) {
      case 'de':
        localeHelper.publishLocale(CalendarLocaleDE);
        localeManager.applyLocale('De');
        break;
      case 'it':
        localeHelper.publishLocale(CalendarLocaleIT);
        localeManager.applyLocale('It');
        break;
      default:
        localeManager.applyLocale('En');
    }
  }

  initLocaleDateFns() {
    const browserLang = this.translate.getBrowserLang();
    switch (browserLang) {
      case 'de':
        this.selectedLocale = de;
        break;
      case 'it':
        this.selectedLocale = it;
        break;
      default:
        this.selectedLocale = enGB;
        break;
    }
  }

  initMonthArray(): void {
    this.monthArrayLocale = [];
    for (let i = 1; i <= 12; i++) {
      const objDate = new Date();
      objDate.setDate(1);
      objDate.setMonth(i - 1);

      const month = objDate.toLocaleString(this.browserLang, { month: 'short' });
      this.monthArrayLocale.push(month);
    }
  }

  initWeekArray(): void {
    this.weekArrayLocale = [];
    for (let i = 0; i < 7; i++) {
      const objDate = new Date(2021, 1, 7);
      objDate.setDate(objDate.getDate() + i);
      const day = objDate.toLocaleString(this.browserLang, { weekday: 'short' });
      this.weekArrayLocale.push(day);
    }
  }
}
