import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageIDS } from 'src/app/app.module';

export interface IMessageDialogData {
  title: MessageIDS;
  message: MessageIDS;
  continueButtonText: MessageIDS;
  cancleButtonText: MessageIDS;
}

@Component({
  selector: 'lgv-message-dialog',
  templateUrl: './message-dialog.component.html',
})
export class MessageDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IMessageDialogData
  ) {}

  close(): void {
    this.dialogRef.close(false);
  }
  continue(): void {
    this.dialogRef.close(true);
  }
}
