import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICustomSnackBar, SnackbarService } from '@shared/services/snackbar.service';
import { ThemeService } from '@shared/services/theme.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { LocalizationService } from '@app/services/localization.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@shared/services/general.service';

@Component({
  selector: 'lgv-root',
  template: `
    <router-outlet> </router-outlet>
    <div
      *ngIf="generalService.blockInput"
      (click)="$event.stopPropagation()"
      style="z-index:100; position: absolute; width: 100vw; height: 100vh;"
    ></div>
  `,
  styles: [
    `
      :host {
        flex-grow: 1;
        display: flex;
      }
    `,
  ],
})
export class AppComponent {
  constructor(
    public snackBar: MatSnackBar,
    public snackBarService: SnackbarService,
    private themeService: ThemeService,
    private translationService: TranslateService,
    public generalService: GeneralService,
    private localizationService: LocalizationService, // dont remove the line this triggers the init of the translation
    private breadcrumbService: BreadcrumbService, // dont remove this line or it will break the init breadcrumb
  ) {
    this.themeService.initTheme();
    this.snackBarService.toast.subscribe((toast: ICustomSnackBar) => {
      this.translationService.get(toast.message).subscribe((translatedMessage) => {
        this.snackBar.open(translatedMessage, toast.action, {
          duration: toast.duration,
          panelClass: [toast.type],
        });
      });
    });
    registerLocaleData(localeDe, 'de', localeDeExtra);
    registerLocaleData(localeIt, 'it', localeItExtra);
  }
}
