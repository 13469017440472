/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { CmsGuard } from './guards/cms.guard';
import { NoAuthGuard } from './guards/no-auth.guard';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import ${moduleName} modules in the AppModule only.`);
  }
}

@NgModule({ declarations: [], imports: [], providers: [AuthGuard, NoAuthGuard, CmsGuard, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
