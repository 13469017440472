import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetRequestResponseItem } from '@schema/APITypes';

@Component({
  selector: 'lgv-request-table-more-item',
  template: `
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="editClicked.emit()">
        <mat-icon class="material-icons-outlined">edit</mat-icon>
        <span translate>shared_component_generictable_edit</span>
      </button>
      <button mat-menu-item (click)="deleteClicked.emit()" *ngIf="item.status !== 'confirmed'">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span translate>shared_component_generictable_delete</span>
      </button>
      <button mat-menu-item (click)="archiveClicked.emit()" *ngIf="item.status === 'confirmed'">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
        <span translate>shared_component_generictable_archive</span>
      </button>
      <button mat-menu-item (click)="unarchiveClicked.emit()" *ngIf="item.status === 'archived'">
        <mat-icon class="material-icons-outlined">unarchive</mat-icon>
        <span translate>shared_component_generictable_reactivate</span>
      </button>
    </mat-menu>
  `,
})
export class RequestTableMoreItemComponent {
  @Input() item: GetRequestResponseItem;
  @Output() editClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() archiveClicked = new EventEmitter();
  @Output() unarchiveClicked = new EventEmitter();
}
