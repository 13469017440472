/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Pipe, PipeTransform } from '@angular/core';
import { DocumentService } from '@app/services/document.service';

@Pipe({
  name: 'getDocument',
})
export class GetDocumentPipe implements PipeTransform {
  constructor(private documentService: DocumentService) {}
  async transform(value: any, args?: any): Promise<any> {
    if (value) {
      const reponse = await this.documentService.getSignedUrl(value);
      return reponse?.url;
    } else {
      return undefined;
    }
  }
}
