import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumn, TableModel } from '@schema/table.models';
import { BehaviorSubject } from 'rxjs';
import { TextClickClickedItem } from '../generic-table.component';

@Component({
  selector: 'lgv-sub-table-item',
  template: `
    <lgv-generic-table
      *ngIf="tableModel?.content?.length > 0"
      [tableDataSubject]="tableModelChanged"
      [isSubtable]="true"
      (textClickClicked)="textClickClicked.emit($event)"
    >
    </lgv-generic-table>
  `,
  styles: [
    `
          :host {
            display: flex;
            margin-bottom: 24px;
          }
        `,
  ],
})
export class SubTableItemComponent {
  @Input()
  set displayedColumns(displayedColumns: TableColumn[]) {
    this.tableModel.displayedColumns = displayedColumns;
  }

  @Input()
  set data(data: any[]) {
    this.tableModel.content = data;
    this.tableModelChanged.next(this.tableModel);
  }
  @Output() textClickClicked = new EventEmitter<TextClickClickedItem>();

  tableModelChanged = new BehaviorSubject<TableModel>(null);
  tableModel: TableModel = {
    content: [],
    trackById: 'type',
    displayedColumns: [],
    isPaginatorVisible: false,
    clickableRow: false,
    scrollMode: true,
    centerContent: true,
  };
}
