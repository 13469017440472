import { Component, Input } from '@angular/core';
import { DocumentService } from '@app/services/document.service';
import { LoadingBarService } from '@shared/services/loading-bar.service';

@Component({
  selector: 'lgv-download-table-item',
  template: ` <div *ngIf="value" (click)="onClick()" class="file">{{ value?.name }}</div>
    <div *ngIf="!value">-</div>`,
  styles: [
    `
          .file {
            text-decoration: underline;
            cursor: pointer;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `,
  ],
})
export class DownloadTableItemComponent {
  @Input() value: { id: string; name: string };

  constructor(private documentService: DocumentService, private loadingBarService: LoadingBarService) {}

  async onClick() {
    this.loadingBarService.isLoading.next(true);
    const response = await this.documentService.getSignedUrl(this.value.id);
    await this.documentService.downloadDocument(response.url, this.value.name);
    this.loadingBarService.isLoading.next(false);
  }
}
