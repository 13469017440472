/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LocalizationService } from '@app/services/localization.service';
import {
  addDays,
  addMonths,
  addYears,
  format,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  parse,
  setMonth,
  toDate,
} from 'date-fns';

// CONFIG. Use environment or something for a dynamic locale and settings

const WEEK_STARTS_ON = 1; // 0 sunday, 1 monday...

export const MAT_DATE_FNS_DATE_FORMATS_SMALL = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'LLL y',
    dateA11yLabel: 'MMMM d, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

export const MAT_DATE_FNS_DATE_FORMATS_BIG = {
  parse: {
    dateInput: 'dd.MM.yyyy HH:mm',
  },
  display: {
    dateInput: 'dd.MM.yyyy HH:mm',
    monthYearLabel: 'LLL y',
    dateA11yLabel: 'MMMM d, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

export const MAT_DATE_FNS_DATE_FORMATS_MONTH_ONLY = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/yyyy',
    monthYearLabel: 'MM/yyyy',
    dateA11yLabel: 'MM/yyyy',
    monthYearA11yLabel: 'MM/yyyy',
  },
};

export const MAT_DATE_FNS_DATE_FORMATS_YEAR_ONLY = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'yyyy',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'yyyy',
    monthYearA11yLabel: 'yyyy',
  },
};

function range(start: number, end: number): number[] {
  const arr: number[] = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
}

// function getLocale(): any {
//   const userLang = navigator.language;

//   if (userLang.startsWith('de')) {
//     return de;
//   }
//   if (userLang.startsWith('it')) {
//     return it;
//   }
//   return enGB;
// }

@Injectable()
export class DateFnsDateAdapter extends DateAdapter<Date> {
  constructor(private localizationService: LocalizationService) {
    super();
  }

  addCalendarDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  addCalendarMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  addCalendarYears(date: Date, years: number): Date {
    return addYears(date, years);
  }

  clone(date: Date): Date {
    return toDate(date);
  }

  createDate(year: number, month: number, date: number): Date {
    return new Date(year, month, date);
  }

  format(date: Date, displayFormat: any): string {
    const locale = this.localizationService.selectedLocale;
    return format(date, displayFormat, {
      locale,
    });
  }

  getDate(date: Date): number {
    return getDate(date);
  }

  getDateNames(): string[] {
    return range(1, 31).map((day) => String(day));
  }

  getDayOfWeek(date: Date): number {
    return parseInt(format(date, 'i'), 10);
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return this.localizationService.weekArrayLocale;
    // return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }

  getFirstDayOfWeek(): number {
    return WEEK_STARTS_ON;
  }

  getMonth(date: Date): number {
    return getMonth(date);
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return this.localizationService.monthArrayLocale;
  }

  getNumDaysInMonth(date: Date): number {
    return getDaysInMonth(date);
  }

  getYear(date: Date): number {
    return getYear(date);
  }

  getYearName(date: Date): string {
    const locale = this.localizationService.selectedLocale;
    return format(date, 'yyyy', {
      locale,
    });
  }

  invalid(): Date {
    return new Date(NaN);
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  parse(value: any, parseFormat: any): Date | null {
    const locale = this.localizationService.selectedLocale;

    return parse(value, parseFormat, new Date(), {
      locale,
    });
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  today(): Date {
    return new Date();
  }
}
