import { Injectable } from '@angular/core';

type ILocalStorageKeys =
  | 'accessToken'
  | 'refreshToken'
  | 'expanded'
  | 'client'
  | 'employee'
  | 'reporting'
  | 'reporting2';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  save(name: ILocalStorageKeys, data: string): void {
    localStorage.setItem(name, data);
  }

  get(name: ILocalStorageKeys): string {
    return localStorage.getItem(name);
  }

  remove(name: ILocalStorageKeys): void {
    localStorage.removeItem(name);
  }

  clearAll(): void {
    localStorage.clear();
  }
}
