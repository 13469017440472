import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { ADMIN_PATHS } from './admin.paths';
import { AdminMainSiteComponent } from './pages/admin-main-site/admin-main-site.component';
import { AdminUsersComponent } from './pages/admin-users/admin-users.component';
import { AdminOrganisationsComponent } from './pages/admin-organisations/admin-organisations.component';

export const ADMIN_ROUTES: Routes = [
  {
    path: ADMIN_PATHS.ADMIN_ROOT,
    component: AdminMainSiteComponent,
    pathMatch: 'full',
  },
  {
    path: ADMIN_PATHS.ADMIN_USER,
    component: AdminUsersComponent,
    pathMatch: 'full',
    data: { breadcrumb: 'admin_routing_users' },
  },
  {
    path: ADMIN_PATHS.ADMIN_ORGANISATION,
    component: AdminOrganisationsComponent,
    pathMatch: 'full',
    data: { breadcrumb: 'admin_routing_organisations' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(ADMIN_ROUTES)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
