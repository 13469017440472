import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lgv-icon-table-item',
  template: ` <button mat-icon-button color="primary" (click)="onClicked.emit()" [matTooltip]="tooltip">
    <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
  </button>`,
})
export class IconTableItemComponent {
  @Input() icon: string;
  @Input() tooltip: string;
  @Output() onClicked = new EventEmitter();
}
