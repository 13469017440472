import { UntypedFormControl } from '@angular/forms';

export const CustomValidators = {
  notOnlyWhiteSpaceValidator(control: UntypedFormControl): {
    whitespace: boolean;
  } {
    // If the input is empty there can't be only whitespaces.
    if (control.value === '') {
      return null;
    }

    const isWhiteSpaceOnly = (control.value || '').trim().length === 0;
    const isValid = !isWhiteSpaceOnly;
    return isValid ? null : { whitespace: true };
  },

  valueIsAnObject(control: UntypedFormControl): {
    valueIsAnObject: boolean;
  } {
    return typeof control.value === 'object' ? null : { valueIsAnObject: true };
  },

  phonePattern: '^((\\+43?)|0043)?[ _0-9][ _0-9]+$',
  foundingPattern: '^[12][0-9]{3}$',
};
