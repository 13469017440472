import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { GetPreSignedDocumentUrlResponse, PostDocumentResponse, PutDocumentPayload } from '@schema/APITypes';
import saveAs from 'file-saver';
import { lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  postDocument(file: File): Promise<PostDocumentResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.post('documents', formData);
  }

  getSignedUrl(documentID: string): Promise<GetPreSignedDocumentUrlResponse> {
    return this.apiService.get(`documents/${documentID}/signed`);
  }

  getDocumentAsset(documentID: string): Promise<Blob> {
    const url = `${environment.API_BASE_URL}documents/${documentID}`;
    this.apiService.get(`documents/private/${documentID}/token`);
    return lastValueFrom(this.http.get(url, { responseType: 'blob' }));
  }

  async downloadDocument(url: string, filename: string): Promise<void> {
    const blob = await lastValueFrom(this.http.get(url, { responseType: 'blob' }));
    return saveAs(blob, filename);
  }

  putDocument(noteId: string, payload: PutDocumentPayload): Promise<void> {
    return this.apiService.put(`documents/${noteId}`, payload);
  }
}
