<lgv-dialog-wrapper [titleText]="data.title">
  <p translate>{{ data.message }}</p>

  <lgv-button-container
    [buttonLeftText]="data.cancleButtonText"
    [buttonRightText]="data.continueButtonText"
    [buttonLeftVisible]="!!data.cancleButtonText"
    (onLeftClicked)="close()"
    (onRightClicked)="continue()"
  >
  </lgv-button-container>
</lgv-dialog-wrapper>
