import { Component, Input } from '@angular/core';

@Component({
  selector: 'lgv-bool-table-item',
  template: ' <mat-icon *ngIf="value" class="material-icons-outlined">done</mat-icon> ',
  styles: [':host{display:flex}'],
})
export class BoolTableItemComponent {
  @Input() value: boolean;
}
