import { APP_PATHS } from 'src/app/app.paths';

export const DASHBOARD_PATHS = {
  DASHBOARD_ROOT: '',
  DASHBOARD_ORGANISATION: 'organisation',
  DASHBOARD_EMPLOYEE: 'employee',
  DASHBOARD_CLIENT: 'client',
  DASHBOARD_USERS: 'users',
  DASHBOARD_FORMS: 'forms',

  DASHBOARD_SCHEDULING_PRO: 'scheduling-pro',
  DASHBOARD_SCHEDULING_ABSENCE: 'scheduling-absence',
  DASHBOARD_REPORTING2: 'reporting2',
  DASHBOARD_TEMPLATES: 'templates',
  DASHBOARD_TEMPLATES_DETAILS: 'templates/:id',

  DASHBOARD_EMPLOYEE_DETAILS: 'employee/:id',
  DASHBOARD_EMPLOYEE_REPORTING2: 'employee/:id/reporting-new/:month',
  DASHBOARD_EMPLOYEE_TEMPLATE: 'employee/:id/requesttemplate/:requestTemplateId',

  DASHBOARD_CLIENT_DETAILS: 'client/:id',
  DASHBOARD_CLIENT_REPORTING2: 'client/:id/reporting-new/:month',
  DASHBOARD_CLIENT_TEMPLATE: 'client/:id/requesttemplate/:requestTemplateId',
};

export const DASHBOARD_PATHS_ABSOLUTE = {
  DASHBOARD_ROOT: `${APP_PATHS.DASHBOARD}`,
  DASHBOARD_SCHEDULING_PRO: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_SCHEDULING_PRO}`,
  DASHBOARD_SCHEDULING_ABSENCE: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_SCHEDULING_ABSENCE}`,
  DASHBOARD_EMPLOYEE: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_EMPLOYEE}`,
  DASHBOARD_CLIENT: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_CLIENT}`,
  DASHBOARD_ORGANISATION: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_ORGANISATION}`,
  DASHBOARD_TEMPLATES: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_TEMPLATES}`,
  DASHBOARD_TEMPLATES_DETAILS: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_TEMPLATES_DETAILS}`,

  DASHBOARD_EMPLOYEE_DETAILS: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_EMPLOYEE_DETAILS}`,
  DASHBOARD_EMPLOYEE_REPORTING2: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_EMPLOYEE_REPORTING2}`,
  DASHBOARD_EMPLOYEE_TEMPLATE: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_EMPLOYEE_TEMPLATE}`,

  DASHBOARD_CLIENT_DETAILS: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_CLIENT_DETAILS}`,
  DASHBOARD_CLIENT_REPORTING2: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_CLIENT_REPORTING2}`,
  DASHBOARD_CLIENT_TEMPLATE: `${APP_PATHS.DASHBOARD}/${DASHBOARD_PATHS.DASHBOARD_CLIENT_TEMPLATE}`,
};
