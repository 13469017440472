import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss'],
})
export class ButtonContainerComponent {
  @Input() buttonLeftText: MessageIDS;
  @Input() buttonRightText: MessageIDS | string;
  @Input() disabled: boolean;
  @Input() buttonLeftVisible = true;
  @Input() buttonRightVisible = true;
  @Input() buttonLeftType: 'button' | 'submit' = 'button';
  @Input() buttonRightType: 'button' | 'submit' = 'button';
  @Output() onLeftClicked = new EventEmitter();
  @Output() onRightClicked = new EventEmitter();
}
