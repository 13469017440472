<lgv-dialog-wrapper titleText="dashboard_dialogs_userprofile_title">
  <lgv-profile-image-picker (fileChanged)="onFileSelected($event)"
    [imageURL]="data.currentUser?.imageID | getDocument | async"
    style="justify-content: center; margin-bottom: 32px"></lgv-profile-image-picker>

  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label translate>dashboard_dialogs_userprofile_firstname</mat-label>
      <input matInput formControlName="firstname" />
      <mat-error *ngIf="this.getField('firstname').invalid" translate> dashboard_dialogs_userprofile_error </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>dashboard_dialogs_userprofile_lastname</mat-label>
      <input matInput formControlName="lastname" />
      <mat-error *ngIf="this.getField('lastname').invalid" translate> dashboard_dialogs_userprofile_error </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>dashboard_dialogs_userprofile_role</mat-label>
      <mat-select formControlName="scope">
        <mat-option *ngFor="let item of roles" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="this.getField('scope').hasError('required')" translate>
        dashboard_dialogs_userprofile_error
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>dashboard_dialogs_userprofile_email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="this.getField('email').invalid" translate> dashboard_dialogs_userprofile_error </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>dashboard_dialogs_userprofile_language</mat-label>
      <mat-select formControlName="language">
        <mat-option value="de">
          Deutsch
        </mat-option>
        <mat-option value="en">
          English
        </mat-option>
        <mat-option value="it">
          Italiano
        </mat-option>
      </mat-select>
    </mat-form-field>

    <lgv-button-container buttonLeftText="dashboard_dialogs_userprofile_buttonleft"
      buttonRightText="dashboard_dialogs_userprofile_buttonright" buttonRightType="submit" [disabled]="isLoading"
      (onLeftClicked)="close()" (onRightClicked)="submit()">
    </lgv-button-container>
  </form>
</lgv-dialog-wrapper>