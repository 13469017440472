import { Component } from '@angular/core';
import { DASHBOARD_PATHS } from '@modules/dashboard/dashboard.paths';
import { NavItem } from '@schema/nav.models';
import { PermissionService } from '@shared/services/permission.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lgv-dashboard-wrapper',
  template: ' <lgv-navigation  [navItems]="navItems"> </lgv-navigation> ',
  styles: [
    `
      :host {
        flex-grow: 1;
        display: flex;
      }
    `,
  ],
})
export class DashboardWrapperComponent {
  constructor(private permissionService: PermissionService) {}

  navItems: NavItem[] = [
    // {
    //   text: 'Home',
    //   iconText: 'home',
    //   route: '/dashboard',
    //   disabled: false,
    //   routerActive: false,
    //   hiddeBadge: true,
    //   badgeCount: 0,
    // },
    {
      text: 'dashboard_nav_client',
      iconText: 'local_hospital',
      route: DASHBOARD_PATHS.DASHBOARD_CLIENT,
      visible: new BehaviorSubject(true),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_employee',
      iconText: 'face',
      route: DASHBOARD_PATHS.DASHBOARD_EMPLOYEE,
      visible: new BehaviorSubject(true),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_schedulingpro',
      iconText: 'calendar_today',
      route: DASHBOARD_PATHS.DASHBOARD_SCHEDULING_PRO,
      visible: this.permissionService.hasPermissionObservable('calendar_pro'),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_schedulingabsence',
      iconText: 'beach_access',
      route: DASHBOARD_PATHS.DASHBOARD_SCHEDULING_ABSENCE,
      visible: this.permissionService.hasPermissionObservable('calendar_pro'),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_reporting',
      iconText: 'insights',
      route: DASHBOARD_PATHS.DASHBOARD_REPORTING2,
      visible: this.permissionService.hasPermissionObservable('reporting_new'),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_templates',
      iconText: 'article',
      route: DASHBOARD_PATHS.DASHBOARD_TEMPLATES,
      visible: this.permissionService.hasPermissionObservable('paper_template'),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },

    // {
    //   text: 'dashboard_nav_chat',
    //   iconText: 'chat',
    //   route: DASHBOARD_PATHS.DASHBOARD_MESSAGES,
    //   disabled: true,
    //   routerActive: true,
    //   hiddeBadge: false,
    //   badgeCount: 2,
    // },
    // {
    //   text: 'dashboard_nav_notifications',
    //   iconText: 'notifications',
    //   route: DASHBOARD_PATHS.DASHBOARD_NOTIFICATION,
    //   disabled: true,
    //   routerActive: true,
    //   hiddeBadge: false,
    //   badgeCount: 3,
    //   badgeWarn: true,
    // },
    {
      text: 'dashboard_nav_forms',
      iconText: 'view_stream',
      route: DASHBOARD_PATHS.DASHBOARD_FORMS,
      visible: this.permissionService.includeDomainObservable('@longevo.at'),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'dashboard_nav_users',
      iconText: 'people',
      route: DASHBOARD_PATHS.DASHBOARD_USERS,
      visible: new BehaviorSubject(true),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    // {
    //   text: 'Settings',
    //   iconText: 'settings',
    //   route: DASHBOARD_PATHS.DASHBOARD_SETTINGS,
    //   disabled: false,
    //   routerActive: true,
    //   hiddeBadge: true,
    //   badgeCount: 0,
    // },
  ];
}
