import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GetUserInfoResponse,
  GetUserResponseItem,
  PatchOrganisationUsersPayload,
  PostRegistrationLinkPayload,
} from '@schema/APITypes';
import { CustomImage } from '@schema/image.modules';
import { AuthService } from '@app/services/auth.service';
import { DocumentService } from '@app/services/document.service';
import { CustomValidators } from '@shared/validators/customvalidators';
import { OrganisationService } from '@app/services/organisation.service';

export interface IAddEditUserDialogData {
  currentUser?: GetUserInfoResponse;
  user?: GetUserResponseItem;
}

@Component({
  selector: 'lgv-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
})
export class AddEditUserDialogComponent implements OnInit {
  isLoading = false;
  image: CustomImage;

  formGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl(this.data.user?.firstname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    lastname: new UntypedFormControl(this.data.user?.lastname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    email: new UntypedFormControl(this.data.user?.email, [
      Validators.required,
      Validators.email,
      Validators.maxLength(2000),
    ]),
    scope: new UntypedFormControl(this.data.user?.scopes, [Validators.required]),
  });

  roles = ['orgAdmin', 'caseManager'];

  constructor(
    private dialogRef: MatDialogRef<AddEditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddEditUserDialogData,
    private documentService: DocumentService,
    private authService: AuthService,
    private organisationService: OrganisationService
  ) {}

  ngOnInit(): void {
    if (this.data.currentUser && this.data.currentUser?.id === this.data.user?.id) {
      this.formGroup.get('scope').disable();
    }
  }

  onFileSelected(image: CustomImage): void {
    this.image = image;
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      try {
        this.isLoading = true;
        if (this.data.user) {
          const payload: PatchOrganisationUsersPayload = {
            firstname: this.formGroup.get('firstname').value,
            lastname: this.formGroup.get('lastname').value,
            email: this.formGroup.get('email').value,
            imageID: this.data.user.imageID,
            isActive: this.data.user.isActive,
            scopes: this.formGroup.get('scope').value,
          };
          if (this.image) {
            const response = await this.documentService.postDocument(this.image.file);
            payload.imageID = response.id;
          }

          await this.organisationService.patchUser(this.data.user.id, payload);
        } else {
          const payload: PostRegistrationLinkPayload = {
            firstname: this.formGroup.get('firstname').value,
            lastname: this.formGroup.get('lastname').value,
            email: this.formGroup.get('email').value,
            organisationID: this.data.currentUser.organisation.id,
            scope: this.formGroup.get('scope').value,
          };
          if (this.image) {
            const response = await this.documentService.postDocument(this.image.file);
            payload.imageID = response.id;
          }
          await this.authService.registrationLink(payload);
        }

        this.dialogRef.close({ message: 'ok' });
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
}
