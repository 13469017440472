import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { GeneralService } from '@shared/services/general.service';
import { Utils } from '@shared/utils/utils';
import { MessageIDS } from 'src/app/app.module';

export interface SelectOption {
  key: string;
  value: MessageIDS;
}
@Component({
  selector: 'lgv-custom-select-field',
  template: `
    <mat-form-field [appearance]="appearance">
      <mat-label>{{ text | translate }}</mat-label>
      <mat-select [required]="isRequired()" [formControl]="fc">
        <mat-option [value]="item.key" *ngFor="let item of items">{{ item.value | translate }}</mat-option>
      </mat-select>
      <mat-error *ngIf="fc.invalid"> {{ errorMessage | translate }} </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      mat-form-field {
        flex-grow: 1;
      }
    `,
  ],
})
export class CustomSelectFieldComponent {
  @Input() text: MessageIDS;
  @Input() fc: UntypedFormControl;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() errorMessage: MessageIDS;
  @Input() items: SelectOption[];

  constructor(public generalService: GeneralService) {}

  isRequired(): boolean {
    return Utils.isRequiredControl(this.fc);
  }
}
