import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as countryList from 'country-list';
import { KeyValueItem } from '../custom-form-field/custom-autocomplete/custom-autocomplete.component';

@Component({
  selector: 'lgv-personal-information-form',
  templateUrl: './personal-information-form.component.html',
})
export class PersonalInformationFormComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() client: boolean;
  countries: KeyValueItem[] = Object.keys(countryList.getCodeList()).map((c) => {
    return {
      key: countryList.getCodeList()[c],
      value: countryList.getCodeList()[c],
    } as KeyValueItem;
  });

  getFormControls(str: string): UntypedFormControl {
    return this.formGroup.controls[str] as UntypedFormControl;
  }
}
