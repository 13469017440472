import { Component, Input } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'lgv-date-table-item',
  template: '{{getDate()}}',
  styleUrls: [],
})
export class DateTableItemComponent {
  @Input() date: Date;
  @Input() format: string;

  getDate(): string {
    if (this.date && this.format) {
      return format(new Date(this.date), this.format);
    } else {
      return '-';
    }
  }
}
