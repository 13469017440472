<lgv-auth-wrapper text="auth_forgotpassword_headline">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill">
      <mat-label translate>auth_forgotpassword_email_label</mat-label>
      <input matInput formControlName="email" type="email" />
      <mat-error *ngIf="this.formGroup.get('email').invalid" translate>auth_forgotpassword_email_error </mat-error>
    </mat-form-field>
    <lgv-auth-button [isLoading]="isLoading"></lgv-auth-button>
  </form>
  <button mat-button color="primary" routerLink="/">{{ 'auth_forgotpassword_button_login' | translate }}</button>
</lgv-auth-wrapper>
