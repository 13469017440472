import { LocaleHelper } from '@bryntum/calendar';
import En from '@bryntum/calendar/locales/calendar.locale.En.js';
const De = {
  localeName: 'De',
  localeDesc: 'Deutsch',
  // region Shared localization

  Button: {
    'Add column': 'Spalte hinzufügen',
    'Show all day events': 'Zeige alle tagesereignisse',
    Apply: 'Anwenden',
    'Display hints': 'Tipps anzeigen',
    'My button': function MyButton(data) {
      return 'Mein knopf '.concat(data);
    },
    'Remove column': 'Spalte entfernen',
  },
  EventEdit: {
    Name: 'Name',
    Resource: 'Ressource',
    Start: 'Start',
    End: 'Ende',
    Save: 'Speichern',
    Delete: 'Löschen',
    Cancel: 'Abbrechen',
    'Edit event': 'Bearbeiten',
    Repeat: 'Wiederholen',
    Calendar: 'Kalender',
    'All day': 'Den ganzen tag',
  },
  SchedulerBase: {
    'Delete event': 'Löschen',
  },
  Checkbox: {
    'Auto apply': 'Automatisch anwenden',
    Automatically: 'Automatisch',
  },
  Combo: {
    noResults: 'Keine Ergebnisse',
    Theme: 'Thema wählen',
    Language: 'Gebietsschema auswählen',
    Size: 'Wähle die Größe aus',
    recordNotCommitted: 'Datensatz konnte nicht hinzugefügt werden',
    addNewValue: function addNewValue(value) {
      return ''.concat(value, ' hinzuf\xFCgen');
    },
  },
  Tooltip: {
    'Click to show info and switch theme or locale':
      'Klicken Sie hier, um Informationen anzuzeigen und das Thema oder Gebietsschema zu wechseln',
    'Click to show the built in code editor': 'Klicken Sie hier, um den integrierten Code-Editor anzuzeigen',
    CheckAutoHints: 'Aktivieren Sie diese Option, um beim Laden des Beispiels automatisch Hinweise anzuzeigen',
    Fullscreen: 'Ganzer Bildschirm',
  },
  Shared: {
    'Locale changed': 'Sprache geändert',
    'Full size': 'Volle Größe',
    'Phone size': 'Telefongröße',
  },
  UndoRedo: {
    Undo: 'Rückgängig machen',
    Redo: 'Wiederholen',
    UndoLastAction: 'Letzte Aktion rückgängig machen',
    RedoLastAction: 'Wiederholen Sie die letzte rückgängig gemachte Aktion',
  },
  // endregion
  // Translations for common words and phrases which are used by all classes.
  Object: {
    Yes: 'Ja',
    No: 'Nein',
    Cancel: 'Stornieren',
    Ok: 'OK',
    newEvent: 'Neue aufgabe',
    Week: 'Woche', // used in DateHelper and Calendar views
  },
  // region Features
  ColumnPicker: {
    column: 'Splate',
    columnsMenu: 'Spalten',
    hideColumn: 'Versteck spalte',
    hideColumnShort: 'Versteck',
    newColumns: 'Neue Spalten',
  },
  Filter: {
    applyFilter: 'Filter anwenden',
    filter: 'Filter',
    editFilter: 'Filter redigieren',
    on: 'Auf',
    before: 'Vor',
    after: 'Nach',
    equals: 'Gleichen',
    lessThan: 'Weniger als',
    moreThan: 'Mehr als',
    removeFilter: 'Filter entfernen',
  },
  FilterBar: {
    enableFilterBar: 'Filterleiste anzeigen',
    disableFilterBar: 'Filterleiste ausblenden',
  },
  Group: {
    group: 'Gruppe',
    groupAscending: 'Aufsteigend gruppieren',
    groupDescending: 'Absteigend gruppieren',
    groupAscendingShort: 'Aufsteigend',
    groupDescendingShort: 'Absteigend',
    stopGrouping: 'Stoppen gruppierung',
    stopGroupingShort: 'Stoppen',
  },
  Search: {
    searchForValue: 'Suche nach Wert',
  },
  Sort: {
    sort: 'Sorte',
    sortAscending: 'Aufsteigend sortierung',
    sortDescending: 'Absteigend sortierung',
    multiSort: 'Multi sortieren',
    removeSorter: 'Sortierung entfernen',
    addSortAscending: 'Aufsteigend sortieren hinzufügen',
    addSortDescending: 'Absteigend sortieren hinzufügen',
    toggleSortAscending: 'Ändern Sie auf aufsteigend',
    toggleSortDescending: 'Zu absteigend wechseln',
    sortAscendingShort: 'Aufsteigend',
    sortDescendingShort: 'Absteigend',
    removeSorterShort: 'Entfernen',
    addSortAscendingShort: '+ Aufsteigend',
    addSortDescendingShort: '+ Absteigend',
  },
  // endregion

  // region Others
  DateHelper: {
    locale: 'de',
    weekStartDay: 1,
    // Non-working days which match weekends by default, but can be changed according to schedule needs
    nonWorkingDays: {
      0: true,
      6: true,
    },
    // Days considered as weekends by the selected country, but could be working days in the schedule
    weekends: {
      0: true,
      6: true,
    },
    unitNames: [
      {
        single: 'Millisekunde',
        plural: 'Millisekunden',
        abbrev: 'ms',
      },
      {
        single: 'Sekunde',
        plural: 'Sekunden',
        abbrev: 's',
      },
      {
        single: 'Minute',
        plural: 'Minuten',
        abbrev: 'min',
      },
      {
        single: 'Stunde',
        plural: 'Stunden',
        abbrev: 'std',
      },
      {
        single: 'Tag',
        plural: 'Tage',
        abbrev: 't',
      },
      {
        single: 'Woche',
        plural: 'Wochen',
        abbrev: 'W',
      },
      {
        single: 'Monat',
        plural: 'Monathe',
        abbrev: 'mon',
      },
      {
        single: 'Quartal',
        plural: 'Quartal',
        abbrev: 'Q',
      },
      {
        single: 'Jahr',
        plural: 'Jahre',
        abbrev: 'jahr',
      },
      {
        single: 'Dekade',
        plural: 'Jahrzehnte',
        abbrev: 'dek',
      },
    ],
    // Used to build a RegExp for parsing time units.
    // The full names from above are added into the generated Regexp.
    // So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
    // When generating its display value though, it uses the full localized names above.
    unitAbbreviations: [
      ['mil'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['d'],
      ['w', 'wk'],
      ['mo', 'mon', 'mnt'],
      ['q', 'quar', 'qrt'],
      ['y', 'yr'],
      ['dek'],
    ],
    parsers: {
      L: 'DD.MM.YYYY',
      LT: 'HH:mm',
    },
    ordinalSuffix: function ordinalSuffix(number) {
      return number;
    },
  },
  PagingToolbar: {
    firstPage: 'Gehe zur ersten Seite',
    prevPage: 'Zurück zur letzten Seite',
    page: 'Seite',
    nextPage: 'Gehe zur nächsten Seite',
    lastPage: 'Gehe zur letzten Seite',
    reload: 'Aktuelle Seite neu laden',
    noRecords: 'Keine Zeilen zum Anzeigen',
    pageCountTemplate: function pageCountTemplate(data) {
      return 'von ' + data.lastPage;
    },
    summaryTemplate: function summaryTemplate(data) {
      return 'Ergebnisse ' + data.start + ' - ' + data.end + ' von ' + data.allCount;
    },
  },
  PanelCollapser: {
    Collapse: 'Reduzieren',
    Expand: 'Erweitern',
  },
  List: {
    loading: 'Beladung...',
  },

  // region Calendar
  Calendar: {
    Today: 'Heute',
    next: function next(unit) {
      return 'N\xE4chsten '.concat(unit);
    },
    previous: function previous(unit) {
      return 'Vorheriger '.concat(unit);
    },
    plusMore: function plusMore(value) {
      return '+'.concat(value, ' mehr');
    },
  },
  CalendarDrag: {
    holdCtrlForRecurrence: 'Halten sie die STRG-Taste für ein wiederkehrendes ereignis gedrückt',
  },
  EventTip: {
    'Edit event': 'Bearbeiten',
    'Delete event': 'Löschen',
  },
  // AgendaView: {
  //   Agenda: 'Agenda',
  // },
  // MonthView: {
  //   Month: 'Monat',
  //   monthUnit: 'monat',
  // },
  // WeekView: {
  //   Week: 'Woche',
  //   weekUnit: 'woche',
  // },
  // YearView: {
  //   Year: 'Jahr',
  //   yearUnit: 'jahr',
  // },
  DayView: {
    Day: 'Tag',
    dayUnit: 'tag',
    daysUnit: 'tage',
    expandAllDayRow: 'Erweitern sie den ganztägigen bereich',
    collapseAllDayRow: 'Ganztägigen abschnitt reduzieren',
  },
  Sidebar: {
    'Filter events': 'Aufgaben filtern',
  },
  WeekExpander: {
    expandTip: 'Klicken Sie hier, um die Zeile zu erweitern',
    collapseTip: 'Klicken Sie hier, um die Zeile zu reduzieren',
  }, // endregion
};

// merge with default locale

export const CalendarLocaleDE = LocaleHelper.mergeLocales(En, De);
