<form [formGroup]="formGroup" class="form" *ngIf="formGroup" class="custom-form">
  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_firstname_label"
      type="text"
      errorMessage="shared_component_pi_firstname_error"
      [fc]="getFormControls('firstName')"
    ></lgv-custom-input-field>
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_lastname_label"
      type="text"
      errorMessage="shared_component_pi_lastname_error"
      [fc]="getFormControls('lastName')"
    ></lgv-custom-input-field>
  </div>
  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_phonenumber_label"
      type="tel"
      errorMessage="shared_component_pi_phonenumber_error"
      [fc]="getFormControls('phoneNumber')"
    ></lgv-custom-input-field>
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_email_label"
      type="email"
      errorMessage="shared_component_pi_email_error"
      [fc]="getFormControls('email')"
    ></lgv-custom-input-field>
  </div>

  <div class="column-one-field">
    <lgv-custom-select-field
      [items]="relationships"
      [fc]="getFormControls('relationship')"
      text="shared_component_pi_relationship_label"
      errorMessage="shared_component_pi_relationship_error"
    ></lgv-custom-select-field>
  </div>
</form>
