<lgv-auth-wrapper text="auth_registrationlink_headline">
  <div class="hello" style="text-align: center; padding-bottom: 10px" *ngIf="!tokenNotFound">
    {{ 'auth_registrationlink_message' | translate }} {{ firstname }}!
  </div>
  <form [formGroup]="formGroup" class="form" *ngIf="!tokenNotFound" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
      <mat-label translate>auth_registrationlink_email_label</mat-label>
      <input matInput formControlName="email" type="email" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>auth_registrationlink_password_label</mat-label>
      <input matInput formControlName="password" type="password" minlength="8" />
      <mat-error *ngIf="this.getField('password').hasError('minlength')" translate>
        auth_registrationlink_password_error
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>auth_registrationlink_repeatpassword_label</mat-label>
      <input matInput formControlName="repeatPassword" type="password" />
      <mat-error *ngIf="this.getField('repeatPassword').hasError('mismatch')" translate>
        auth_registrationlink_repeatpassword_error
      </mat-error>
    </mat-form-field>
    <lgv-auth-button [isLoading]="isLoading"></lgv-auth-button>
  </form>
  <button *ngIf="!tokenNotFound" mat-button color="primary" routerLink="/login">
    {{ 'auth_registrationlink_button_login' | translate }}
  </button>
  <div style="text-align: center; padding-bottom: 10px" *ngIf="tokenNotFound" translate>
    auth_registrationlink_error
  </div>
</lgv-auth-wrapper>
