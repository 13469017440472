import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { LocalizationService } from '@app/services/localization.service';
import { EventStore, Calendar, LocaleManager, LocaleHelper } from '@bryntum/calendar';
import { BryntumCalendarComponent } from '@bryntum/calendar-angular';
import { CalendarData } from '@modules/dashboard/components/calendar-pro/calendar.pro.helper';
import { BehaviorSubject } from 'rxjs';
import { calendarConfig } from './calendar.config';

@Component({
  selector: 'lgv-print-calendar-item',
  template: `
    <bryntum-calendar
      [project]="calendarConfig.project"
      [events]="calendarData.events.rows"
      [resources]="calendarData.resources.rows"
      [date]="currentDate"
      [modes]="calendarConfig.modes"
      [sidebar]="calendarConfig.sidebar"
      [modeDefaults]="calendarConfig.modeDefaults"
      [weekStartDay]="calendarConfig.weekStartDay"
    ></bryntum-calendar>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        height: 309mm;
        width: 440mm;
        overflow: hidden;
      }
    `,
  ],
})
export class PrintCalendarItemComponent implements AfterViewInit {
  @ViewChild(BryntumCalendarComponent) calendarComponent: BryntumCalendarComponent;
  @Input() eventsChanged: BehaviorSubject<CalendarData>;
  @Input() currentDate: Date;
  @Input() isClientMode: boolean;

  private calendar: Calendar;
  private eventStore: EventStore;

  calendarConfig = calendarConfig;

  calendarData: CalendarData = { success: true, resources: { rows: [] }, events: { rows: [] } };

  constructor(private localizationService: LocalizationService) {
    this.localizationService.initCalendarPro(LocaleManager, LocaleHelper);
  }

  ngAfterViewInit(): void {
    this.calendar = this.calendarComponent.instance;
    this.eventStore = this.calendar.eventStore;

    this.eventsChanged.subscribe(async (response) => {
      if (response) {
        this.calendarData = response;
      }
    });
  }
}
