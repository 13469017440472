import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-custom-date-field',
  templateUrl: './custom-date-field.component.html',
  styles: [
    `
          mat-form-field {
            flex-grow: 1;
          }
        `,
  ],
})
export class CustomDateFieldComponent {
  @Input() text: MessageIDS;
  @Input() fc: UntypedFormControl;
  @Input() errorMessage: MessageIDS;
}
