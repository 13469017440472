import { Component, Input } from '@angular/core';
import { TableColumn } from '@schema/table.models';

@Component({
  selector: 'lgv-subtext-table-item',
  template: '{{getText()}}',
})
export class SubtextTableItemComponent {
  @Input() column: TableColumn;
  @Input() item: any;

  getText(): string {
    if (this.item[this.column.subprop]) {
      return this.item[this.column.subprop][this.column.prop] ?? '-';
    }
    return '-';
  }
}
