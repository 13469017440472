/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/services/auth.service';
import { GetUserInfoResponse } from '@schema/APITypes';
import { BehaviorSubject, Subject } from 'rxjs';
import { TableModel } from '@schema/table.models';
import { MatSort } from '@angular/material/sort';
import { Utils } from '@shared/utils/utils';
import { trigger, state, style, transition, animate } from '@angular/animations';

export interface TextClickClickedItem {
  event: PointerEvent;
  row: any;
  column: string;
}
export interface SubTextClickClickedItem {
  event: TextClickClickedItem;
  row: any;
}

@Component({
  selector: 'lgv-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GenericTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() tableDataSubject: BehaviorSubject<TableModel>;
  @Input() masterToggleSubject: Subject<boolean>;
  @Input() page: number;
  @Input() pageSize: number;
  @Input() totalCount: number;
  @Input() isSubtable: boolean;

  @Output() deleteRowEvent = new EventEmitter<any>();
  @Output() updateRowEvent = new EventEmitter<any>();
  @Output() downloadRowEvent = new EventEmitter<any>();
  @Output() paidRowEvent = new EventEmitter<any>();
  @Output() archiveRowEvent = new EventEmitter<any>();
  @Output() unarchiveRowEvent = new EventEmitter<any>();
  @Output() permissionRowEvent = new EventEmitter<any>();
  @Output() openButtonRowEvent = new EventEmitter<any>();
  @Output() starRowEvent = new EventEmitter<any>();

  @Output() multiSelectEvent = new EventEmitter<any[]>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() sendInviteEvent = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() textClickClicked = new EventEmitter<TextClickClickedItem>();
  @Output() subTextClickClicked = new EventEmitter<SubTextClickClickedItem>();

  @Output() sortChangedEvent = new EventEmitter<any>();
  @Output() changeAccountStatusEvent = new EventEmitter<{ item: any; bo: boolean }>();
  @Output() expandRowEvent = new EventEmitter<{
    element: string;
    expanded: boolean;
  }>();
  @Output() masterToggleClicked = new EventEmitter<boolean>();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData: TableModel;
  isLoading = true;
  errorText: string;
  pageEvent: PageEvent;
  selection = new SelectionModel<any>(true, []);
  expandedElement: any | null;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.selection.changed.subscribe(() => {
      this.multiSelectEvent.emit(this.selection.selected);
    });
    this.tableDataSubject.subscribe((res: TableModel) => {
      this.tableData = res;
      this.updateTable();
    });

    if (this.tableData) {
      this.dataSource.data = this.tableData.content;
    }

    if (this.masterToggleSubject) {
      this.masterToggleSubject.subscribe((value) => {
        if (value) {
          this.selection.select(...this.dataSource.data.filter((x) => !x.footer));
        } else {
          this.selection.clear();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.tableData.isPaginatorVisible) {
      this.paginator.page.asObservable().subscribe((pageEvent: PageEvent) => {
        // The API starts indexing with 1 for the first page
        // while the material library starts with 0.
        // Therefore we add 1.
        this.pageChanged.emit(pageEvent.pageIndex + 1);
      });
    }
    // if (this.initOrderDir && this.initSortColumn) {
    //   const sortState: Sort = { active: this.initSortColumn, direction: this.initOrderDir as any };
    //   this.sort.sortChange.emit(sortState);
    // }

    this.sort.sortChange.subscribe((sortEvent: { active: string; direction: 'asc' | 'desc' }) => {
      this.sortChangedEvent.emit({ active: Utils.camelToUnderscore(sortEvent.active), direction: sortEvent.direction });
    });
  }

  getCurrentUser(): GetUserInfoResponse {
    return this.authService.userInfo;
  }

  updateTable(): void {
    this.dataSource.data = this.tableData.content;
  }

  changeAccountStatus(item: any, bo: boolean) {
    this.changeAccountStatusEvent.emit({ item, bo });
  }

  getRow(row) {
    if (!row.disableClick) {
      this.rowSelected.emit(row);
    }
  }

  getProps(): string[] {
    const props = this.tableData.displayedColumns.map((column) => {
      return column.prop;
    });

    if (this.tableData.multiSelectFirst) {
      return ['select', ...props];
    } else if (this.tableData.multiSelectLast) {
      return [...props, 'select'];
    } else {
      return props;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter((x) => !x.footer).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.masterToggleClicked.emit(false);
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data.filter((x) => !x.footer));
    this.masterToggleClicked.emit(true);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onExpandRowClick = (element) => {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }

    this.expandRowEvent.emit({
      element: element,
      expanded: this.expandedElement === element,
    });
  };

  onClickTextClicked(event, row, column: string) {
    event.stopPropagation();
    this.textClickClicked.emit({ event, row, column });
  }

  onSubTableTextClicked(event: TextClickClickedItem, row: any) {
    this.subTextClickClicked.emit({ event, row });
  }

  trackByFn(index, item) {
    if (!this.tableData) {
      return null;
    }
    return item[this.tableData.trackById];
  }

  hourFormatter(item: number): string {
    return Utils.hourFormatter(item);
  }
}
