import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-dialog-wrapper',
  template: `
    <div class="header">
      <div translate [translateParams]="titleTranslateParams" class="header-content">{{ titleText }}</div>
      <p translate>{{ messageText }}</p>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        padding: 24px;
        min-width: 500px !important;
        max-height: 85vh;
        overflow-y: scroll;
      }

      .header {
        display: flex;
        flex-direction: column;
      }

      .header-content {
        word-break: break-word;
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
      }

      .step-info-container {
        display: flex;
        flex-direction: column;
        margin-right: 24px;
      }
    `,
  ],
})
export class DialogWrapperComponent implements OnDestroy {
  @Input() titleText: MessageIDS;
  @Input() messageText: MessageIDS;
  @Input() titleTranslateParams: any;
  @Input() currentStep: number;
  @Input() stepCount: number;
  @Input() onClose: (dialogRef: MatDialogRef<any>) => void;
  @Output() closed = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<any>,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(document.body, 'hidden');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'hidden');
  }

  onCloseClick(): void {
    this.closed.emit();
    this.dialogRef.close();
  }
}
