<form [formGroup]="formGroup" class="form" *ngIf="formGroup" class="custom-form">
  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_firstname_label"
      type="text"
      errorMessage="shared_component_pi_firstname_error"
      [fc]="getFormControls('firstName')"
    ></lgv-custom-input-field>
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_lastname_label"
      type="text"
      errorMessage="shared_component_pi_lastname_error"
      [fc]="getFormControls('lastName')"
    ></lgv-custom-input-field>
  </div>

  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      [fc]="getFormControls('street')"
      text="shared_component_pi_address_label"
      type="text"
      errorMessage="shared_component_pi_address_error"
    ></lgv-custom-input-field>

    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_zip_label"
      type="plz"
      errorMessage="shared_component_pi_zip_error"
      [fc]="getFormControls('zipCode')"
    ></lgv-custom-input-field>
  </div>

  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_city_label"
      [fc]="getFormControls('city')"
      type="text"
      errorMessage="shared_component_pi_city_error"
    ></lgv-custom-input-field>

    <lgv-custom-select-search
      appearance="outline"
      [items]="countries"
      text="shared_component_pi_country_label"
      [fc]="getFormControls('country')"
      errorMessage="shared_component_pi_country_error"
    ></lgv-custom-select-search>
  </div>
  <div class="column">
    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_phonenumber_label"
      type="tel"
      errorMessage="shared_component_pi_phonenumber_error"
      [fc]="getFormControls('phoneNumber')"
    ></lgv-custom-input-field>

    <lgv-custom-date-field
      [fc]="getFormControls('birthday')"
      text="shared_component_pi_birthday_label"
      errorMessage="shared_component_pi_birthday_error"
    ></lgv-custom-date-field>
  </div>

  <div [class]="client ? 'column' : 'column-one-field'">
    <lgv-custom-input-field
      *ngIf="client"
      appearance="outline"
      text="shared_component_pi_sin_label"
      type="text"
      errorMessage="shared_component_pi_sin_error"
      [fc]="getFormControls('sin')"
    ></lgv-custom-input-field>

    <lgv-custom-input-field
      appearance="outline"
      text="shared_component_pi_email_label"
      type="email"
      errorMessage="shared_component_pi_email_error"
      [fc]="getFormControls('email')"
    ></lgv-custom-input-field>

    <!-- TODO: customer portal -->
    <!-- <lgv-custom-matcheckbox
      [fc]="getFormControls('account')"
      title="generate account"
      style="margin: 0.25em 0; padding-bottom: 1.34375em; padding-left: 10px; padding-right: 10px"
    ></lgv-custom-matcheckbox> -->
  </div>
</form>
