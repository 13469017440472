<lgv-auth-wrapper text="auth_login_headline">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill">
      <mat-label translate>auth_login_email_label</mat-label>
      <input matInput formControlName="email" type="email" autocomplete="off" />
      <mat-error *ngIf="this.getField('email').invalid" translate>auth_login_email_error </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label translate>auth_login_password_label</mat-label>
      <input matInput formControlName="password" autocomplete="current-password"
        [type]="hidePassword ? 'password' : 'text'" minlength="8" />
      <button class="show-password" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
        <mat-icon class="material-icons-outlined">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="this.getField('password').invalid" translate> auth_login_password_error </mat-error>
    </mat-form-field>
    <lgv-auth-button [isLoading]="isLoading"></lgv-auth-button>
  </form>
  <button mat-button color="primary" routerLink="/forgot-password">
    {{ 'auth_login_button_forgotpassword' | translate }}
  </button>
</lgv-auth-wrapper>