import { Component, Output, EventEmitter, Input } from '@angular/core';
import { GetAdminUsersResponseItem } from '@schema/APITypes';

@Component({
  selector: 'lgv-user-admin-more-table-item',
  template: `
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="editClicked.emit()">
        <mat-icon class="material-icons-outlined">edit</mat-icon>
        <span translate>shared_component_generictable_edit</span>
      </button>
      <button
        mat-menu-item
        *ngIf="user.isActive === 'active' && user.scopes !== 'cms'"
        (click)="disableAccountClicked.emit()"
      >
        <mat-icon class="material-icons-outlined">person_off</mat-icon>
        <span translate>shared_component_generictable_disableaccount</span>
      </button>
      <button mat-menu-item *ngIf="user.isActive === 'inactive'" (click)="enableAccountClicked.emit()">
        <mat-icon class="material-icons-outlined">person_on</mat-icon>
        <span translate>shared_component_generictable_enableaccount</span>
      </button>
      <button mat-menu-item *ngIf="user.isActive === 'pending'" (click)="sendInviteClicked.emit()">
        <mat-icon class="material-icons-outlined">email</mat-icon>
        <span translate>shared_component_generictable_sendinviteagain</span>
      </button>
      <button mat-menu-item *ngIf="user.scopes !== 'cms'" (click)="deleteClicked.emit()">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span translate>shared_component_generictable_delete</span>
      </button>
    </mat-menu>
  `,
})
export class UserAdminMoreTableItemComponent {
  @Input() user: GetAdminUsersResponseItem;
  @Output() editClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<void>();
  @Output() sendInviteClicked = new EventEmitter<void>();
  @Output() enableAccountClicked = new EventEmitter<void>();
  @Output() disableAccountClicked = new EventEmitter<void>();
}
