import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DASHBOARD_PATHS_ABSOLUTE } from '@modules/dashboard/dashboard.paths';
import {
  GetRegistrationLinkInfoResponse,
  GetUserInfoResponse,
  PostForgotPasswordCompletePayload,
  PostForgotPasswordPayload,
  PostLoginPayload,
  PostLoginResponse,
  PostRegistrationLinkCompletePayload,
  PostRegistrationLinkPayload,
  PutUserInfoPayload,
} from '@schema/APITypes';
import { BehaviorSubject, Observable } from 'rxjs';

import { ADMIN_PATHS_ABSOLUTE } from '@modules/admin/admin.paths';
import { LocalStorageService } from '@shared/services/local-storage.service';
import queryString from 'query-string';
import { APP_PATHS } from 'src/app/app.paths';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userInfo: GetUserInfoResponse;
  private userInfoSubject: BehaviorSubject<GetUserInfoResponse> = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  getUserInfoObs(): Observable<GetUserInfoResponse> {
    return this.userInfoSubject.asObservable();
  }

  setUserInfoObs(userInfo: GetUserInfoResponse): void {
    this.userInfo = userInfo;
    this.userInfoSubject.next(userInfo);
  }

  isLoggedIn(): string {
    return this.localStorageService.get('accessToken');
  }

  logout(): void {
    this.apiService.resetAuth();
  }

  async login(payload: PostLoginPayload): Promise<void> {
    const response: PostLoginResponse = await this.apiService.post('auth/login', payload);
    this.apiService.saveTokens(response);
    await this.getUserInformation();
    this.routeToCorrectRoot();
  }

  async sendForgotPasswordEmail(payload: PostForgotPasswordPayload): Promise<void> {
    await this.apiService.post('auth/forgot-password', payload);
  }

  async sendForgotPasswordEmailComplete(payload: PostForgotPasswordCompletePayload): Promise<void> {
    const response: PostLoginResponse = await this.apiService.post('auth/forgot-password/complete', payload);
    this.apiService.saveTokens(response);
  }

  async getUserInformation(): Promise<void> {
    const response: GetUserInfoResponse = await this.apiService.get('auth/userinfo');
    this.setUserInfoObs(response);
  }

  async putUserInformation(payload: PutUserInfoPayload): Promise<void> {
    await this.apiService.put('auth/userinfo', payload);
  }

  routeToCorrectRoot(): void {
    switch (this.userInfo.scopes[0]) {
      case 'caseManager':
        this.router.navigateByUrl(DASHBOARD_PATHS_ABSOLUTE.DASHBOARD_ROOT);
        break;
      case 'orgAdmin':
        this.router.navigateByUrl(DASHBOARD_PATHS_ABSOLUTE.DASHBOARD_ROOT);
        break;
      case 'cms':
        this.router.navigateByUrl(ADMIN_PATHS_ABSOLUTE.ADMIN_USERS);
        break;
      case 'app':
        break;
      default:
        this.logout();
        this.router.navigateByUrl(APP_PATHS.ROOT);
    }
  }

  async registrationLink(payload: PostRegistrationLinkPayload): Promise<void> {
    await this.apiService.post('auth/registration-link', payload);
  }

  async registrationLinkInfo(token: string): Promise<GetRegistrationLinkInfoResponse> {
    const link = queryString.stringifyUrl({
      url: 'auth/registration-link/info',
      query: token
        ? {
            token,
          }
        : {},
    });
    return await this.apiService.get(link);
  }

  async registrationLinkResend(userId: string): Promise<GetRegistrationLinkInfoResponse> {
    const link = queryString.stringifyUrl({
      url: 'auth/registration-link/resend',
      query: {
        userId,
      },
    });
    return await this.apiService.get(link);
  }

  async registrationLinkComplete(payload: PostRegistrationLinkCompletePayload): Promise<void> {
    const response = await this.apiService.post('auth/registration-link/complete', payload);
    this.apiService.saveTokens(response);
    await this.getUserInformation();
    this.routeToCorrectRoot();
  }
}
