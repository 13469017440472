import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import { BehaviorSubject } from 'rxjs';
import { TableColumn, TableModel } from '@schema/table.models';
import { AuthService } from '@app/services/auth.service';
import {
  AddEditUserAdminComponent,
  IAddEditAdminUserDialogData,
} from '@modules/admin/dialogs/add-edit-user-admin-dialog/add-edit-user-admin-dialog.component';
import { GetAdminUsersResponseItem, PatchAdminUsersPayload } from '@schema/APITypes';
import { DeleteDialogComponent, IDeleteDialogReturnData } from '@shared/dialogs/delete-dialog/delete-dialog.component';
import { SnackbarService } from '@shared/services/snackbar.service';
import { DATE_FORMAT } from 'src/app/constants';
import { LoadingBarService } from '@shared/services/loading-bar.service';

@Component({
  selector: 'lgv-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  displayedColumns: TableColumn[] = [
    { prop: 'imageID', type: 'image' },
    { prop: 'email', trans: 'admin_users_table_email', type: 'text' },
    { prop: 'scopes', trans: 'admin_users_table_role', type: 'text' },
    {
      prop: 'firstname',
      trans: 'admin_users_table_firstname',
      type: 'text',
    },
    {
      prop: 'lastname',
      trans: 'admin_users_table_lastname',
      type: 'text',
    },
    {
      prop: 'lastLogin',
      trans: 'admin_users_table_lastlogin',
      type: 'datetime',
      format: DATE_FORMAT,
    },
    {
      prop: 'createdAt',
      trans: 'admin_users_table_createdat',
      type: 'datetime',
      format: DATE_FORMAT,
    },
    {
      prop: 'isActive',
      trans: 'admin_users_table_status',
      type: 'status',
    },
    {
      prop: 'name',
      trans: 'admin_users_table_organisation',
      type: 'subtext',
      subprop: 'organisation',
    },
    {
      prop: 'userAdminMore',
      type: 'userAdminMore',
    },
  ];

  usersData: TableModel = {
    trackById: 'id',
    content: [],
    displayedColumns: this.displayedColumns,
    isPaginatorVisible: true,
    defaultPicture: 'account_circle',
  };
  usersTableChanged = new BehaviorSubject<TableModel>(this.usersData);
  isLoading = false;

  page = 1;
  count = 10;
  totalCount = 0;
  search = '';

  constructor(
    private adminService: AdminService,
    private dialog: MatDialog,
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private loadingBarService: LoadingBarService
  ) {}
  ngOnInit(): void {
    this.reloadUsers();
  }

  async reloadUsers(): Promise<void> {
    this.changeLoadingState(true);
    try {
      const userReponse = await this.adminService.getUsers({
        limit: this.count.toString(),
        filterQuery: this.search ? this.search : undefined,
        offset: ((this.page - 1) * this.count).toString(),
      });
      this.totalCount = userReponse.total;
      this.usersData.content = userReponse.users;
      this.usersTableChanged.next(this.usersData);
      this.changeLoadingState(false);
    } catch (error) {
      this.changeLoadingState(false);
      throw error;
    }
  }

  onSearch(value: string): void {
    this.search = value;
    this.page = 1;
    this.reloadUsers();
  }

  onPageChanged(page: number): void {
    this.page = page;
    this.reloadUsers();
  }

  addClicked(): void {
    const ref = this.dialog.open(AddEditUserAdminComponent, {
      data: {} as IAddEditAdminUserDialogData,
    });
    ref.afterClosed().subscribe((result: IDeleteDialogReturnData) => {
      if (result?.message === 'ok') {
        this.reloadUsers();
      }
    });
  }
  editClicked(user: GetAdminUsersResponseItem): void {
    const ref = this.dialog.open(AddEditUserAdminComponent, {
      data: {
        user,
      } as IAddEditAdminUserDialogData,
    });
    ref.afterClosed().subscribe((result: IDeleteDialogReturnData) => {
      if (result?.message === 'ok') {
        this.reloadUsers();
      }
    });
  }

  deleteClicked(user: GetAdminUsersResponseItem): void {
    const deleteDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        deleteFunction: async () => {
          await this.adminService.deleteUser(user.id);
        },
      },
    });

    deleteDialogRef.afterClosed().subscribe((result: IDeleteDialogReturnData) => {
      if (result?.message === 'ok') {
        this.reloadUsers();
      }
    });
  }

  async sendInviteClicked(user: GetAdminUsersResponseItem): Promise<void> {
    this.changeLoadingState(true);
    try {
      await this.authService.registrationLinkResend(user.id);
      this.snackBarService.showInfo('admin_users_email_send');
      this.changeLoadingState(false);
    } catch (error) {
      this.changeLoadingState(false);
      throw error;
    }
  }

  async changeAccountStatusClicked(event: { item: GetAdminUsersResponseItem; bo: boolean }): Promise<void> {
    this.changeLoadingState(true);
    const payload: PatchAdminUsersPayload = {};
    if (event.bo) {
      payload.isActive = 'active';
    } else {
      payload.isActive = 'inactive';
    }
    try {
      await this.adminService.patchUser(payload, event.item.id);
      await this.reloadUsers();
      this.changeLoadingState(false);
    } catch (error) {
      this.changeLoadingState(false);
      throw error;
    }
  }

  changeLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.loadingBarService.isLoading.next(isLoading);
  }
}
