import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GetRequestDetailsResponseItem, GetRequestListResponseItem, Language, RequestAnswers } from '@schema/APITypes';
import { lastValueFrom } from 'rxjs';

export const Utils = {
  isRequiredControl: (control: UntypedFormControl): boolean => {
    const { validator } = control;
    if (validator) {
      const validation = validator(new UntypedFormControl());
      return validation !== null && validation.required === true;
    }
    return false;
  },

  getRandomNumber: (): string => {
    return Math.floor(Math.random() * 100000).toString();
  },
  camelToUnderscore: (key: string): string => {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('_').toLowerCase();
  },

  getRequestFullName: (
    request: GetRequestListResponseItem | GetRequestDetailsResponseItem,
    lastNameFirst?: boolean
  ): string => {
    if (lastNameFirst) {
      return `${request.lastName} ${request.firstName}`;
    } else {
      return `${request.firstName} ${request.lastName}`;
    }
  },

  getRequestLastnameWithFirstnameShort: (
    request: GetRequestListResponseItem | GetRequestDetailsResponseItem
  ): string => {
    return `${request.lastName} ${request.firstName.charAt(0)}.`;
  },

  getAnswerFromResponse: (answers: RequestAnswers[], questionId: string): RequestAnswers => {
    const found = answers.find((x) => x.formQuestionId === questionId);
    if (found) {
      return found;
    } else {
      return undefined;
    }
  },

  getTmTypeTranslationKeys: async (
    translate: TranslateService
  ): Promise<{
    [key: string]: string;
  }> => {
    const keys: { [key: string]: string } = {
      normal: 'dashboard_feature_calendar_eventtype_normal',
      occupied: 'dashboard_feature_calendar_eventtype_occupied',
      splitex: 'dashboard_feature_calendar_eventtype_splitex',
      sickleave: 'dashboard_feature_calendar_eventtype_sickleave',
      vacation: 'dashboard_feature_calendar_eventtype_vacation',
      accident: 'dashboard_feature_calendar_eventtype_accident',
      hospitalization: 'dashboard_feature_calendar_eventtype_hospitalization',
    };

    for (const k in keys) {
      if (keys.hasOwnProperty(k)) {
        const value = await lastValueFrom(translate.get(keys[k]));
        keys[k] = value;
      }
    }
    return keys;
  },

  getHumanReadableSize(size: number, decimals = 2): string {
    if (size === 0) {
      return '';
    }
    const k = 1024;
    const sizeFormats = ['Bytes', 'KB', 'MB', 'GB'];
    let i = Math.floor(Math.log(size) / Math.log(k));
    i = i < sizeFormats.length ? i : sizeFormats.length - 1;
    return `${parseFloat((size / Math.pow(k, i)).toFixed(decimals))} ${sizeFormats[i]}`;
  },

  hourFormatter(minutes: number): string {
    if (minutes) {
      const hours = Math.floor(minutes / 60);
      const minutesLeft = minutes % 60;
      return `${hours}:${this.isBelowTenAddZero(minutesLeft)}`;
    } else {
      return '00:00';
    }
  },

  isBelowTenAddZero(value: number): string {
    if (value < 10) {
      return `0${value}`;
    } else {
      return `${value}`;
    }
  },

  getBrowserLanguage(): Language {
    const language = navigator.language.split('-')[0];
    if (language === 'de' || language === 'en' || language === 'it') {
      return language;
    } else {
      return 'en';
    }
  },

  getUTCDate(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
  },
  getUTCDateForUI(date: Date): Date {
    const newDate = new Date(date);
    return new Date(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes(),
      newDate.getUTCSeconds()
    );
  },
};
