import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import { AdminGetOrganisationResponseItem } from '@schema/APITypes';
import {
  AddEditOrganisationDialogComponent,
  IAddEditOrganisationDialogData,
} from '@shared/dialogs/add-edit-organisation-dialog/add-edit-organisation-dialog.component';
import { DeleteDialogComponent, IDeleteDialogReturnData } from '@shared/dialogs/delete-dialog/delete-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { TableColumn, TableModel } from '@schema/table.models';
import {
  EditOrganisationPermissionsComponent,
  IEditOrganisationPermissionseDialogData,
} from '@modules/admin/dialogs/edit-organisation-permissions/edit-organisation-permissions.component';
import { LoadingBarService } from '@shared/services/loading-bar.service';

@Component({
  selector: 'lgv-admin-organisations',
  templateUrl: './admin-organisations.component.html',
  styleUrls: ['./admin-organisations.component.scss'],
})
export class AdminOrganisationsComponent implements OnInit {
  displayedColumns: TableColumn[] = [
    { subprop: 'organisationItem', prop: 'id', trans: 'admin_organisation_table_name', type: 'subtext' },
    { subprop: 'organisationItem', prop: 'name', trans: 'admin_organisation_table_name', type: 'subtext' },
    {
      subprop: 'organisationItem',
      prop: 'companyNumber',
      trans: 'admin_organisation_table_companynumber',
      type: 'subtext',
    },
    {
      subprop: 'organisationItem',
      prop: 'taxNumber',
      trans: 'admin_organisation_table_taxnumber',
      type: 'subtext',
    },
    { subprop: 'organisationItem', prop: 'website', trans: 'admin_organisation_table_website', type: 'subtext' },

    {
      prop: 'city',
      subprop: 'address',
      subsubprop: 'organisationItem',
      trans: 'admin_organisation_table_city',
      type: 'subsubtext',
    },
    {
      prop: 'country',
      subprop: 'address',
      subsubprop: 'organisationItem',
      trans: 'admin_organisation_table_country',
      type: 'subsubtext',
    },
    { subprop: 'counts', prop: 'employees', trans: 'admin_organisation_table_employees', type: 'subtext' },
    { subprop: 'counts', prop: 'users', trans: 'admin_organisation_table_users', type: 'subtext' },

    { prop: 'organisationMore', type: 'organisationMore' },
  ];

  organisationData: TableModel = {
    trackById: 'id',
    content: [],
    displayedColumns: this.displayedColumns,
    isPaginatorVisible: true,
  };
  organisationsTableChanged = new BehaviorSubject<TableModel>(this.organisationData);
  isLoading = false;

  page = 1;
  count = 10;
  totalCount = 0;
  search = '';

  constructor(
    private adminService: AdminService,
    private dialog: MatDialog,
    private loadingBarService: LoadingBarService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.reloadOrganisations();
  }

  async reloadOrganisations(): Promise<void> {
    this.changeLoadingState(true);
    try {
      const response = await this.adminService.getOrganisations({
        limit: this.count.toString(),
        filterQuery: this.search ? this.search : undefined,
        offset: ((this.page - 1) * this.count).toString(),
      });
      this.totalCount = response.total;
      this.organisationData.content = response.organisations;
      this.organisationsTableChanged.next(this.organisationData);
      this.changeLoadingState(false);
    } catch (error) {
      this.changeLoadingState(false);
      throw error;
    }
  }

  onSearch(value: string): void {
    this.search = value;
    this.page = 1;
    this.reloadOrganisations();
  }

  onPageChanged(page: number): void {
    this.page = page;
    this.reloadOrganisations();
  }

  addClicked(): void {
    const ref = this.dialog.open(AddEditOrganisationDialogComponent, {
      data: {
        isAdmin: true,
      } as IAddEditOrganisationDialogData,
    });
    ref.afterClosed().subscribe((result) => {
      if (result && result.message === 'ok') {
        this.reloadOrganisations();
      }
    });
  }

  editClicked(responseItem: AdminGetOrganisationResponseItem): void {
    const ref = this.dialog.open(AddEditOrganisationDialogComponent, {
      data: {
        organisation: responseItem.organisationItem,
        isAdmin: true,
      } as IAddEditOrganisationDialogData,
    });
    ref.afterClosed().subscribe((result) => {
      if (result && result.message === 'ok') {
        this.reloadOrganisations();
      }
    });
  }

  deleteClicked(responseItem: AdminGetOrganisationResponseItem): void {
    const deleteDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        deleteFunction: async () => {
          await this.adminService.deleteOrganisation(responseItem.organisationItem.id);
        },
      },
    });

    deleteDialogRef.afterClosed().subscribe((result: IDeleteDialogReturnData) => {
      if (result?.message === 'ok') {
        this.reloadOrganisations();
      }
    });
  }

  permissionClicked(responseItem: AdminGetOrganisationResponseItem): void {
    const data: IEditOrganisationPermissionseDialogData = {
      organisation: responseItem.organisationItem,
    };
    const dialogRef = this.dialog.open(EditOrganisationPermissionsComponent, {
      data,
    });

    dialogRef.afterClosed().subscribe((result: IDeleteDialogReturnData) => {
      if (result?.message === 'ok') {
        this.reloadOrganisations();
      }
    });
  }

  changeLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.loadingBarService.isLoading.next(isLoading);
  }
}
