import { Injectable } from '@angular/core';
import { TimemanagementService } from '@app/services/timemanagement.service';
import { GetRequestListResponseItem, PostTimemanagementCalendarResponseItem, RequestType } from '@schema/APITypes';
import { SnackbarService } from './snackbar.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColoringService {
  // needs to stay constant, don not modify from code! (employee colors are dark)
  ALL_EMPLOYEE_COLORS: string[] = [
    '#F44336',
    '#4CAF50',
    '#9C27B0',
    '#00BCD4',
    '#3F51B5',
    '#2196F3',
    '#009688',
    '#673AB7',
    '#5C7AEA',
    '#E91E63',
    '#005792',
    '#87AAAA',
    '#E26A2C',
    '#125C13',
    '#3E065F',
    '#193498',
    '#6C4A4A',
    '#3F0713',
    '#AD0A08',
    '#9A9483',
    '#6D9886',
    '#506D84',
    '#150050',
    '#4A403A',
    '#00A19D',
    '#E05D5D',
    '#125C13',
    '#2C394B',
    '#A73489',
    '#595260',
    '#480032',
    '#2B7F89',
    '#008B17',
  ];

  ALL_CLIENT_COLORS: string[] = [
    '#f48fb1',
    '#aed581',
    '#ff8a65',
    '#4fc3f7',
    '#CDDC39',
    '#FFC107',
    '#FF9800',
    '#ffe600',
    '#bcaaa4',
    '#F6D7A7',
    '#C8E3D4',
    '#C89595',
    '#D3E4CD',
    '#FE8F8F',
    '#9D84B7',
    '#CDF2CA',
    '#F6E061',
    '#CAB8FF',
    '#B1FFFD',
    '#FFDAC7',
    '#BCFFB9',
    '#F7E6AD',
    '#FFE3E3',
    '#FF94CC',
    '#F2EDD7',
    '#F6B8B8',
    '#CAF7E3',
    '#E798AE',
    '#ffcdb2',
    '#a8dadc',
    '#FFE7C7',
    '#5FB917',
    '#edf1fe',
    '#d3b683',
    '#e5ccbd',
    '#bfafb2',
    '#e3e7c4',
    '#FFFF99',
  ];

  usedEmployeeColors: string[] = [];
  usedClientColors: string[] = [];

  coloredEmployees: { [key: string]: { color: string; clients?: string[] } } = {};
  coloredClients: { [key: string]: { color: string; employees?: string[] } } = {};
  coloredResources: { [key: string]: string } = {};

  RESOURCE_VIEW_COLORING_CONTEXT: RequestType = 'employee';

  colorChanged = new Subject<void>();

  constructor(private timemanagementService: TimemanagementService, private snackbarService: SnackbarService) {
    this.colorChanged.subscribe(() => {
      const allClientColors = this.ALL_CLIENT_COLORS.length;
      const allEmployeeColors = this.ALL_EMPLOYEE_COLORS.length;
      this.colorChanged.subscribe(() => {
        if (timemanagementService.colorByEmployee.value) {
          if (Object.keys(this.coloredEmployees).length >= (allEmployeeColors / 100) * 80) {
            snackbarService.showError('global_color80');
          }
        } else {
          if (Object.keys(this.coloredClients).length >= (allClientColors / 100) * 80) {
            snackbarService.showError('global_color80');
          }
        }
      });
    });
  }

  getColor(context: RequestType): string {
    if (
      (context === 'employee' && this.usedEmployeeColors.length === this.ALL_EMPLOYEE_COLORS.length) ||
      (context === 'client' && this.usedClientColors.length === this.ALL_CLIENT_COLORS.length)
    ) {
      return null;
    }

    const ALL_COLORS = context === 'employee' ? this.ALL_EMPLOYEE_COLORS : this.ALL_CLIENT_COLORS;
    const usedColors = context === 'employee' ? this.usedEmployeeColors : this.usedClientColors;

    // finds the first non-used element
    const colorToReturn = ALL_COLORS.find((x) => usedColors.indexOf(x) === -1);
    usedColors.push(colorToReturn);
    this.colorChanged.next();
    return colorToReturn;
  }

  unassignColor(color: string, context: RequestType): void {
    const usedColors = context === 'employee' ? this.usedEmployeeColors : this.usedClientColors;

    // context is client
    const colorIndex = usedColors.indexOf(color);

    // if (colorIndex < 0) {
    //   throw new Error('Color not in use');
    // }

    usedColors.splice(colorIndex, 1);
    this.colorChanged.next();
  }

  resetAllColorings = (): void => {
    // we need to clear the actual objects/arrays here instead of just setting them newly
    // because there are references that do not get updated otherwise

    // clear the used colors array
    this.usedClientColors.splice(0, this.usedEmployeeColors.length);
    this.usedEmployeeColors.splice(0, this.usedEmployeeColors.length);

    // clear the colored objects
    Object.keys(this.coloredClients).forEach((key) => delete this.coloredClients[key]);
    Object.keys(this.coloredEmployees).forEach((key) => delete this.coloredEmployees[key]);
    this.colorChanged.next();
  };

  clearColorings = (): void => {
    this.usedClientColors = [];
    this.usedEmployeeColors = [];

    this.coloredClients = {};
    this.coloredEmployees = {};
    this.coloredResources = {};
    this.colorChanged.next();
  };

  isColorDark(hexColor: string): boolean {
    const rgbColors = this.convertHex2Rgb(hexColor);

    const brightness = Math.round((rgbColors[0] * 299 + rgbColors[1] * 587 + rgbColors[2] * 114) / 1000);
    return brightness < 150;
  }

  convertHex2Rgb(hex: string): number[] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
  }

  getCalendarColorForAppointment(appointment: PostTimemanagementCalendarResponseItem): string {
    // check if this employee has already been colored

    if (this.timemanagementService.colorByEmployee.getValue()) {
      if (this.coloredEmployees[appointment.employee.id]) {
        if (this.coloredEmployees[appointment.employee.id].clients.indexOf(appointment.client.id) === -1) {
          this.coloredEmployees[appointment.employee.id].clients.push(appointment.client.id);
        }
      } else {
        // employee has not been colored yet
        const newEmployeeColor = this.getColor('employee');
        this.checkIfColorIsNull(newEmployeeColor);
        this.coloredEmployees[appointment.employee.id] = {
          color: newEmployeeColor,
          clients: [appointment.client.id],
        };
      }
    } else {
      // check if client is already colored

      if (this.coloredClients[appointment.client.id]) {
        if (this.coloredClients[appointment.client.id].employees.indexOf(appointment.employee.id) === -1) {
          this.coloredClients[appointment.client.id].employees.push(appointment.employee.id);
        }
      } else {
        // client not colored yet
        const newClientColor = this.getColor('client');
        this.checkIfColorIsNull(newClientColor);
        this.coloredClients[appointment.client.id] = {
          color: newClientColor,
          employees: [appointment.employee.id],
        };
      }
    }

    if (this.timemanagementService.colorByEmployee.getValue()) {
      return this.coloredEmployees[appointment.employee.id].color;
    }

    return this.coloredClients[appointment.client.id].color;
  }

  checkIfColorIsNull(color: string) {
    if (color === null) {
      this.snackbarService.showError('global_allcolorused');
      this.timemanagementService.requestsSelected.next({ value: [], propagate: true });
      this.clearColorings();
    }
  }

  returnUnusedColorings(
    oldSelections: GetRequestListResponseItem[],
    newSelections: GetRequestListResponseItem[],
    context: 'employees' | 'clients',
    resourceView?: boolean
  ): void {
    if (newSelections.length > oldSelections.length) {
      // added --> we don't care as color assignment happens by calendar
      return;
    }

    const deletedEntry = oldSelections.find((x) => newSelections.indexOf(x) === -1);

    if (!deletedEntry) {
      return;
    }

    // if we just have resourceView coloring --> easy
    if (resourceView) {
      const usedColor = this.coloredResources[deletedEntry.id];
      this.unassignColor(usedColor, this.RESOURCE_VIEW_COLORING_CONTEXT);
      delete this.coloredResources[deletedEntry.id];

      return;
    }

    if (context === 'employees') {
      if (!this.coloredEmployees[deletedEntry.id]) {
        return;
      }
      this.coloredEmployees[deletedEntry.id].clients.forEach((client) => {
        if (!this.coloredClients[client]) {
          // client already deleted
          return;
        }
        const clientColor = this.coloredClients[client].color;
        this.unassignColor(clientColor, 'client');
        delete this.coloredClients[client];
      });

      const employeeColor = this.coloredEmployees[deletedEntry.id].color;
      this.unassignColor(employeeColor, 'employee');
      delete this.coloredEmployees[deletedEntry.id];
    } else {
      // de-selected --> delete all corresponding employee colorings + then the client colorings
      if (!this.coloredClients[deletedEntry.id]) {
        return;
      }

      this.coloredClients[deletedEntry.id].employees.forEach((employee) => {
        if (!this.coloredEmployees[employee]) {
          // employee already deleted
          return;
        }
        const employeeColor = this.coloredEmployees[employee].color;
        this.unassignColor(employeeColor, 'employee');
        delete this.coloredEmployees[employee];
      });

      const clientColor = this.coloredClients[deletedEntry.id].color;
      this.unassignColor(clientColor, 'client');
      delete this.coloredClients[deletedEntry.id];
    }
  }

  getColorForResourceViewResource(resourceId: string): string {
    if (!this.coloredResources[resourceId]) {
      // color this resource
      this.coloredResources[resourceId] = this.getColor(this.RESOURCE_VIEW_COLORING_CONTEXT);
    }
    return this.coloredResources[resourceId];
  }
}
