import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@shared/services/theme.service';

@Component({
  selector: 'lgv-auth-button',
  template: `
    <button
      mat-fab
      [color]="themeService.isDarkMode() ? 'primary' : 'accent'"
      (click)="onClicked.emit()"
      style="z-index: 10;"
    >
      <mat-icon *ngIf="!isLoading">east</mat-icon>
      <mat-spinner diameter="35" *ngIf="isLoading"></mat-spinner>
    </button>
  `,
  styles: [
    `
      :host {
        position: absolute;
        right: -42px;
        bottom: 70px;
      }
      button {
        width: 70px;
        height: 70px;
      }

      @media (max-width: 560px) {
        :host {
          right: 4px;
        }
      }
    `,
  ],
})
export class AuthButtonComponent {
  @Input() isLoading: boolean;
  @Output() onClicked = new EventEmitter<any>();

  constructor(public themeService: ThemeService) {}
}
