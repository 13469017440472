import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourFormatterLarge',
})
export class HourFormatterLargePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    if (value) {
      const minutes = parseInt(value, 10);
      const hours = Math.floor(minutes / 60);
      const minutesLeft = minutes % 60;
      return `${hours}h ${minutesLeft}min`;
    } else {
      return '0h 0min';
    }
  }
}
