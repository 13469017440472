import { Component, OnDestroy } from '@angular/core';
import { LoadingBarService } from '@shared/services/loading-bar.service';
import { ThemeService } from '@shared/services/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lgv-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnDestroy {
  show: boolean;
  showSub: Subscription;
  constructor(
    private loadingBarService: LoadingBarService,
    private themeService: ThemeService,
  ) {
    this.showSub = this.loadingBarService.isLoading.subscribe((show) => {
      this.show = show;
    });
  }

  ngOnDestroy(): void {
    this.showSub.unsubscribe();
  }

  getColor(): string {
    if (this.themeService.isDarkMode()) {
      return 'accent';
    } else {
      return 'primary';
    }
  }
}
