<div *ngIf="tableData && dataSource" class="table-wrapper">
  <table mat-table [dataSource]="dataSource" [trackBy]="trackByFn" multiTemplateDataRows matSort
    [ngClass]="{ 'has-subtables': tableData.isExpandButtonVisible, 'table-normal': !tableData.scrollMode }">
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" *ngIf="tableData.isExpandButtonVisible">
      <td mat-cell *matCellDef="let element" [attr.colspan]="tableData.displayedColumns.length + 1">
        <div [@detailExpand]="
            element &&
            expandedElement &&
            element[tableData.expandElementCompareColumn] === expandedElement[tableData.expandElementCompareColumn]
              ? 'expanded'
              : 'collapsed'
          " style="overflow: hidden">
          <lgv-sub-table-item style="overflow-x: scroll" [displayedColumns]="tableData.subDisplayedColumns"
            [data]="element.subitems" (textClickClicked)="onSubTableTextClicked($event, element)"></lgv-sub-table-item>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="select" *ngIf="tableData.multiSelectFirst || tableData.multiSelectLast">
      <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 75px">
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="table-data" style="width: 75px">
        <mat-checkbox *ngIf="!row.footer" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)" color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="item.prop" *ngFor="let item of tableData.displayedColumns">
      <th class="table-header" *matHeaderCellDef mat-header-cell mat-sort-header [disabled]="!item.sort ? true : false"
        style="user-select: none" [style.width]="item.columnWidth ? item.columnWidth : ''"
        [style.textAlign]="item.headerCentered ? 'center' : undefined" [ngClass]="{
          'icon-header':
            item.type === 'edit' ||
            item.type === 'delete' ||
            item.type === 'download' ||
            item.type === 'downloadSignature' ||
            item.type === 'openButton' ||
            item.type === 'expandButton' ||
            item.type === 'star',
          'image-table-header': item.type === 'image',
          'user-more-header':
            item.type === 'userMore' ||
            item.type === 'userAdminMore' ||
            item.type === 'requestMore' ||
            item.type === 'organisationMore' ||
            item.type === 'editDeleteMore',
          'table-header-center': tableData.centerContent
        }">
        <span [style.color]="item.marked ? 'red' : 'unset'">
          {{ item.trans | translate }}
        </span>
      </th>
      <td class="table-data" mat-cell *matCellDef="let element" [ngClass]="{
          'icon-data':
            item.type === 'edit' ||
            item.type === 'delete' ||
            item.type === 'download' ||
            item.type === 'downloadSignature' ||
            item.type === 'openButton' ||
            item.type === 'expandButton' ||
            item.type === 'star',
          'image-table-data': item.type === 'image',
          'image-table-header': item.type === 'image',
          'table-data-center': tableData.centerContent
        }" [style.width]="item.columnWidth ? item.columnWidth : ''">
        <!-- It is possible to hide to content of some columns for nested tables but still keep the column. -->
        <ng-container *ngIf="isSubtable ? !item.hiddenForSubtable : true">
          <span *ngIf="item.type === 'text'" [matTooltip]="element[item.prop]">
            {{ element[item.prop] ?? '-' }}
          </span>

          <span *ngIf="item.type === 'currency'" [matTooltip]="element[item.prop]">
            {{ element[item.prop] ? (element[item.prop] | currency: item.format:'') : '-' }}
          </span>

          <span *ngIf="item.type === 'colorGreen'" [matTooltip]="element[item.prop]" style="color: #4caf50">
            {{ element[item.prop] ? element[item.prop] : '-' }}
          </span>

          <span *ngIf="item.type === 'clickText'" [matTooltip]="element[item.prop]"
            [ngStyle]="{ cursor: !element.footer ? 'pointer' : 'default' }"
            (click)="!element.footer ? onClickTextClicked($event, element, item.prop) : null">
            {{ element[item.prop] ? element[item.prop] : '-' }}
          </span>

          <span *ngIf="item.type === 'reportingDetailsItem'"
            [ngStyle]="{ cursor: !element.footer ? 'pointer' : 'default' }"
            (click)="!element.footer ? onClickTextClicked($event, element, item.prop) : null">
            <div style="display: flex; justify-content: center" *ngIf="
                element[item.prop] &&
                element[item.prop].isForfait &&
                (element[item.prop].plannedQ > 0 || element[item.prop].workedQ > 0)
              " [style]="element[item.prop].allIsSynedFromPlatform ? 'text-decoration: underline' : ''">
              <div>{{ element[item.prop].plannedQ }}</div>
              <div>/</div>
              <div style="color: #4caf50">{{ element[item.prop].workedQ }}</div>

            </div>

            <div style="display: flex; justify-content: center" *ngIf="
                element[item.prop] &&
                !element[item.prop].isForfait &&
                (element[item.prop].plannedH > 0 || element[item.prop].workedH > 0)
              " [style]="element[item.prop].allIsSynedFromPlatform ? 'text-decoration: underline' : ''">
              <div>{{ hourFormatter(element[item.prop].plannedH) }}</div>
              <div>/</div>
              <div style="color: #4caf50">{{ hourFormatter(element[item.prop].workedH) }}</div>
            </div>
            <div *ngIf="!element[item.prop]">-</div>
          </span>

          <span *ngIf="item.type === 'translate'" [matTooltip]="element[item.prop] | translate">
            {{ element[item.prop] ? (element[item.prop] | translate) : '-' }}
          </span>

          <lgv-subtext-table-item *ngIf="item.type === 'subtext'" [item]="element"
            [column]="item"></lgv-subtext-table-item>

          <lgv-star-table-item
            *ngIf="item.type === 'star' && (element?.status ==='open' || element?.status ==='starred')"
            [value]="element?.status ==='starred'" (valueChange)="starRowEvent.emit(element)"></lgv-star-table-item>


          <lgv-subsubtext-table-item *ngIf="item.type === 'subsubtext'" [item]="element"
            [column]="item"></lgv-subsubtext-table-item>

          <lgv-bool-table-item *ngIf="item.type === 'bool'" [value]="element[item.prop]"> </lgv-bool-table-item>
          <lgv-icon-table-item icon="delete" tooltip="Delete" *ngIf="item.type === 'delete'"
            (click)="$event.stopPropagation()" (onClicked)="deleteRowEvent.emit(element)">
          </lgv-icon-table-item>
          <lgv-icon-table-item icon="edit" tooltip="Edit" *ngIf="item.type === 'edit'"
            (click)="$event.stopPropagation()" (onClicked)="updateRowEvent.emit(element)">
          </lgv-icon-table-item>

          <lgv-icon-table-item icon="file_download" tooltip="Download"
            *ngIf="item.type === 'downloadSignature' && element['status'] === 'approved'"
            (click)="$event.stopPropagation()" (onClicked)="downloadRowEvent.emit(element)">
          </lgv-icon-table-item>

          <lgv-icon-table-item icon="file_download" tooltip="Download" *ngIf="item.type === 'download'"
            (click)="$event.stopPropagation()" (onClicked)="downloadRowEvent.emit(element)">
          </lgv-icon-table-item>

          <lgv-icon-table-item icon="open_in_new" tooltip="Open" *ngIf="item.type === 'openButton'"
            (click)="$event.stopPropagation()" (onClicked)="openButtonRowEvent.emit(element)">
          </lgv-icon-table-item>
          <lgv-image-table-item *ngIf="item.type === 'image'" [id]="element[item.prop]"
            [defaultPicture]="tableData.defaultPicture"></lgv-image-table-item>

          <lgv-date-table-item [format]="item.format" [date]="element[item.prop]"
            *ngIf="item.type === 'datetime'"></lgv-date-table-item>

          <lgv-numeric-table-item [format]="item.format" [value]="element[item.prop]"
            *ngIf="item.type === 'numeric'"></lgv-numeric-table-item>

          <lgv-hours-minutes-table-item [value]="element[item.prop]"
            *ngIf="item.type === 'hoursMinutes'"></lgv-hours-minutes-table-item>

          <lgv-hours-minutes-table-item [value]="element[item.prop]" *ngIf="item.type === 'hoursMinutesGreen'"
            style="color: #4caf50"></lgv-hours-minutes-table-item>

          <lgv-download-table-item [value]="element[item.prop]"
            *ngIf="item.type === 'downloadFile'"></lgv-download-table-item>

          <lgv-user-admin-more-table-item [user]="element" (editClicked)="updateRowEvent.emit(element)"
            (deleteClicked)="deleteRowEvent.emit(element)" (sendInviteClicked)="sendInviteEvent.emit(element)"
            (enableAccountClicked)="changeAccountStatus(element, true)"
            (disableAccountClicked)="changeAccountStatus(element, false)" (click)="$event.stopPropagation()"
            *ngIf="item.type === 'userAdminMore'"></lgv-user-admin-more-table-item>

          <lgv-user-more-table-item [user]="element" [currentUser]="getCurrentUser()"
            (editClicked)="updateRowEvent.emit(element)" (deleteClicked)="deleteRowEvent.emit(element)"
            (sendInviteClicked)="sendInviteEvent.emit(element)"
            (enableAccountClicked)="changeAccountStatus(element, true)"
            (disableAccountClicked)="changeAccountStatus(element, false)" (click)="$event.stopPropagation()"
            *ngIf="item.type === 'userMore'"></lgv-user-more-table-item>

          <lgv-request-table-more-item *ngIf="item.type === 'requestMore'" [item]="element"
            (editClicked)="updateRowEvent.emit(element)" (deleteClicked)="deleteRowEvent.emit(element)"
            (archiveClicked)="archiveRowEvent.emit(element)" (unarchiveClicked)="unarchiveRowEvent.emit(element)"
            (click)="$event.stopPropagation()">
          </lgv-request-table-more-item>


          <lgv-edit-delete-table-more-item *ngIf="item.type === 'editDeleteMore'"
            (editClicked)="updateRowEvent.emit(element)" (deleteClicked)="deleteRowEvent.emit(element)"
            (click)="$event.stopPropagation()">
          </lgv-edit-delete-table-more-item>

          <lgv-organisation-more-table-item *ngIf="item.type === 'organisationMore'"
            (editClicked)="updateRowEvent.emit(element)" (deleteClicked)="deleteRowEvent.emit(element)"
            (permissionClicked)="permissionRowEvent.emit(element)" (click)="$event.stopPropagation()">
          </lgv-organisation-more-table-item>

          <lgv-status-table-item *ngIf="item.type === 'status'" [value]="element[item.prop]"></lgv-status-table-item>
          <lgv-request-link-table-item *ngIf="item.type === 'requestLink'" [data]="element[item.prop]"
            [link]="item.link"></lgv-request-link-table-item>



          <button mat-icon-button style="padding: 10px;"
            *ngIf="tableData.isExpandButtonVisible && item.type === 'expandButton' && element.subItemsCount >= 1"
            (click)="$event.stopPropagation(); onExpandRowClick(element)">
            <mat-icon *ngIf="
                (!expandedElement ?? element) ||
                (expandedElement &&
                  element[tableData.expandElementCompareColumn] !==
                    expandedElement[tableData.expandElementCompareColumn])
              ">add</mat-icon>
            <mat-icon *ngIf="
                element &&
                expandedElement &&
                element[tableData.expandElementCompareColumn] === expandedElement[tableData.expandElementCompareColumn]
              ">remove</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="!tableData.hideTableColumnHeaders">
      <tr class="table-header-row" mat-header-row *matHeaderRowDef="getProps()"></tr>
    </ng-container>
    <tr (click)="getRow(row)" class="table-data-rows default-row-height"
      [style.borderLeft]="row.rowColor ? row.rowColor : undefined" mat-row *matRowDef="let row; columns: getProps()"
      [ngClass]="{
        clickable: tableData.clickableRow && !row.disableClick
      }" (click)="!row.footer && tableData.clickableRow ? selection.toggle(row) : null"></tr>

    <ng-container *ngIf="tableData.isExpandButtonVisible">
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </ng-container>
  </table>

  <mat-paginator *ngIf="tableData.isPaginatorVisible" class="custom-paginator" [hidePageSize]="true"
    [pageSize]="pageSize" [length]="totalCount" [pageIndex]="page - 1" (page)="pageEvent = $event"></mat-paginator>
</div>