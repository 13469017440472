import { Component, Input } from '@angular/core';
import { NavItem } from '@schema/nav.models';

@Component({
  selector: 'lgv-navigation-item',
  template: `
    <mat-nav-list *ngIf="item.visible | async">
      <mat-list-item
        [routerLink]="item.route"
        [routerLinkActive]="item.routerActive ? 'active-link' : ''"
        [ngStyle]="{
          width: isExpanded ? '215px' : 'unset'
        }"
      >
        <mat-icon
          aria-hidden="false"
          *ngIf="!item.imageURL"
          matListItemIcon
          class="material-icons-outlined"
          [matBadge]="item.badgeCount"
          [matBadgeHidden]="item.hiddeBadge"
          matBadgePosition="before"
          matBadgeSize="small"
          [matBadgeColor]="item.badgeWarn ? 'warn' : 'accent'"
          >{{ item.iconText }}</mat-icon
        >
        <div *ngIf="isExpanded">{{ item.text | translate }}</div>
        <div
          style="margin-right: 16px; border-radius: 50%; background-size: cover;background-position: 50% 50%;"
          *ngIf="item.imageURL"
          matListItemIcon
          [style.background-image]="'url(' + item.imageURL + ')'"
          [matBadge]="item.badgeCount"
          [matBadgeHidden]="item.hiddeBadge"
          matBadgePosition="before"
          matBadgeSize="small"
          [matBadgeColor]="item.badgeWarn ? 'warn' : 'accent'"
        ></div>
      </mat-list-item>
    </mat-nav-list>
  `,
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent {
  @Input() isExpanded = false;
  @Input() item: NavItem;
}
