import { Component, Input } from '@angular/core';
import { SignatureStatus, IsActive, ReportingStatus, RequestStatus } from '@schema/APITypes';

@Component({
  selector: 'lgv-status-table-item',
  template: `
    <div *ngIf="value" class="status-container">
      <div
        class="status-item"
        [ngClass]="{
          'green-color': value === 'active' || value === 'confirmed' || value === 'approved' || value === 'signed',
          'yellow-color': value === 'pending' || value === 'inprogress',
          'orange-color': value === 'archived',
          'red-color': value === 'inactive' || value === 'rejected',
          'blue-color': value === 'open',
          'blue-green-color': value === 'starred'
        }"
      ></div>
      <div class="status-name">{{ getValue() }}</div>
    </div>
    <div *ngIf="!value">-</div>
  `,
  styles: [
    `
      .status-container {
        display: flex;
        align-items: center;
      }

      .yellow-color {
        background-color: #ffeb3b;
      }

      .blue-green-color {
        background-color: #009688;
      }

      .green-color {
        background-color: #4caf50;
      }

      .orange-color {
        background-color: #ff9800;
      }

      .red-color {
        background-color: #f44336;
      }

      .blue-color {
        background-color: #03a9f4;
      }

      .status-item {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 8px;
        flex-shrink: 0;
      }

      .status-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class StatusTableItemComponent {
  @Input() value: IsActive | RequestStatus | ReportingStatus | SignatureStatus;

  getValue(): string {
    if (this.value === 'inprogress') {
      return 'in progress';
    }

    return this.value;
  }
}
