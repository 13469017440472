<lgv-search-input
  *ngIf="organisationData.content.length > 0 || !!search"
  placeholder="admin_organisation_search"
  (searchChanged)="onSearch($event)"
></lgv-search-input>
<lgv-generic-table
  *ngIf="organisationData.content.length > 0 && !isLoading"
  [tableDataSubject]="organisationsTableChanged"
  [page]="page"
  [pageSize]="count"
  [totalCount]="totalCount"
  (updateRowEvent)="editClicked($event)"
  (deleteRowEvent)="deleteClicked($event)"
  (permissionRowEvent)="permissionClicked($event)"
  (pageChanged)="onPageChanged($event)"
></lgv-generic-table>

<lgv-fab-button (btnClicked)="addClicked()" type="add"></lgv-fab-button>

<lgv-empty-state
  symbol="help"
  *ngIf="organisationData.content.length === 0 && !isLoading && search"
  titleText="admin_organisation_notfound_title"
  text="admin_organisation_notfound_message"
></lgv-empty-state>

<lgv-empty-state
  symbol="add_circle"
  *ngIf="organisationData.content.length === 0 && !isLoading && !search"
  titleText="admin_organisation_init_title"
  text="admin_organisation_init_message"
></lgv-empty-state>
