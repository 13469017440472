import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageIDS } from 'src/app/app.module';
import { KeyValueItem } from '../custom-autocomplete/custom-autocomplete.component';

@Component({
  selector: 'lgv-custom-select-search',
  templateUrl: './custom-select-search.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
      }

      mat-form-field {
        flex-grow: 1;
      }
    `,
  ],
})
export class CustomSelectSearchComponent implements OnInit, OnDestroy {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Input() appearance: MatFormFieldAppearance;
  @Input() text: MessageIDS;
  @Input() fc: UntypedFormControl;
  @Input() errorMessage: MessageIDS;
  @Input() items: KeyValueItem[] = [];
  @Input() prefix: string;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }
  }
  public itemFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredItems: ReplaySubject<KeyValueItem[]> = new ReplaySubject<any[]>(1);

  protected onDestroySubject = new Subject<void>();

  ngOnInit(): void {
    // load the initial bank list
    this.filteredItems.next(this.items.slice());

    // listen for search field value changes
    this.itemFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroySubject)).subscribe(() => {
      this.filterItems();
    });
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  protected filterItems(): void {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(this.items.filter((bank) => bank.value.toLowerCase().indexOf(search) > -1));
  }
}
