import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { PRINT_PATHS } from './print.paths';
import { OrgGuard } from '@app/guards/org.guard';
import { PrintCalendarComponent } from './pages/print-calendar/print-calendar.component';
import { OrganisationFeaturePermission } from '@schema/APITypes';

export const PRINT_ROUTES: Routes = [
  {
    path: PRINT_PATHS.PRINT_CALENDAR,
    component: PrintCalendarComponent,
    canActivate: [OrgGuard],
    pathMatch: 'full',
    data: {
      permissionsRequired: ['calendar_pro'] as OrganisationFeaturePermission[],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(PRINT_ROUTES)],
  exports: [RouterModule],
})
export class PrintRoutingModule {}
