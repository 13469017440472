import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ICustomSnackBar {
  message: string;
  action: string;
  duration: number;
  type: string;
  actionMode?: 'sharingPermission';
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public toastSubject: Subject<ICustomSnackBar> = new Subject();
  public toast = this.toastSubject.asObservable();

  showError(message: string, duration = 7000): void {
    this.toastSubject.next({
      message: message,
      action: 'OK',
      duration,
      type: 'snackbar-error',
    });
  }

  showInfo(message: string, duration = 7000): void {
    this.toastSubject.next({
      message,
      action: 'OK',
      duration,
      type: 'snackbar-info',
    });
  }
}
