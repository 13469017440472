/* eslint-disable @typescript-eslint/no-empty-function */
export const calendarConfig = {
  // Features named by the properties are included.
  // An object is used to configure the feature.

  weekStartDay: 1, // start the calendar on monday

  sidebar: null,
  project: {
    timeZone: 'UTC',
  },

  // A block of configs which is applied to all modes.
  modeDefaults: {
    hourHeight: 30,
  },
  modes: {
    week: {
      showAllDayHeader: false,
      eventLayout: {
        clearanceMinutes: 999,
      },
    },
    day: false,
    year: false,
    agenda: false,
    month: false,
  },
};
