export const ACCEPTED_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];
export const ACCEPTED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'text/csv',
  'text/plain',
  'text/xml',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT2 = 'yyyy-MM-dd';
export const DATE_FORMAT_FULL = 'dd.MM.yyyy HH:mm';
export const DATE_YEAR_MONTH_FORMAT = 'yyyy-MM';
export const DATE_FORMAT_REPORTING = 'yyyyMM';
export const DEFAULT_LANGUAGE = 'en';
