import { Component } from '@angular/core';

@Component({
  selector: 'lgv-auth-footer',
  template: `
    <footer>
      <div class="footer-container">
        <div>© All Rights reserved</div>
        <div style="display: flex; align-items: center">
          <a target="_blank" href="https://longevo.at/privacy" translate> auth_footer_terms </a>
          <div style="padding: 0 4px">|</div>
          <a target="_blank" href="https://longevo.at/privacy" translate>auth_footer_data</a>
          <div style="padding: 0 4px">|</div>
          <a href="mailto:hello@longevo.at" translate>auth_footer_support</a>
          <div style="padding: 0 4px">|</div>
          <a target="_blank" href="https://longevo.at/contact" translate>auth_footer_about</a>
        </div>
      </div>
    </footer>
  `,
  styleUrls: ['./auth-footer.component.scss'],
})
export class AuthFooterComponent {}
