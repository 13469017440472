import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageIDS } from 'src/app/app.module';

export interface IUploadDialogData {
  title: MessageIDS;
  message?: MessageIDS;
  fileTypes: string[];
  buttonLeftText: MessageIDS;
  buttonRightText: MessageIDS;
  uploadFunction: (file: File) => Promise<void>;
}

export interface IUploadDialogReturnData {
  message: 'ok' | 'error';
}

@Component({
  selector: 'lgv-upload-dialog',
  template: `
    <lgv-dialog-wrapper [titleText]="data.title">
      <div *ngIf="data.message">{{ data.message | translate }}</div>
      <lgv-upload-field [fileTypes]="this.data.fileTypes" (filesEmitter)="fileSelected($event)"></lgv-upload-field>

      <lgv-button-container
        [disabled]="!file || isLoading"
        [buttonLeftText]="data.buttonLeftText"
        [buttonRightText]="data.buttonRightText"
        (onLeftClicked)="close()"
        (onRightClicked)="onSubmitClick()"
      >
      </lgv-button-container>
    </lgv-dialog-wrapper>
  `,
  styles: [
    `
      .button-container {
        display: flex;
        justify-content: flex-end;
      }
    `,
  ],
})
export class UploadDialogComponent {
  file: File;
  isLoading: boolean;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent, IUploadDialogReturnData>,
    @Inject(MAT_DIALOG_DATA) public data: IUploadDialogData
  ) {}

  async onSubmitClick(): Promise<void> {
    this.isLoading = true;
    try {
      if (this.file) {
        await this.data.uploadFunction(this.file);
        this.dialogRef.close({ message: 'ok' });
      }
    } catch (error) {
      this.dialogRef.close({ message: 'error' });
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  fileSelected(file: File[]): void {
    this.file = file[0];
  }
}
