import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lgv-star-table-item',
  template: `
    <button style="margin-left:6px" mat-icon-button (click)="$event.stopPropagation(); valueChange.emit()">
      <mat-icon>{{ value ? 'star' : 'star_outlined' }}</mat-icon>
    </button>
  `,
})
export class StarTableItemComponent {
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<void>();
}
