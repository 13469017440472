<lgv-search-input
  *ngIf="usersData.content.length > 0 || search || isLoading"
  placeholder="admin_users_search"
  (searchChanged)="onSearch($event)"
></lgv-search-input>
<lgv-generic-table
  *ngIf="usersData.content.length > 0 && !isLoading"
  [tableDataSubject]="usersTableChanged"
  [page]="page"
  [pageSize]="count"
  [totalCount]="totalCount"
  (pageChanged)="onPageChanged($event)"
  (updateRowEvent)="editClicked($event)"
  (deleteRowEvent)="deleteClicked($event)"
  (sendInviteEvent)="sendInviteClicked($event)"
  (changeAccountStatusEvent)="changeAccountStatusClicked($event)"
></lgv-generic-table>

<lgv-fab-button type="add" (btnClicked)="addClicked()"></lgv-fab-button>

<lgv-empty-state
  symbol="help"
  *ngIf="usersData.content.length === 0 && !isLoading && search"
  titleText="admin_users_notfound_title"
  text="admin_users_notfound_message"
></lgv-empty-state>

<lgv-empty-state
  symbol="add_circle"
  *ngIf="usersData.content.length === 0 && !isLoading && !search"
  titleText="admin_users_init_title"
  text="admin_users_init_message"
></lgv-empty-state>
