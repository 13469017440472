<mat-form-field [appearance]="appearance">
  <span matTextPrefix *ngIf="prefix">{{ prefix }}&nbsp;</span>
  <mat-label>
    {{ text | translate }}
  </mat-label>
  <mat-select [formControl]="fc" #singleSelect>
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'global_search_placeholder' | translate"
        [formControl]="itemFilterCtrl"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let item of filteredItems | async" [value]="item.key">
      {{ item.value }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="fc.invalid">{{ errorMessage | translate }}</mat-error>
</mat-form-field>