<lgv-dialog-wrapper titleText="admin_dialogs_orgpermission_title">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-slide-toggle color="primary" formControlName="calendar_pro">Calendar Pro</mat-slide-toggle>
    <mat-slide-toggle color="primary" formControlName="app_platform">App Platform</mat-slide-toggle>
    <br />
    <h3>BeeCare</h3>
    <mat-slide-toggle color="primary" formControlName="beecare_filter">BeeCare Filter</mat-slide-toggle>
    <mat-slide-toggle color="primary" formControlName="reporting_new">Reporting New</mat-slide-toggle>
    <mat-slide-toggle color="primary" formControlName="export_perigon">Export for Perigon</mat-slide-toggle>

    <lgv-button-container buttonLeftText="admin_dialogs_orgpermission_buttonleft"
      buttonRightText="admin_dialogs_orgpermission_buttonright" buttonRightType="submit" [disabled]="isLoading"
      (onLeftClicked)="close()">
    </lgv-button-container>
  </form>
</lgv-dialog-wrapper>