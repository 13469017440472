import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import { AuthService } from '@app/services/auth.service';
import { DocumentService } from '@app/services/document.service';
import {
  GetAdminUsersResponseItem,
  GetOrganisationListResponseItem,
  PatchAdminUsersPayload,
  PostRegistrationLinkPayload,
} from '@schema/APITypes';
import { CustomImage } from '@schema/image.modules';
import { AddEditUserDialogComponent } from '@shared/dialogs/add-edit-user-dialog/add-edit-user-dialog.component';
import { CustomValidators } from '@shared/validators/customvalidators';

export interface IAddEditAdminUserDialogData {
  user?: GetAdminUsersResponseItem;
}

@Component({
  selector: 'lgv-add-edit-user-admin-dialog',
  templateUrl: './add-edit-user-admin-dialog.component.html',
})
export class AddEditUserAdminComponent implements OnInit {
  isLoading = false;
  image: CustomImage;
  roles = ['orgAdmin', 'caseManager'];
  organisations: GetOrganisationListResponseItem[] = [];
  formGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl(this.data.user?.firstname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    lastname: new UntypedFormControl(this.data.user?.lastname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    scope: new UntypedFormControl(this.data.user?.scopes, [Validators.required]),
    organisation: new UntypedFormControl({
      value: this.data.user?.organisation?.id,
      disabled: this.isOrganisationDisabled(this.data.user?.scopes),
    }),
    email: new UntypedFormControl(this.data.user?.email, [
      Validators.required,
      Validators.email,
      Validators.maxLength(2000),
    ]),
  });

  constructor(
    private dialogRef: MatDialogRef<AddEditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddEditAdminUserDialogData,
    private documentService: DocumentService,
    private authService: AuthService,
    private adminService: AdminService,
  ) {}

  async ngOnInit(): Promise<void> {
    const response = await this.adminService.getOrganisationsList();
    this.organisations = response.organisations;
    this.formGroup.get('scope').valueChanges.subscribe((value) => {
      switch (value) {
        case 'app':
          this.formGroup.get('organisation').disable();
          this.formGroup.get('organisation').setValue('');
          break;
        case 'cms':
          this.formGroup.get('organisation').disable();
          this.formGroup.get('organisation').setValue('');

          break;
        default:
          this.formGroup.get('organisation').enable();
          break;
      }
    });
  }

  isOrganisationDisabled(value: string): boolean {
    switch (value) {
      case 'app':
        return true;
      case 'cms':
        return true;
      default:
        return false;
    }
  }

  onFileSelected(image: CustomImage): void {
    this.image = image;
  }

  close(): void {
    this.dialogRef.close();
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      this.isLoading = true;
      try {
        if (this.data.user) {
          const payload: PatchAdminUsersPayload = {
            firstname: this.formGroup.get('firstname').value,
            lastname: this.formGroup.get('lastname').value,
            email: this.formGroup.get('email').value,
            imageID: this.data.user.imageID,
            isActive: this.data.user.isActive,
            organisationID: this.formGroup.get('organisation').value,
          };

          if (this.data.user.scopes !== 'cms') {
            payload.scopes = this.formGroup.get('scope').value;
          }

          if (this.image) {
            const response = await this.documentService.postDocument(this.image.file);
            payload.imageID = response.id;
          }
          await this.adminService.patchUser(payload, this.data.user.id);
        } else {
          const payload: PostRegistrationLinkPayload = {
            firstname: this.formGroup.get('firstname').value,
            lastname: this.formGroup.get('lastname').value,
            email: this.formGroup.get('email').value,
            organisationID: this.formGroup.get('organisation').value,
            scope: this.formGroup.get('scope').value,
          };
          if (this.image) {
            const response = await this.documentService.postDocument(this.image.file);
            payload.imageID = response.id;
          }
          await this.authService.registrationLink(payload);
        }

        this.isLoading = false;
        this.dialogRef.close({ message: 'ok' });
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
}
