import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintRoutingModule } from './print.routing';
import { PrintCalendarComponent } from './pages/print-calendar/print-calendar.component';
import { BryntumCalendarModule } from '@bryntum/calendar-angular';
import { PrintCalendarItemComponent } from './pages/print-calendar/print-calendar-item.component';

@NgModule({
  declarations: [PrintCalendarComponent, PrintCalendarItemComponent],
  imports: [CommonModule, PrintRoutingModule, BryntumCalendarModule],
})
export class PrintModule {}
