/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AUTH_PATHS_ABSOLUTE } from '@modules/auth/auth.paths';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CmsGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      if (!this.authService.isLoggedIn()) {
        return this.router.parseUrl(AUTH_PATHS_ABSOLUTE.AUTH_ROOT);
      }
      if (!this.authService.userInfo) {
        await this.authService.getUserInformation();
      }

      if (this.authService.userInfo?.scopes.includes('cms')) {
        return true;
      }
    } catch (error) {
      this.authService.logout();
      throw error;
    }

    this.authService.logout();
    return this.router.parseUrl(AUTH_PATHS_ABSOLUTE.AUTH_ROOT);
  }
}
