import { Component } from '@angular/core';

@Component({
  selector: 'lgv-not-found',
  template: `
    <h1 translate>global_notfound</h1>
    <button mat-flat-button color="primary" routerLink="/">Home</button>
  `,

  styles: [
    `
          :host {
            flex-grow: 1;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `,
  ],
})
export class NotFoundComponent {}
