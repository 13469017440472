import { APP_PATHS } from 'src/app/app.paths';

export const AUTH_PATHS = {
  AUTH_ROOT: '',
  AUTH_LOGIN: 'login',
  AUTH_LOGOUT: 'logout',
  AUTH_SIGN_UP: 'signup',
  AUTH_FORGOT_PASSWORD: 'forgot-password',
  AUTH_SET_NEW_PASSWORD: 'set-new-password',
  AUTH_REGISTRATION_LINK: 'registration-link',
};

export const AUTH_PATHS_ABSOLUTE = {
  AUTH_ROOT: `${APP_PATHS.AUTH}`,
  AUTH_LOGIN: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_LOGIN}`,
  AUTH_LOGOUT: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_LOGOUT}`,
  AUTH_SIGN_UP: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_SIGN_UP}`,
  AUTH_FORGOT_PASSWORD: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_FORGOT_PASSWORD}`,
  AUTH_SET_NEW_PASSWORD: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_SET_NEW_PASSWORD}`,
  AUTH_REGISTRATION_LINK: `${APP_PATHS.AUTH}/${AUTH_PATHS.AUTH_REGISTRATION_LINK}`,
};
