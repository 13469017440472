import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { relationships } from '@schema/form.models';

@Component({
  selector: 'lgv-representative-form',
  templateUrl: './representative-form.component.html',
})
export class RepresentativeFormComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() representative: boolean;
  relationships = relationships;

  getFormControls(str: string): UntypedFormControl {
    return this.formGroup.controls[str] as UntypedFormControl;
  }
}
