import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GetRequestListResponseItem } from '@schema/APITypes';

@Component({
  selector: 'lgv-request-link-table-item',
  template: ' <div *ngIf="data" (click)="onClick()">{{ data.lastName }} {{ data.firstName }}</div> ',
  styles: [
    `
          :host {
            cursor: pointer;
          }
        `,
  ],
})
export class RequestLinkTableItemComponent {
  @Input() data: GetRequestListResponseItem;
  @Input() link: string;

  constructor(private router: Router) {}

  onClick(): void {
    const url = this.router.createUrlTree([this.link.replace(':id', this.data.id)]);
    window.open(url.toString(), '_blank');
  }
}
