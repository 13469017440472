import { Component } from '@angular/core';
import { ADMIN_PATHS } from '@modules/admin/admin.paths';
import { NavItem } from '@schema/nav.models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lgv-admin-wrapper',
  template: ' <lgv-navigation [navItems]="navItems"> </lgv-navigation> ',
  styles: [
    `
      :host {
        flex-grow: 1;
        display: flex;
      }
    `,
  ],
})
export class AdminWrapperComponent {
  navItems: NavItem[] = [
    {
      text: 'admin_nav_admin',
      iconText: 'admin_panel_settings',
      route: '/admin',
      routerActive: false,
      visible: new BehaviorSubject(true),
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'admin_nav_users',
      iconText: 'people',
      route: ADMIN_PATHS.ADMIN_USER,
      visible: new BehaviorSubject(true),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'admin_nav_organisations',
      iconText: 'corporate_fare',
      visible: new BehaviorSubject(true),
      route: ADMIN_PATHS.ADMIN_ORGANISATION,
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
  ];
}
