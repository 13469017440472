import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import { OrganisationService } from '@app/services/organisation.service';
import { GetOrganisationResponseItem, PutOrganisationPayload } from '@schema/APITypes';
import { CustomValidators } from '@shared/validators/customvalidators';

export interface IAddEditOrganisationDialogData {
  organisation?: GetOrganisationResponseItem;
  isAdmin?: boolean;
}

@Component({
  selector: 'lgv-add-edit-organisation-dialog',
  templateUrl: './add-edit-organisation-dialog.component.html',
})
export class AddEditOrganisationDialogComponent {
  isLoading = false;
  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.data.organisation?.name, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    companyNumber: new UntypedFormControl(this.data.organisation?.companyNumber, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    website: new UntypedFormControl(this.data.organisation?.website, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    phoneNumber: new UntypedFormControl(this.data.organisation?.phoneNumber, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
  });
  addressFormGroup: UntypedFormGroup;
  constructor(
    private dialogRef: MatDialogRef<AddEditOrganisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddEditOrganisationDialogData,
    private adminService: AdminService,
    private organisationService: OrganisationService
  ) {}

  getAddressFormGroup(formGroup: UntypedFormGroup): void {
    this.addressFormGroup = formGroup;
  }

  async submit(): Promise<void> {
    try {
      if (this.formGroup.invalid || this.addressFormGroup.invalid) {
        this.addressFormGroup.markAllAsTouched();
        this.formGroup.markAllAsTouched();
      } else {
        this.isLoading = true;
        const payload: PutOrganisationPayload = {
          companyNumber: this.formGroup.value.companyNumber,
          name: this.formGroup.value.name,
          website: this.formGroup.value.website,
          address: {
            city: this.addressFormGroup.value.city,
            country: this.addressFormGroup.value.country,
            street: this.addressFormGroup.value.street,
            zipCode: this.addressFormGroup.value.zipCode,
          },
          phoneNumber: this.formGroup.value.phoneNumber,
          currency: this.data.organisation?.currency,
          kmInternalPrice: this.data.organisation?.kmInternalPrice,
          kmExternalPrice: this.data.organisation?.kmExternalPrice,
          zsr: this.data.organisation?.zsr,
          qrIban: this.data.organisation?.qrIban,
          idPvbr: this.data.organisation?.idPvbr,
          vat: this.data.organisation?.vat,
          taxNumber: this.data.organisation?.taxNumber,
        };

        if (this.data.organisation) {
          payload.address.id = this.data.organisation.address.id;

          if (this.data.isAdmin) {
            await this.adminService.putOrganisation(payload, this.data.organisation.id);
            this.dialogRef.close({ message: 'ok' });
          } else {
            await this.organisationService.putOrganisation(payload);
            this.dialogRef.close({ message: 'ok' });
          }
        } else {
          await this.adminService.postOrganisation(payload);
          this.dialogRef.close({ message: 'ok' });
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }

  close(): void {
    this.dialogRef.close();
  }
}
