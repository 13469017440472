import { Component, Input } from '@angular/core';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-empty-state',
  template: `
    <mat-icon>{{ symbol }}</mat-icon>

    <h2 lgvMyDirective translate>{{ titleText }}</h2>
    <p translate>{{ text }}</p>

    <div class="buttons">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: auto;
      }

      mat-icon {
        color: #878787;
        width: 240px;
        height: 240px;
        font-size: 240px;
      }

      p {
        font-size: 12px;
        color: #878787;
        font-weight: 600;
      }

      .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 24px;
      }
    `,
  ],
})
export class EmptyStateComponent {
  @Input() public titleText: MessageIDS;
  @Input() public text: MessageIDS;
  @Input() public symbol: 'help' | 'lock' | 'error' | 'add_circle' = 'add_circle';
}
