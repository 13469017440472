/* eslint-disable @typescript-eslint/no-empty-function */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { environment } from '@environment/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  Sentry.init({
    dsn: 'https://f3494c240d45009c58bf2fc1af5bfce4@o4507413225275392.ingest.de.sentry.io/4507413268987984',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  });
  window.console.log = () => {};
  window.console.error = () => {};
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
