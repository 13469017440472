/* eslint-disable camelcase */
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import {
  GetOrganisationResponseItem,
  OrganisationFeaturePermission,
  PostOrganisationPermissionPayload,
} from '@schema/APITypes';

export interface IEditOrganisationPermissionseDialogData {
  organisation: GetOrganisationResponseItem;
}

@Component({
  selector: 'lgv-edit-organisation-permissions',
  templateUrl: './edit-organisation-permissions.component.html',
  styles: [
    `
      .form mat-slide-toggle {
        margin: 8px 0;
        display: block;
      }
    `,
  ],
})
export class EditOrganisationPermissionsComponent {
  isLoading = false;

  formGroup = new UntypedFormGroup({
    calendar_pro: new UntypedFormControl(this.containsFeaturePermission('calendar_pro')),
    reporting_new: new UntypedFormControl(this.containsFeaturePermission('reporting_new')),
    export_perigon: new UntypedFormControl(this.containsFeaturePermission('export_perigon')),
    beecare_filter: new UntypedFormControl(this.containsFeaturePermission('beecare_filter')),
    app_platform: new UntypedFormControl(this.containsFeaturePermission('app_platform')),
  });

  constructor(
    private dialogRef: MatDialogRef<EditOrganisationPermissionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditOrganisationPermissionseDialogData,
    private adminService: AdminService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  async submit(): Promise<void> {
    try {
      this.isLoading = true;
      const payload: PostOrganisationPermissionPayload = [];
      const formData = this.formGroup.value;
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          payload.push(key as OrganisationFeaturePermission);
        }
      });
      await this.adminService.postOrganisationPermissions(this.data.organisation.id, payload);
      this.dialogRef.close({ message: 'ok' });
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  containsFeaturePermission(feature: OrganisationFeaturePermission): boolean {
    return !!this.data.organisation.organisationFeaturePermission?.find((x) => x === feature);
  }
}
