import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lgv-edit-delete-table-more-item',
  template: `
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="editClicked.emit()">
        <mat-icon class="material-icons-outlined">edit</mat-icon>
        <span translate>shared_component_generictable_edit</span>
      </button>
      <button mat-menu-item (click)="deleteClicked.emit()">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span translate>shared_component_generictable_delete</span>
      </button>
    </mat-menu>
  `,
})
export class EditDeleteTableMoreItemComponent {
  @Output() editClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
}
