import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes.module';

import { GenericTableComponent } from './generic-table.component';
import { OrganisationMoreTableItemComponent } from './more-table-item/organisation-more-table-item.component';
import { RequestTableMoreItemComponent } from './more-table-item/request-table-more-item.component';
import { UserAdminMoreTableItemComponent } from './more-table-item/user-admin-more-table-item.component';
import { UserMoreTableItemComponent } from './more-table-item/user-more-table-item.component';
import { BoolTableItemComponent } from './table-item/bool-table-item.component';
import { DateTableItemComponent } from './table-item/date-table-item.component';
import { HoursMinutesTableItemComponent } from './table-item/hours-minutes-table-item.component';
import { IconTableItemComponent } from './table-item/icon-table-item.component';
import { ImageTableItemComponent } from './table-item/image-table-item.component';
import { NumericTableItemComponent } from './table-item/numeric-table-item.component';
import { SubTableItemComponent } from './table-item/sub-table-item.component';
import { RequestLinkTableItemComponent } from './table-item/request-link-table-item.component';
import { StatusTableItemComponent } from './table-item/status-table-item.component';
import { SubSubtextTableItemComponent } from './table-item/subsubtext-table-item.component';
import { SubtextTableItemComponent } from './table-item/subtext-table-item.component';
import { DownloadTableItemComponent } from './table-item/download-table-item.component';
import { StarTableItemComponent } from './table-item/star-table-item.component';
import { EditDeleteTableMoreItemComponent } from './more-table-item/edit-delete-table-more-item.component';
@NgModule({
  declarations: [
    GenericTableComponent,
    ImageTableItemComponent,
    DateTableItemComponent,
    UserMoreTableItemComponent,
    SubtextTableItemComponent,
    SubSubtextTableItemComponent,
    BoolTableItemComponent,
    StatusTableItemComponent,
    StarTableItemComponent,
    IconTableItemComponent,
    UserAdminMoreTableItemComponent,
    RequestLinkTableItemComponent,
    NumericTableItemComponent,
    SubTableItemComponent,
    RequestTableMoreItemComponent,
    HoursMinutesTableItemComponent,
    OrganisationMoreTableItemComponent,
    DownloadTableItemComponent,
    EditDeleteTableMoreItemComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, MaterialModule, TranslateModule, PipesModule],
  exports: [
    GenericTableComponent,
    ImageTableItemComponent,
    DateTableItemComponent,
    UserMoreTableItemComponent,
    SubtextTableItemComponent,
    SubSubtextTableItemComponent,
    BoolTableItemComponent,
    IconTableItemComponent,
    StatusTableItemComponent,
    StarTableItemComponent,
    UserAdminMoreTableItemComponent,
    RequestLinkTableItemComponent,
    NumericTableItemComponent,
    SubTableItemComponent,
    RequestTableMoreItemComponent,
    HoursMinutesTableItemComponent,
    OrganisationMoreTableItemComponent,
    DownloadTableItemComponent,
    EditDeleteTableMoreItemComponent,
  ],
})
export class GenericTableModule {}
