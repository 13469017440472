/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetDocumentPipe } from './pipes/getDocument.pipe';
import { GetViewNamePipe } from './pipes/getViewName.pipe';
import { HourFormatterPipe } from './pipes/hourFormatter.pipe';
import { HourFormatterLargePipe } from './pipes/hourFormatterLarge.pipe';

@NgModule({
  declarations: [GetDocumentPipe, GetViewNamePipe, HourFormatterPipe, HourFormatterLargePipe],
  imports: [CommonModule],
  exports: [GetDocumentPipe, GetViewNamePipe, HourFormatterPipe, HourFormatterLargePipe],
})
export class PipesModule {}
