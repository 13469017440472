import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@shared/services/theme.service';

@Component({
  selector: 'lgv-fab-button',
  template: `
    <button color="primary" mat-fab class="add-button" (click)="btnClicked.emit()">
      <mat-icon>{{ type }}</mat-icon>
    </button>
  `,
  styles: [
    `
      :host {
        height: 56px;
        width: 56px;
        position: fixed;
        right: 48px;
        bottom: 66px;
        z-index: 1;
        cursor: pointer;
      }
    `,
  ],
})
export class FabButtonComponent {
  @Input() type: string;
  @Output() btnClicked = new EventEmitter<void>();
  constructor(public themeService: ThemeService) {}
}
