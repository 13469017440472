import { Injectable } from '@angular/core';
import {
  AdminGetOrganisationsResponse,
  GetAdminUsersResponse,
  GetOrganisationsListResponse,
  PatchAdminUsersPayload,
  PostOrganisationPayload,
  PostOrganisationPermissionPayload,
} from '@schema/APITypes';
import { GetListOptions } from '@schema/listoptions.models';
import queryString from 'query-string';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private apiService: ApiService) {}

  getUsers(options?: GetListOptions): Promise<GetAdminUsersResponse> {
    const string = queryString.stringifyUrl({
      url: 'admin/users',
      query: options
        ? {
            offset: options.offset,
            limit: options.limit,
            orderDir: options.orderDir,
            filterQuery: options.filterQuery,
          }
        : {},
    });
    return this.apiService.get(string);
  }

  patchUser(payload: PatchAdminUsersPayload, id: string): Promise<GetAdminUsersResponse> {
    return this.apiService.patch(`admin/users/${id}`, payload);
  }

  deleteUser(id: string): Promise<void> {
    return this.apiService.delete(`admin/users/${id}`);
  }

  getOrganisations(options?: GetListOptions): Promise<AdminGetOrganisationsResponse> {
    const string = queryString.stringifyUrl({
      url: 'admin/organisations',
      query: options
        ? {
            offset: options.offset,
            limit: options.limit,
            orderDir: options.orderDir,
            filterQuery: options.filterQuery,
          }
        : {},
    });
    return this.apiService.get(string);
  }
  getOrganisationsList(): Promise<GetOrganisationsListResponse> {
    return this.apiService.get('admin/organisations/list');
  }

  postOrganisationPermissions(id: string, payload: PostOrganisationPermissionPayload): Promise<void> {
    return this.apiService.post(`admin/organisations/${id}/permissions`, payload);
  }
  postOrganisation(payload: PostOrganisationPayload): Promise<void> {
    return this.apiService.post('admin/organisations', payload);
  }

  putOrganisation(payload: PostOrganisationPayload, id: string): Promise<void> {
    return this.apiService.put(`admin/organisations/${id}`, payload);
  }

  deleteOrganisation(id: string): Promise<void> {
    return this.apiService.delete(`admin/organisations/${id}`);
  }
}
