/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomImage } from '@schema/image.modules';

@Component({
  selector: 'lgv-profile-image-picker',
  template: `
    <button
      mat-button
      class="add-img-container grey-background"
      (click)="uploadImageClicked()"
      *ngIf="!selectedFile && !imageURL"
    >
      <div class="img-white-sub-container">
        <mat-icon class="uploadImg"> file_upload </mat-icon>
      </div>
    </button>
    <div class="add-img-container" (click)="uploadImageClicked()" *ngIf="imageURL || selectedFile">
      <img *ngIf="imageURL && !selectedFile" [src]="imageURL" class="image-background" />
      <div *ngIf="selectedFile" class="image-preview" [style.backgroundImage]="'url(' + selectedFile?.src + ')'"></div>
    </div>
    <input
      style="display: none"
      #fileInput
      type="file"
      accept="image/jpeg, image/png"
      (change)="onFileSelected($event)"
    />
  `,
  styleUrls: ['./profile-image-picker.component.scss'],
})
export class ProfileImagePickerComponent {
  @Input() imageURL: string;
  @Output() fileChanged = new EventEmitter<CustomImage>();
  @ViewChild('fileInput') inputField: ElementRef;

  selectedFile: CustomImage;

  uploadImageClicked(): void {
    this.inputField.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files.item(0);
    this.handleFiles(file);
    this.inputField.nativeElement.value = null;
  }

  deleteClicked(): void {
    this.selectedFile = null;
    this.fileChanged.emit(null);
  }

  handleFiles(files: File): void {
    if (FileReader) {
      if (files) {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          this.selectedFile = {
            file: files,
            src: (event.target as any).result,
          };
          this.fileChanged.emit(this.selectedFile);
        };
        fileReader.readAsDataURL(files);
      }
    }
  }
}
