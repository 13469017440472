import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { SnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'lgv-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
      .form {
        min-height: 200px;
      }
    `,
  ],
})
export class ForgotPasswordComponent {
  isLoading = false;
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  constructor(private auth: AuthService, private snackbarService: SnackbarService) {}

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
    } else {
      try {
        this.isLoading = true;
        const username = this.formGroup.get('email').value as string;
        await this.auth.sendForgotPasswordEmail({ username });
        this.snackbarService.showInfo('auth_forgotpassword_info_emailsend');
        this.formGroup.get('email').setValue('');
        this.isLoading = false;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
