<lgv-dialog-wrapper [titleText]="data.title">
  <p translate style="max-width: 500px">{{ data.message }}</p>
  <lgv-button-container
    buttonLeftText="shared_component_dialog_delete_cancel"
    [buttonRightText]="data.button"
    [disabled]="isLoading"
    (onLeftClicked)="close()"
    (onRightClicked)="submit()"
  >
  </lgv-button-container>
</lgv-dialog-wrapper>
