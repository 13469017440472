import { APP_PATHS } from 'src/app/app.paths';

export const ADMIN_PATHS = {
  ADMIN_ROOT: '',
  ADMIN_USER: 'users',
  ADMIN_ORGANISATION: 'organisations',
};

export const ADMIN_PATHS_ABSOLUTE = {
  ADMIN_ROOT: `${APP_PATHS.ADMIN}`,
  ADMIN_USERS: `${APP_PATHS.ADMIN}/${ADMIN_PATHS.ADMIN_USER}`,
  ADMIN_ORGANISATION: `${APP_PATHS.ADMIN}/${ADMIN_PATHS.ADMIN_ORGANISATION}`,
};
