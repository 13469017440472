<lgv-dialog-wrapper [titleText]="data.user ? 'admin_dialogs_user_edituser' : 'admin_dialogs_user_adduser'">
  <lgv-profile-image-picker
    (fileChanged)="onFileSelected($event)"
    [imageURL]="data.user?.imageID | getDocument | async"
    style="justify-content: center; margin-bottom: 32px"
  ></lgv-profile-image-picker>

  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label translate>admin_dialogs_user_firstname</mat-label>
      <input matInput formControlName="firstname" />
      <mat-error *ngIf="this.getField('firstname').invalid">
        {{ 'admin_dialogs_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>admin_dialogs_user_lastname</mat-label>
      <input matInput formControlName="lastname" />
      <mat-error *ngIf="this.getField('lastname').invalid"> {{ 'admin_dialogs_user_required' | translate }} </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>admin_dialogs_user_role</mat-label>
      <mat-select formControlName="scope" *ngIf="data.user?.scopes !== 'cms'">
        <mat-option *ngFor="let item of roles" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
      <mat-select formControlName="scope" *ngIf="data.user?.scopes === 'cms'">
        <mat-option value="cms"> cms </mat-option>
      </mat-select>
      <mat-error *ngIf="this.getField('scope').hasError('required')">
        {{ 'admin_dialogs_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>admin_dialogs_user_organisation</mat-label>
      <mat-select formControlName="organisation">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let item of organisations" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="this.getField('scope').hasError('required')">
        {{ 'admin_dialogs_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>admin_dialogs_user_email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="this.getField('email').invalid"> {{ 'admin_dialogs_user_required' | translate }} </mat-error>
    </mat-form-field>

    <lgv-button-container
      buttonLeftText="admin_dialogs_user_buttonleft"
      buttonRightText="admin_dialogs_user_buttonright"
      buttonRightType="submit"
      [disabled]="isLoading"
      (onLeftClicked)="close()"
    >
    </lgv-button-container>
  </form>
</lgv-dialog-wrapper>
