import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EditDeleteRecurringDialogComponent,
  IEditDeleteRecurringDialogData,
  IEditDeleteRecurringDialogResult,
} from '@modules/dashboard/dialogs/edit-delete-recurring-dialog/edit-delete-recurring-dialog.component';
import {
  GetRequestListResponseItem,
  GetTodoResponseItem,
  PatchTimemanagementPayload,
  PostTimemanagementCalendarAbsencePayload,
  PostTimemanagementCalendarAbsenceResponse,
  PostTimemanagementCalendarPayload,
  PostTimemanagementCalendarResponse,
  PostTimemanagementCalendarResponseItem,
  PostTimemanagementPayload,
  PutTimemanagementPayload,
  RequestType,
  TimemanagementItem,
} from '@schema/APITypes';
import { DeleteDialogComponent } from '@shared/dialogs/delete-dialog/delete-dialog.component';
import { Utils } from '@shared/utils/utils';
import { isBefore, isEqual } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api.service';

export const TMDays: { name: string; selected: boolean; weekDay: number }[] = [
  { name: 'global_weekday_monday', weekDay: 0, selected: false },
  { name: 'global_weekday_tuesday', weekDay: 1, selected: false },
  { name: 'global_weekday_wednesday', weekDay: 2, selected: false },
  { name: 'global_weekday_thursday', weekDay: 3, selected: false },
  { name: 'global_weekday_friday', weekDay: 4, selected: false },
  { name: 'global_weekday_saturday', weekDay: 5, selected: false },
  { name: 'global_weekday_sunday', weekDay: 6, selected: false },
];

export interface PersonIdToColorMap {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimemanagementService {
  requestsSelected = new BehaviorSubject<{ propagate: boolean; value: GetRequestListResponseItem[] }>({
    propagate: false,
    value: [],
  });

  tmTypesSelected = new BehaviorSubject<{ propagate: boolean; value: string[] }>({
    propagate: false,
    value: [],
  });

  selectedDate = new BehaviorSubject<{ propagate: boolean; value: Date }>({ propagate: false, value: new Date() });
  availableRequests: GetRequestListResponseItem[] = [];
  sidelistSelectionByDate: { [key: string]: { id: string } } = {};
  colorByEmployee = new BehaviorSubject<boolean>(false);
  showHolidays = new BehaviorSubject<boolean>(true);
  selectedRequestType = new BehaviorSubject<RequestType>('employee');
  vacationSidebarOpen = new BehaviorSubject<boolean>(true);
  reloadCalendar = new Subject<void>();
  markVacationEvent = new Subject<PostTimemanagementCalendarResponseItem>();
  viewTodoEvent = new Subject<{ request: GetRequestListResponseItem; from: Date; todo: GetTodoResponseItem }>();

  constructor(private apiService: ApiService) {
    this.selectedRequestType.subscribe(() => {
      this.requestsSelected.next({ propagate: true, value: [] });
    });
  }

  postTimemanagement(payload: PostTimemanagementPayload): Promise<void> {
    return this.apiService.post('timemanagement', payload);
  }

  putTimemanagement(payload: PutTimemanagementPayload, id: string): Promise<void> {
    return this.apiService.put(`timemanagement/${id}`, payload);
  }

  patchTimemanagement(payload: PatchTimemanagementPayload, timemanagementId: string): Promise<void> {
    return this.apiService.patch(`timemanagement/${timemanagementId}`, payload);
  }

  postTimemanagementCalendar(payload: PostTimemanagementCalendarPayload): Promise<PostTimemanagementCalendarResponse> {
    return this.apiService.post('timemanagement/calendar', payload);
  }

  postTimemanagementCalendarAbsence(
    payload: PostTimemanagementCalendarAbsencePayload,
  ): Promise<PostTimemanagementCalendarAbsenceResponse> {
    return this.apiService.post('timemanagement/calendar/absence', payload);
  }

  handleDragDropEvent(newStart: Date, newEnd: Date, tm: TimemanagementItem): PostTimemanagementPayload {
    const payload: PostTimemanagementPayload = {
      fromTime: Utils.getUTCDate(newStart),
      toTime: Utils.getUTCDate(newEnd),
      frequencyType: tm.frequencyType,
      requestId: tm.requestId,
      employeeId: tm.employeeId,
      serviceId: tm.service?.id,
      servicePerForfait: tm.servicePerForfait,
      tmType: tm.tmType,
      notes: tm.notes,
    };

    if (tm.frequencyType !== 'none') {
      payload.weekdays = tm.weekdays;
      payload.interval = tm.interval;

      if (tm.dtStart) {
        const d = new Date(tm.dtStart);
        payload.dtStart = Utils.getUTCDate(
          new Date(d.getFullYear(), d.getMonth(), d.getDate(), newStart.getHours(), newStart.getMinutes()),
        );
      }

      if (tm.until) {
        const d = new Date(tm.until);
        payload.until = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          newStart.getHours(),
          newStart.getMinutes(),
        );
      }

      if (payload.until && isBefore(new Date(payload.until), new Date(payload.dtStart))) {
        payload.until = Utils.getUTCDate(new Date(payload.dtStart));
      }
    }

    return payload;
  }

  async handleEditEvent(
    payload: PutTimemanagementPayload,
    isFromDragDropAndOtherDay: boolean,
    dialog: MatDialog,
    tm: TimemanagementItem,
    reload: Subject<void>,
  ): Promise<void> {
    if (tm.frequencyType !== 'none') {
      const dialog2Ref = dialog.open(EditDeleteRecurringDialogComponent, {
        data: {
          title: 'dashboard_feature_calendar_editevent',
          isFromDragDropAndOtherDay,
          splitUntilMinDate: payload.calendarItem.from,
          tm,
        } as IEditDeleteRecurringDialogData,
      });
      dialog2Ref.afterClosed().subscribe(async (result: IEditDeleteRecurringDialogResult) => {
        if (result) {
          payload.splitOperator = result.splitOperator;
          payload.splitUntil = result.untilDate;
          await this.putTimemanagement(payload, tm.id);
          reload.next();
        } else {
          reload.next();
        }
      });
    } else {
      await this.putTimemanagement(payload, tm.id);
      reload.next();
    }
  }

  async handleDeleteEvent(
    payload: PatchTimemanagementPayload,
    dialog: MatDialog,
    tm: TimemanagementItem,
    reload: Subject<boolean>,
  ): Promise<void> {
    if (tm.frequencyType !== 'none') {
      const dialog2Ref = dialog.open(EditDeleteRecurringDialogComponent, {
        data: {
          title: 'dashboard_feature_calendar_deleteevent',
          splitUntilMinDate: payload.calendarItem.from,
          tm,
        } as IEditDeleteRecurringDialogData,
      });
      dialog2Ref.afterClosed().subscribe(async (result: IEditDeleteRecurringDialogResult) => {
        if (result) {
          payload.splitOperator = result.splitOperator;
          payload.splitUntil = result.untilDate;
          await this.patchTimemanagement(payload, tm.id);
          reload.next(true);
        } else {
          reload.next(false);
        }
      });
    } else {
      const dialogCloseRef = dialog.open(DeleteDialogComponent, {
        data: {
          deleteFunction: async () => {
            await this.patchTimemanagement(payload, tm.id);
            reload.next(true);
          },
        },
      });
      dialogCloseRef.afterClosed().subscribe((answer) => {
        if (answer?.message !== 'ok') {
          reload.next(false);
        }
      });
    }
  }

  hasTmItemChanged(payload: PostTimemanagementPayload, newStart: Date, newEnd: Date, tm: TimemanagementItem): boolean {
    if (!payload) {
      return false;
    }

    if (payload.employeeId !== tm.employeeId) {
      return true;
    }

    if (payload.weekdays !== tm.weekdays) {
      return true;
    }

    if (payload.frequencyType !== tm.frequencyType) {
      return true;
    }
    if (payload.interval !== tm.interval) {
      return true;
    }
    if (payload.serviceId && payload.serviceId !== tm.service.id) {
      return true;
    }
    if (payload.servicePerForfait !== tm.servicePerForfait) {
      return true;
    }
    if (!isEqual(newStart, payload.fromTime)) {
      return true;
    }
    if (!isEqual(newEnd, payload.toTime)) {
      return true;
    }

    if ((tm.until || payload.until) && !isEqual(new Date(tm.until), payload.until)) {
      return true;
    }

    if ((tm.dtStart || payload.dtStart) && !isEqual(new Date(tm.dtStart), payload.dtStart)) {
      return true;
    }

    return false;
  }
}
