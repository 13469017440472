import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@app/services/auth.service';
import { DocumentService } from '@app/services/document.service';
import { LocalizationService } from '@app/services/localization.service';
import { DASHBOARD_PATHS } from '@modules/dashboard/dashboard.paths';
import {
  AddEditUserInfoDialogComponent,
  IAddEditUserInfoDialogData,
} from '@modules/dashboard/dialogs/add-edit-userinfo-dialog/add-edit-userinfo-dialog.component';
import { GetUserInfoResponse } from '@schema/APITypes';
import { NavItem } from '@schema/nav.models';
import { GeneralService } from '@shared/services/general.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { ThemeService } from '@shared/services/theme.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environment/environment';

@Component({
  selector: 'lgv-navigation',
  templateUrl: './navigation.component.html',
  styles: [
    `
      :host {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      mat-sidenav {
        display: flex;
      }
    `,
  ],
})
export class NavigationComponent {
  @Input() navItems: NavItem[] = [];
  @ViewChild('sidenav') sidenav: MatSidenav;
  @HostBinding('class') className = '';
  darkMode = false;
  isExpanded = false;
  isProduction = environment.production;

  navItemsBottom: NavItem[] = [
    {
      text: 'dashboard_nav_organisation',
      iconText: 'corporate_fare',
      route: DASHBOARD_PATHS.DASHBOARD_ORGANISATION,
      visible: new BehaviorSubject(true),
      routerActive: true,
      hiddeBadge: true,
      badgeCount: 0,
    },
    {
      text: 'Unkown',
      iconText: 'account_circle',
      visible: new BehaviorSubject(true),
      routerActive: false,
      hiddeBadge: true,
      badgeCount: 0,
    },
  ];

  userInfo: GetUserInfoResponse;
  isDarkMode: boolean;
  constructor(
    public generalService: GeneralService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    public themeService: ThemeService,
    private documentService: DocumentService,
    private localizationService: LocalizationService,
  ) {
    this.isDarkMode = this.themeService.isDarkMode();
    this.isExpanded = this.localStorageService.get('expanded') === 'true';
    this.authService.getUserInfoObs().subscribe(async (info) => {
      if (info) {
        this.userInfo = info;
        const accountItem = this.navItemsBottom.find((item) => item.iconText === 'account_circle');
        const orgItem = this.navItemsBottom.find((item) => item.iconText === 'corporate_fare');
        if (this.userInfo.firstname && this.userInfo.lastname) {
          accountItem.text = this.userInfo.firstname + ' ' + this.userInfo.lastname[0] + '.';
        }
        if (this.userInfo.imageID) {
          const response = await this.documentService.getSignedUrl(this.userInfo.imageID);
          accountItem.imageURL = response?.url;
        }

        if (!this.userInfo.organisation) {
          orgItem.visible = new BehaviorSubject(false);
        }
        this.localizationService.changeLanguage(this.userInfo.language);
      }
    });
  }

  switchMode(): void {
    this.isDarkMode = this.themeService.isDarkMode();

    if (this.isDarkMode) {
      this.themeService.update('light-mode');
    } else {
      this.themeService.update('dark-mode');
    }
  }

  async changePassword(): Promise<void> {
    try {
      const username = this.userInfo.email;
      await this.authService.sendForgotPasswordEmail({ username });
      this.snackbarService.showInfo('dashboard_info_send');
    } catch (error) {
      this.snackbarService.showError('dashboard_info_send_failed');
    }
  }

  updateUserProfileEvent(): void {
    const ref = this.dialog.open(AddEditUserInfoDialogComponent, {
      data: {
        currentUser: this.userInfo,
      } as IAddEditUserInfoDialogData,
    });

    ref.afterClosed().subscribe(async () => await this.authService.getUserInformation());
  }

  expectedChanged(): void {
    this.isExpanded = !this.isExpanded;
    this.localStorageService.save('expanded', this.isExpanded.toString());
  }
}
