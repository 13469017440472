import { CurrencyPipe } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminModule } from '@modules/admin/admin.module';
import { BreadcrumbService, BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthModule } from './modules/auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { AdminWrapperComponent } from './wrapper/admin-wrapper/admin-wrapper.component';
import { DashboardWrapperComponent } from './wrapper/dashboard-wrapper/dashboard-wrapper.component';
import { NotFoundComponent } from './wrapper/not-found/not-found.component';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { GlobalErrorHandler } from '@app/interceptor/globalerrorhandling';
import { TokenInterceptor } from '@app/interceptor/token.interceptor';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '@app/interceptor/http-error.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import enMessages from '../assets/i18n/en.json';
import { DEFAULT_LANGUAGE } from './constants';
import { NavigationItemComponent } from './wrapper/navigation-item/navigation-item.component';
import { NavigationComponent } from './wrapper/navigation/navigation.component';
import { PrintModule } from '@modules/print/print.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export type MessageIDS = keyof typeof enMessages;
@NgModule({
  declarations: [
    AppComponent,
    DashboardWrapperComponent,
    NotFoundComponent,
    NavigationComponent,
    NavigationItemComponent,
    AdminWrapperComponent,
  ],

  imports: [
    BrowserModule,
    AuthModule,
    CoreModule,
    SharedModule,
    AdminModule,
    PrintModule,
    AppRoutingModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      // interceptor for HTTP errors
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    BreadcrumbService,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
