import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { GeneralService } from '@shared/services/general.service';
import { SnackbarService } from '@shared/services/snackbar.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private snackbarService: SnackbarService,
    private zone: NgZone,
    private generalService: GeneralService,
  ) {}

  handleError(error: Error): void {
    this.zone.run(() => {
      // client-side error
      if (!this.generalService.handeledByHTTPIntercepter) {
        this.snackbarService.showError('global_errorhandling_client');
        console.error(error);
      }
      this.generalService.handeledByHTTPIntercepter = false;
    });
  }
}
