import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMainSiteComponent } from './pages/admin-main-site/admin-main-site.component';
import { AdminRoutingModule } from './admin.routing';
import { AdminUsersComponent } from './pages/admin-users/admin-users.component';
import { AdminOrganisationsComponent } from './pages/admin-organisations/admin-organisations.component';
import { SharedModule } from '@shared/shared.module';
import { AddEditUserAdminComponent } from './dialogs/add-edit-user-admin-dialog/add-edit-user-admin-dialog.component';
import { PipesModule } from '@shared/pipes.module';
import { GenericTableModule } from '@shared/shared-modules/generic-table/generic-table.module';
import { TranslateModule } from '@ngx-translate/core';
import { EditOrganisationPermissionsComponent } from './dialogs/edit-organisation-permissions/edit-organisation-permissions.component';

@NgModule({
  declarations: [
    AdminMainSiteComponent,
    AdminUsersComponent,
    AdminOrganisationsComponent,
    AddEditUserAdminComponent,
    EditOrganisationPermissionsComponent,
  ],
  imports: [CommonModule, AdminRoutingModule, SharedModule, PipesModule, GenericTableModule, TranslateModule],
})
export class AdminModule {}
