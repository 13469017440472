import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_PATHS } from 'src/app/app.paths';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'lgv-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.auth.logout();
    this.router.navigateByUrl(APP_PATHS.ROOT);
  }
}
