/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AddressFormComponent } from './component/address-form/address-form.component';
import { CustomCheckboxComponent } from './component/custom-checkbox/custom-checkbox.component';
import { CustomAutocompleteChipFieldComponent } from './component/custom-form-field/custom-autocomplete-chip-field/custom-autocomplete-chip-field.component';
import { CustomAutocompleteImageComponent } from './component/custom-form-field/custom-autocomplete-image/custom-autocomplete-image.component';
import { CustomAutocompleteComponent } from './component/custom-form-field/custom-autocomplete/custom-autocomplete.component';
import { CustomDateFieldComponent } from './component/custom-form-field/custom-date-field/custom-date-field.component';
import { CustomInputFieldComponent } from './component/custom-form-field/custom-input-field/custom-input-field.component';
import { CustomSelectFieldComponent } from './component/custom-form-field/custom-select-field/custom-select-field.component';
import { CustomMatcheckboxComponent } from './component/custom-matcheckbox/custom-matcheckbox.component';
import { DialogWrapperComponent } from './component/dialog-wrapper/dialog-wrapper.component';
import { DocumentPickerComponent } from './component/document-picker/document-picker.component';
import { EmptyStateComponent } from './component/empty-state/empty-state.component';
import { FabButtonMenuComponent } from './component/fab-button-menu/fab-button-menu.component';
import { FabButtonComponent } from './component/fab-button/fab-button.component';
import { FilterToolbarComponent } from './component/filter-toolbar/filter-toolbar.component';
import { ImageButtonComponent } from './component/image-button/image-button.component';
import { ImagePickerComponent } from './component/image-picker/image-picker.component';
import { LoadingSpinnerComponent } from './component/loading-spinner/loading-spinner.component';
import { NumericStepperComponent } from './component/numeric-stepper/numeric-stepper.component';
import { PersonalInformationFormComponent } from './component/personal-information-form/personal-information-form.component';
import { ProfileImagePickerComponent } from './component/profile-image-picker/profile-image-picker.component';
import { SearchInputComponent } from './component/search-input/search-input.component';
import { AddEditOrganisationDialogComponent } from './dialogs/add-edit-organisation-dialog/add-edit-organisation-dialog.component';
import { AddEditUserDialogComponent } from './dialogs/add-edit-user-dialog/add-edit-user-dialog.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { MaterialModule } from './material.module';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { CustomSelectSearchComponent } from './component/custom-form-field/custom-select-search/custom-select-search.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomImageSelectSearchComponent } from './component/custom-form-field/custom-image-select-search/custom-image-select-search.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { UploadFieldComponent } from './component/upload-field/upload-field.component';
import { ButtonContainerComponent } from './component/button-container/button-container.component';
import { CustomImageMultiSelectSearchComponent } from './component/custom-form-field/custom-multi-image-select-search/custom-multi-image-select-search.component';
import { CustomChipSearchComponent } from './component/custom-form-field/custom-chip-search/custom-chip-search.component';
import { PipesModule } from './pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingBarComponent } from './component/loading-bar/loading-bar.component';
import { RepresentativeFormComponent } from './component/representative-form/representative-form.component';
import { FilterToolbarButtonComponent } from './component/filter-toolbar/filter-toolbar-button.component';
import { FilterToolbarMonthYearOptionalComponent } from './component/filter-toolbar/filter-toolbar-month-year-optional.component';
import { FilterToolbarMonthYearComponent } from './component/filter-toolbar/filter-toolbar-month-year.component';
import { FilterToolbarRangePickerComponent } from './component/filter-toolbar/filter-toolbar-range-picker.component';
import { FilterToolbarSingleSelectionComponent } from './component/filter-toolbar/filter-toolbar-single-selection.component';
import { FilterToolbarSingleSelectionNoTranslationComponent } from './component/filter-toolbar/filter-toolbar-single-selection-notranslation.component';
@NgModule({
  declarations: [
    DialogWrapperComponent,
    FabButtonMenuComponent,
    LoadingSpinnerComponent,
    AddEditUserDialogComponent,
    CustomCheckboxComponent,
    DocumentPickerComponent,
    FabButtonComponent,
    ProfileImagePickerComponent,
    ImageButtonComponent,
    PersonalInformationFormComponent,
    RepresentativeFormComponent,
    CustomInputFieldComponent,
    CustomDateFieldComponent,
    CustomSelectFieldComponent,
    CustomAutocompleteChipFieldComponent,
    CustomMatcheckboxComponent,
    CustomAutocompleteComponent,
    NumericStepperComponent,
    SearchInputComponent,
    AddEditOrganisationDialogComponent,
    AddressFormComponent,
    DeleteDialogComponent,
    FilterToolbarComponent,
    FilterToolbarButtonComponent,
    ImagePickerComponent,
    EmptyStateComponent,
    CustomAutocompleteImageComponent,
    FilterToolbarMonthYearComponent,
    FilterToolbarRangePickerComponent,
    FilterToolbarSingleSelectionComponent,
    FilterToolbarSingleSelectionNoTranslationComponent,
    MessageDialogComponent,
    LoadingBarComponent,

    FilterToolbarMonthYearOptionalComponent,
    CustomSelectSearchComponent,
    CustomImageSelectSearchComponent,
    UploadFieldComponent,
    UploadDialogComponent,
    ButtonContainerComponent,
    CustomImageMultiSelectSearchComponent,
    CustomChipSearchComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    LoadingSpinnerComponent,
    FabButtonMenuComponent,
    CustomCheckboxComponent,
    DocumentPickerComponent,
    FabButtonComponent,
    ProfileImagePickerComponent,
    ImageButtonComponent,
    PersonalInformationFormComponent,
    RepresentativeFormComponent,
    CustomInputFieldComponent,
    CustomDateFieldComponent,
    CustomSelectFieldComponent,
    CustomMatcheckboxComponent,
    CustomAutocompleteChipFieldComponent,
    NumericStepperComponent,
    SearchInputComponent,
    AddEditOrganisationDialogComponent,
    AddressFormComponent,
    DeleteDialogComponent,
    FilterToolbarComponent,
    FilterToolbarButtonComponent,
    ImagePickerComponent,
    CustomAutocompleteComponent,
    EmptyStateComponent,
    CustomAutocompleteImageComponent,
    FilterToolbarMonthYearComponent,
    FilterToolbarRangePickerComponent,
    FilterToolbarSingleSelectionComponent,
    FilterToolbarSingleSelectionNoTranslationComponent,
    FilterToolbarMonthYearOptionalComponent,
    CustomSelectSearchComponent,
    CustomImageSelectSearchComponent,
    UploadFieldComponent,
    UploadDialogComponent,
    ButtonContainerComponent,
    CustomImageMultiSelectSearchComponent,
    CustomChipSearchComponent,
    DialogWrapperComponent,
    LoadingBarComponent,
  ],
})
export class SharedModule {}
