import { Component, Input } from '@angular/core';

@Component({
  selector: 'lgv-numeric-table-item',
  template: '{{ value | number: format }}',
})
export class NumericTableItemComponent {
  @Input() format: string;
  @Input() value: string;
}
