import { LocaleHelper } from '@bryntum/calendar';
import En from '@bryntum/calendar/locales/calendar.locale.En.js';

export const It = {
  localeName: 'It',
  localeDesc: 'Italiano',
  // region Shared localization
  Button: {
    'Add column': 'Aggiungi colonna',
    'Show all day events': 'Mostra tutti gli eventi',
    Apply: 'Applica',
    'Display hints': 'Mostra suggerimenti',
    'My button': function MyButton(data) {
      return 'Mein knopf '.concat(data);
    },
    'Remove column': 'Rimuovi colonna',
  },
  EventEdit: {
    Name: 'Nome',
    Resource: 'Risorsa',
    Start: 'Inizio',
    End: 'Fine',
    Save: 'Salva',
    Delete: 'Elimina',
    Cancel: 'Annulla',
    'Edit event': 'Modifica evento',
    Repeat: 'Ripeti',
    Calendar: 'Calendario',
    'All day': 'Tutto il giorno',
  },
  SchedulerBase: {
    'Delete event': 'Eliminare evento',
  },
  Checkbox: {
    'Auto apply': 'Applica automaticamente',
    Automatically: 'Automaticamente',
  },
  CodeEditor: {
    'Code editor': 'Modifica codice',
    'Download code': 'Scarica codice',
  },
  Combo: {
    noResults: 'Nessun risultato',
    Theme: 'Scegli tema',
    Language: 'Scegli linguaggio',
    Size: 'Scegli la grandezza',
    recordNotCommitted: 'I dati non sono stati inseriti',
    addNewValue: function addNewValue(value) {
      return ''.concat(value, ' Aggiungi');
    },
  },
  Tooltip: {
    'Click to show info and switch theme or locale':
      'Clicca qui per vedere informazioni e per cambiare tema o schema regionale',
    'Click to show the built in code editor': 'Clicca qui per visualizzare code-editor integrato',
    CheckAutoHints:
      'Abilita questa opzione per visualizzare automaticamente i suggerimenti quando viene caricato un esempio',
    Fullscreen: 'Tutto lo schermo',
  },
  Shared: {
    'Locale changed': 'Lingua cambiata',
    'Full size': 'Full size',
    'Phone size': 'Phone size',
  },
  UndoRedo: {
    Undo: 'Annulla azione',
    Redo: 'Ripeti',
    UndoLastAction: 'Annulla ultima azione',
    RedoLastAction: 'Ripeti ultima azione annullata',
  },
  // endregion
  // Translations for common words and phrases which are used by all classes.
  Object: {
    Yes: 'Si',
    No: 'No',
    Cancel: 'Annulla',
    Ok: 'OK',
    newEvent: 'Nuovo evento',
    Week: 'Settimana', // used in DateHelper and Calendar views
  },
  // region Features
  ColumnPicker: {
    column: 'Colonna',
    columnsMenu: 'Colonne',
    hideColumn: 'Nascondi colonna',
    hideColumnShort: 'Nascondi',
    newColumns: 'Nuova colonna',
  },
  Filter: {
    applyFilter: 'Applica filtro',
    filter: 'Filtra',
    editFilter: 'Modifica filtro',
    on: 'su',
    before: 'prima',
    after: 'dopo',
    equals: 'uguale',
    lessThan: 'meno di',
    moreThan: 'piu di',
    removeFilter: 'rimuovi filtro',
  },
  FilterBar: {
    enableFilterBar: 'Mostra filtro',
    disableFilterBar: 'Nascondi filtro',
  },
  Group: {
    group: 'Gruppo',
    groupAscending: 'Raggruppa in ordine crescente',
    groupDescending: 'Raggruppa in ordine decrescente',
    groupAscendingShort: 'Crescente',
    groupDescendingShort: 'Decrescente',
    stopGrouping: 'Annulla raggruppamento',
    stopGroupingShort: 'Annulla',
  },
  Search: {
    searchForValue: 'Cerca valore',
  },
  Sort: {
    sort: 'Raggruppa',
    sortAscending: 'Raggruppa in ordine crescente',
    sortDescending: 'Raggruppa in ordine decrescente',
    multiSort: 'Raggruppamento multiplo',
    removeSorter: 'Rimuovi raggruppamento',
    addSortAscending: 'Aggiungi raggruppamento in ordine crescente',
    addSortDescending: 'Aggiungi raggruppamento in ordine decrescente',
    toggleSortAscending: 'Cambia in ordine crescente',
    toggleSortDescending: 'Cambia in ordine decrescente',
    sortAscendingShort: 'crescente',
    sortDescendingShort: 'decrescente',
    removeSorterShort: 'Rimuovi',
    addSortAscendingShort: '+ crescente',
    addSortDescendingShort: '+ decrescente',
  },
  // endregion

  // region Others
  DateHelper: {
    locale: 'it',
    weekStartDay: 1,
    // Non-working days which match weekends by default, but can be changed according to schedule needs
    nonWorkingDays: {
      0: true,
      6: true,
    },
    // Days considered as weekends by the selected country, but could be working days in the schedule
    weekends: {
      0: true,
      6: true,
    },
    unitNames: [
      {
        single: 'Millisecondi',
        plural: 'Millisecondi',
        abbrev: 'ms',
      },
      {
        single: 'Secondo',
        plural: 'Secondi',
        abbrev: 's',
      },
      {
        single: 'Minuto',
        plural: 'Minuti',
        abbrev: 'min',
      },
      {
        single: 'Ora',
        plural: 'Ore',
        abbrev: 'ore',
      },
      {
        single: 'Giorno',
        plural: 'Giorni',
        abbrev: 'g',
      },
      {
        single: 'Settimana',
        plural: 'Settimane',
        abbrev: 's',
      },
      {
        single: 'Mese',
        plural: 'Mesi',
        abbrev: 'mes',
      },
      {
        single: 'Quadrimestre',
        plural: 'Quadrimestri',
        abbrev: 'q',
      },
      {
        single: 'Anno',
        plural: 'Anni',
        abbrev: 'Anno',
      },
      {
        single: 'Decennio',
        plural: 'Decenni',
        abbrev: 'Dec',
      },
    ],
    // Used to build a RegExp for parsing time units.
    // The full names from above are added into the generated Regexp.
    // So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
    // When generating its display value though, it uses the full localized names above.
    unitAbbreviations: [
      ['mil'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['d'],
      ['w', 'wk'],
      ['mo', 'mon', 'mnt'],
      ['q', 'quar', 'qrt'],
      ['y', 'yr'],
      ['dek'],
    ],
    parsers: {
      L: 'DD.MM.YYYY',
      LT: 'HH:mm',
    },
    ordinalSuffix: function ordinalSuffix(number) {
      return number;
    },
  },
  PagingToolbar: {
    firstPage: 'Alla prima pagina',
    prevPage: 'Vai alla pagina precedente',
    page: 'Pagina',
    nextPage: 'Vai alla prossima pagina',
    lastPage: 'Vai ultima pagina',
    reload: 'Carica pagina',
    noRecords: 'Niente da mostrare',
    pageCountTemplate: function pageCountTemplate(data) {
      return 'von ' + data.lastPage;
    },
    summaryTemplate: function summaryTemplate(data) {
      return 'Eventi ' + data.start + ' - ' + data.end + ' von ' + data.allCount;
    },
  },
  PanelCollapser: {
    Collapse: 'Riduci',
    Expand: 'Espandi',
  },
  List: {
    loading: 'Caricamento...',
  },

  // region Calendar
  Calendar: {
    Today: 'Oggi',
    next: function next(unit) {
      return 'Prossima '.concat(unit);
    },
    previous: function previous(unit) {
      return 'Precedente '.concat(unit);
    },
    plusMore: function plusMore(value) {
      return '+'.concat(value, ' aggiuntivi');
    },
  },
  CalendarDrag: {
    holdCtrlForRecurrence: 'Tieni premuto il tasto CTRL per un evento ricorrente',
  },
  EventTip: {
    'Edit event': 'Modidica evento',
  },
  // AgendaView: {
  //   Agenda: 'Agenda',
  // },
  // MonthView: {
  //   Month: 'Mese',
  //   monthUnit: 'mese',
  // },
  // WeekView: {
  //   Week: 'Settimana',
  //   weekUnit: 'settimana',
  // },
  // YearView: {
  //   Year: 'Anno',
  //   yearUnit: 'anno',
  // },
  DayView: {
    Day: 'Giorno',
    dayUnit: 'giorno',
    daysUnit: 'giorno',
    expandAllDayRow: 'Espandi sezione per evento giornaliero',
    collapseAllDayRow: 'Espandi sezione per evento giornaliero',
  },
  Sidebar: {
    'Filter events': 'Filtra eventi',
  },
  WeekExpander: {
    expandTip: 'Clicca qui per espandere la riga',
    collapseTip: 'Clicca qui per ridurre la riga',
  }, // endregion
};

export const CalendarLocaleIT = LocaleHelper.mergeLocales(En, It);
