import { Component, Input } from '@angular/core';

export interface IUserImageInfos {
  userGUID: string;
  organisationGUID: string;
}

@Component({
  selector: 'lgv-image-table-item',
  template: `
    <div *ngIf="id" [style.backgroundImage]="'url(' + (id | getDocument | async) + ')'"></div>
    <mat-icon class="material-icons-outlined" *ngIf="!id">{{ defaultPicture }}</mat-icon>
  `,
  styles: [
    `
      div {
        height: 32px;
        width: 32px;
        border-radius: 32px;
        vertical-align: middle;
        background-size: cover;
        background-position: 50% 50%;
      }

      mat-icon {
        transform: scale(1.2);
      }
    `,
  ],
})
export class ImageTableItemComponent {
  @Input() id: string;
  @Input() defaultPicture: string;
}
