<lgv-dialog-wrapper [titleText]="data.title" (closed)="onCancelClicked()">
  <mat-radio-group class="radio-group" [(ngModel)]="selectedOption">
    <mat-radio-button color="primary" class="radio-button" *ngFor="let option of options" [value]="option.key"
      [disabled]="data.isFromDragDropAndOtherDay && option.key !== 'one' || (data.tm.interval>1 && data.tm.frequencyType==='daily' && option.key ==='until')">
      <div translate>{{ option.value }}</div>
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field (click)="dp.open()" *ngIf="selectedOption==='until'" appearance="fill">
    <mat-label translate>dashboard_dialogs_editdeleterecurring_until_date</mat-label>
    <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="dp" [(ngModel)]="untilDate"
      [min]="data.splitUntilMinDate" />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker [dateClass]="dateClass" #dp></mat-datepicker>
  </mat-form-field>

  <mat-error *ngIf="untilDateError" translate>dashboard_dialogs_editdeleterecurring_until_date_error</mat-error>


  <lgv-button-container buttonLeftText="dashboard_dialogs_editdeleterecurring_buttonleft"
    buttonRightText="dashboard_dialogs_editdeleterecurring_buttonright" (onLeftClicked)="onCancelClicked()"
    (onRightClicked)="onOkClicked()">
  </lgv-button-container>
</lgv-dialog-wrapper>