import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@shared/component/custom-form-field/custom-select-field/custom-select-field.component';
import { FormQuestionItem, FormSectionItem } from './APITypes';
import { CustomImage } from './image.modules';

export interface FormTypeItem {
  name: string;
  types: {
    key: string;
    icon?: string;
    value: string;
  }[];
}

export const formTypes: FormTypeItem[] = [
  {
    name: 'personal information',
    types: [
      {
        key: 'personalInformation',
        icon: 'face',
        value: 'form_typeitem_personalinfo',
      },
    ],
  },
  {
    name: 'textfields',
    types: [
      { key: 'text', icon: 'short_text', value: 'form_typeitem_shortanswer' },
      { key: 'textArea', icon: 'notes', value: 'form_typeitem_paragraph' },
      { key: 'datepicker', icon: 'event', value: 'form_typeitem_date' },
      { key: 'datepickerRange', icon: 'event', value: 'form_typeitem_daterange' },
    ],
  },
  {
    name: 'upload',
    types: [
      { key: 'imageUpload', icon: 'file_upload', value: 'form_typeitem_profilepicture' },
      { key: 'docUpload', icon: 'file_upload', value: 'form_typeitem_documentupload' },
    ],
  },
  {
    name: 'selection',
    types: [
      { key: 'smallButtonSelection', icon: 'check_box', value: 'form_typeitem_buttonshort' },
      { key: 'buttonSelection', icon: 'check_box', value: 'form_typeitem_button' },
      { key: 'select', icon: 'arrow_drop_down_circle', value: 'form_typeitem_dropdown' },
      { key: 'imageSelection', icon: 'collections', value: 'form_typeitem_imageselection' },
    ],
  },

  {
    name: 'others',
    types: [
      { key: 'numberStepper', icon: 'plus_one', value: 'form_typeitem_stepper' },
      { key: 'slider', icon: 'linear_scale', value: 'form_typeitem_scale' },
      { key: 'yesNo', icon: 'flaky', value: 'form_typeitem_yesno' },
      { key: 'availability', icon: 'event_available', value: 'form_typeitem_availability' },
    ],
  },
];

export interface CustomFormSectionItem extends FormSectionItem {
  selected: boolean;
  formQuestions: CustomFormQuestionItem[];
}
export interface CustomFormQuestionItem extends FormQuestionItem {
  selected: boolean;
  selectionInput?: any;
}

export interface QuestionPageResult {
  [key: string]: any;
}

export interface QuestionResult {
  [key: string]: QuestionPageResult;
}

export const imageArray = [
  'assets/icons/gender/both.svg',
  'assets/icons/gender/man.svg',
  'assets/icons/gender/woman.svg',
  'assets/icons/care/AdministrativeAufgaben.svg',
  'assets/icons/care/Nahrungsversorgung.svg',
  'assets/icons/care/AnAusziehen.svg',
  'assets/icons/care/Reisen.svg',
  'assets/icons/care/ArztTerminunterstuetzung.svg',
  'assets/icons/care/Transport.svg',
  'assets/icons/care/Blutdruckkontrolle.svg',
  'assets/icons/care/Verbandwechsel.svg',
  'assets/icons/care/Blutzuckermessen.svg',
  'assets/icons/care/Wundversorgung.svg',
  'assets/icons/care/Demenzversorgung.svg',
  'assets/icons/care/bodycare_black.svg',
  'assets/icons/care/Einkaufshilfe.svg',
  'assets/icons/care/comment_black.svg',
  'assets/icons/care/Erste-Hilfe-Erfahrung.svg',
  'assets/icons/care/disabled_black.svg',
  'assets/icons/care/Hobbys.svg',
  'assets/icons/care/hkp_black.svg',
  'assets/icons/care/Injektionen.svg',
  'assets/icons/care/Katheterversorgung.svg',
  'assets/icons/care/housekeeper_black.svg',
  'assets/icons/care/Kochen.svg',
  'assets/icons/care/remobilisation.svg',
  'assets/icons/care/Koerperpflege.svg',
  'assets/icons/care/special_black.svg',
  'assets/icons/care/Mobilisierung.svg',
];

export const relationships: SelectOption[] = [
  { key: '', value: 'form_relationship_none' },
  { key: 'spouse', value: 'form_relationship_spouse' },
  { key: 'child', value: 'form_relationship_sondaughter' },
  { key: 'caregiver', value: 'form_relationship_caregiver' },
  { key: 'grandchild', value: 'form_relationship_grandchildren' },
  { key: 'relatives', value: 'form_relationship_relatives' },
  { key: 'others', value: 'form_relationship_others' },
  // { key: 'friend', value: 'form_relationship_firends' },
];

export interface FormControlOutput {
  uid: string;
  fc: UntypedFormControl;
}

export interface FormGroupOutput {
  uid: string;
  fg: UntypedFormGroup;
}

export interface ImageUploadOutput {
  uid: string;
  file: CustomImage;
}
export interface DocUploadOutput {
  uid: string;
  files: File[];
}
export interface ImageSelectionOutput {
  uid: string;
  list: { text: string; selected: boolean; image: string }[];
}

export interface NumberOutput {
  uid: string;
  num: number;
}

export interface BoolOutput {
  uid: string;
  bool: boolean;
}

export interface AvailabilityOutput {
  uid: string;
  list: { name: string; values: boolean[] }[];
}
export interface ButtonSelectionOutput {
  uid: string;
  list: { text: string; selected: boolean }[];
}

export interface TimeManagementOutput {
  uid: string;
  list: { name: string; fc: UntypedFormControl }[];
}

export interface PICaregiverOutput {
  uid: string;
  fg: UntypedFormGroup;
}
export interface PICustomerOutput {
  uid: string;
  otherAddress: boolean;
  representative: boolean;
  formGroupClient: UntypedFormGroup;
  formGroupRepresentative: UntypedFormGroup;
  formGroupBillingAddress: UntypedFormGroup;
}
