import { Injectable } from '@angular/core';
import {
  GetFormResponse,
  GetOrganisationResponseItem,
  GetOrganisationUsersResponse,
  PatchOrganisationUsersPayload,
  PostOrganisationPayload,
  RequestType,
} from '@schema/APITypes';
import { GetUsersListOptions } from '@schema/listoptions.models';
import queryString from 'query-string';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private apiService: ApiService) {}

  putOrganisation(payload: PostOrganisationPayload): Promise<void> {
    return this.apiService.put('organisations', payload);
  }

  getUsersByOrganisation(options?: GetUsersListOptions): Promise<GetOrganisationUsersResponse> {
    const url = queryString.stringifyUrl({
      url: 'organisations/users',
      query: options
        ? {
            offset: options.offset,
            limit: options.limit,
            orderDir: options.orderDir,
            filterQuery: options.filterQuery,
            userFilterRole: options.userFilterRole,
            userFilterStatus: options.userFilterStatus,
            userSortColumn: options.userSortColumn,
          }
        : {},
    });
    return this.apiService.get(url);
  }

  getFormByOrganisation(organisationID: string, formID: string): Promise<GetFormResponse> {
    return this.apiService.get(`organisations/${organisationID}/form/${formID}`);
  }

  getFormTypeByOrganisation(organisationID: string, formType: RequestType): Promise<GetFormResponse> {
    return this.apiService.get(`organisations/${organisationID}/formtype/${formType}`);
  }

  getOrganisationDetails(): Promise<GetOrganisationResponseItem> {
    return this.apiService.get('organisations');
  }

  patchUser(userId: string, payload: PatchOrganisationUsersPayload): Promise<void> {
    return this.apiService.patch(`organisations/users/${userId}`, payload);
  }

  deleteUser(userId: string): Promise<void> {
    return this.apiService.delete(`organisations/users/${userId}`);
  }
}
