<button
  mat-stroked-button
  *ngIf="buttonLeftVisible"
  color="primary"
  [type]="buttonLeftType"
  class="stroked"
  (click)="onLeftClicked.emit()"
>
  {{ buttonLeftText | translate }}
</button>
<div *ngIf="buttonLeftVisible" style="width: 8px"></div>
<button
  mat-flat-button
  *ngIf="buttonRightVisible"
  color="primary"
  [disabled]="disabled"
  [type]="buttonRightType"
  class="flat"
  (click)="onRightClicked.emit()"
>
  {{ buttonRightText | translate }}
</button>
