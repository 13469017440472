import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ThemeService } from '@shared/services/theme.service';
import { MessageIDS } from 'src/app/app.module';

export interface ActionListItem {
  label: string;
  icon: string;
  action: string;
}
@Component({
  selector: 'lgv-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Input() placeholder: MessageIDS;
  @Input() border = true;
  @Input() initValue: string;
  @Input() actionList: ActionListItem[] = [];
  @Input() slowSearchChangeDetection = false;

  @Output() searchChanged = new EventEmitter<string>();
  @Output() onActionClicked = new EventEmitter<string>();
  @ViewChild('input') inputElement;

  formGroup = new UntypedFormGroup({});
  search = new UntypedFormControl('');

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    if (this.initValue) {
      this.search.setValue(this.initValue);
    }
    this.search.valueChanges.subscribe((value) => {
      if (this.slowSearchChangeDetection) {
        if ((value.length > 1 && value.length % 2 === 0) || value.length === 0) {
          this.searchChanged.emit(value);
        }
      } else {
        this.searchChanged.emit(value);
      }
    });
  }

  getAdditionalInputClasses(): string {
    if (this.search.value.length > 0) {
      return 'text-entered';
    }
  }

  getMatIconText(): string {
    if (this.search.value.length > 0) {
      return 'close';
    } else {
      return 'search';
    }
  }

  onSearchIconClicked(): void {
    if (this.search.value.length > 0) {
      this.search.setValue('');
    }

    this.inputElement.nativeElement.focus();
  }
}
