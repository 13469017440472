/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { PostLoginResponse } from '@schema/APITypes';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { lastValueFrom } from 'rxjs';

type IHTTPMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
interface IDoRequestOptions {
  method: IHTTPMethods;
  path: string;
  body?: any;
  contentType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  accessToken: string;
  refreshToken: string;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
    this.accessToken = this.localStorageService.get('accessToken');
    this.refreshToken = this.localStorageService.get('refreshToken');
  }

  async get(path: string, options?: { contentType?: string }): Promise<any> {
    return this.doRequest({
      method: 'GET',
      path,
      contentType: options?.contentType,
    });
  }

  async post(path: string, body?: any): Promise<any> {
    return this.doRequest({
      method: 'POST',
      path,
      body,
    });
  }

  async put(path: string, body?: any): Promise<any> {
    return this.doRequest({
      method: 'PUT',
      path,
      body,
    });
  }

  async patch(path: string, body?: any): Promise<any> {
    return this.doRequest({
      method: 'PATCH',
      path,
      body,
    });
  }

  async delete(path: string): Promise<any> {
    return this.doRequest({
      method: 'DELETE',
      path,
    });
  }

  saveTokens(response: PostLoginResponse): void {
    this.accessToken = response.access_token;
    this.refreshToken = response.refresh_token;

    this.localStorageService.save('accessToken', response.access_token);
    this.localStorageService.save('refreshToken', response.refresh_token);
  }

  resetAuth(): void {
    this.accessToken = '';
    this.refreshToken = '';

    this.localStorageService.save('accessToken', '');
    this.localStorageService.save('refreshToken', '');
  }

  private async doRequest(options: IDoRequestOptions): Promise<any> {
    const requestUrl = environment.API_BASE_URL + options.path;

    const header = new HttpHeaders();

    if (options.contentType) {
      header.append('Content-Type', options.contentType);
    }

    try {
      let response;

      if (options.method === 'GET') {
        response = await lastValueFrom(this.http.get(requestUrl, { headers: header }));
      } else if (options.method === 'POST') {
        response = await lastValueFrom(this.http.post(requestUrl, options.body, { headers: header }));
      } else if (options.method === 'PUT') {
        response = await lastValueFrom(this.http.put(requestUrl, options.body, { headers: header }));
      } else if (options.method === 'PATCH') {
        response = await lastValueFrom(this.http.patch(requestUrl, options.body, { headers: header }));
      } else if (options.method === 'DELETE') {
        response = await lastValueFrom(this.http.delete(requestUrl, { headers: header }));
      }

      return response;
    } catch (error) {
      throw error;
    }
  }
}
