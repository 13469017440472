import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'lgv-registration-link',
  templateUrl: './registration-link.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
    `,
  ],
})
export class RegistrationLinkComponent implements OnInit {
  isLoading = false;
  firstname = '';
  tokenNotFound = false;
  token = '';
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    repeatPassword: new UntypedFormControl('', [Validators.required]),
  });
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    await this.activatedRoute.queryParams.subscribe(async (params) => {
      try {
        const response = await this.authService.registrationLinkInfo(params.token);
        this.token = params.token;
        this.getField('email').setValue(response.email);
        this.firstname = response.firstname;
      } catch (error) {
        this.tokenNotFound = true;
        throw error;
      }
    });
    this.formGroup.valueChanges.subscribe((field) => {
      if (field.password !== field.repeatPassword) {
        this.getField('repeatPassword').setErrors({ mismatch: true });
      } else {
        this.getField('repeatPassword').setErrors(null);
      }
    });
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      try {
        this.isLoading = true;
        const password = this.getField('password').value;

        await this.authService.registrationLinkComplete({
          token: this.token,
          password,
          language: Utils.getBrowserLanguage(),
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
}
