import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { GeneralService } from '@shared/services/general.service';
import { Utils } from '@shared/utils/utils';
import { MessageIDS } from 'src/app/app.module';

@Component({
  selector: 'lgv-custom-input-field',
  template: `
    <mat-form-field [appearance]="appearance">
      <mat-label>
        {{ text | translate }}
      </mat-label>
      <input [required]="isRequired()" color="primary" matInput [formControl]="fc" [type]="type" />
      <mat-error *ngIf="fc.invalid">
        {{ errorMessage | translate }}
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      mat-form-field {
        flex-grow: 1;
      }
    `,
  ],
})
export class CustomInputFieldComponent {
  @Input() text: MessageIDS;
  @Input() appearance: MatFormFieldAppearance;
  @Input() type: 'text' | 'email' | 'password' | 'plz' | 'tel' = 'text';
  @Input() fc: UntypedFormControl;
  @Input() errorMessage: MessageIDS;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }
  }

  constructor(public generalService: GeneralService) {}

  isRequired(): boolean {
    return Utils.isRequiredControl(this.fc);
  }
}
