<form [formGroup]="formGroup" class="form">
  <lgv-custom-input-field
    text="shared_component_addressform_street_label"
    [fc]="getFormControls('street')"
    type="text"
    errorMessage="shared_component_addressform_street_error"
    [disabled]="innerDisabled"
  ></lgv-custom-input-field>
  <lgv-custom-input-field
    text="shared_component_addressform_zip_label"
    [fc]="getFormControls('zipCode')"
    type="text"
    errorMessage="shared_component_addressform_zip_error"
    [disabled]="innerDisabled"
  ></lgv-custom-input-field>
  <lgv-custom-input-field
    text="shared_component_addressform_city_label"
    [fc]="getFormControls('city')"
    type="text"
    errorMessage="shared_component_addressform_city_error"
    [disabled]="innerDisabled"
  ></lgv-custom-input-field>
  <lgv-custom-select-search
    [items]="countries"
    text="shared_component_addressform_country_label"
    [fc]="getFormControls('country')"
    type="text"
    errorMessage="shared_component_addressform_country_error"
    [disabled]="innerDisabled"
  ></lgv-custom-select-search>
</form>
