import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(private authService: AuthService) {}

  canActivate(): Promise<boolean | UrlTree> {
    return this.checkNotLoggedIn();
  }

  async checkNotLoggedIn(): Promise<boolean | UrlTree> {
    try {
      if (!this.authService.isLoggedIn()) {
        return true;
      }
      if (!this.authService.userInfo) {
        await this.authService.getUserInformation();
      }

      this.authService.routeToCorrectRoot();
    } catch (error) {
      return false;
    }

    return false;
  }
}
