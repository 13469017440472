import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetUserInfoResponse, PutUserInfoPayload } from '@schema/APITypes';
import { CustomImage } from '@schema/image.modules';
import { AuthService } from '@app/services/auth.service';
import { DocumentService } from '@app/services/document.service';
import { CustomValidators } from 'src/app/shared/validators/customvalidators';
import { LocalizationService } from '@app/services/localization.service';

export interface IAddEditUserInfoDialogData {
  currentUser?: GetUserInfoResponse;
}

@Component({
  selector: 'lgv-add-edit-userinfo-dialog',
  templateUrl: './add-edit-userinfo-dialog.component.html',
})
export class AddEditUserInfoDialogComponent {
  isLoading = false;
  image: CustomImage;

  formGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl(this.data.currentUser.firstname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    lastname: new UntypedFormControl(this.data.currentUser.lastname, [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    email: new UntypedFormControl(this.data.currentUser.email, [
      Validators.required,
      Validators.email,
      Validators.maxLength(2000),
    ]),
    scope: new UntypedFormControl({ value: this.data.currentUser.scopes[0], disabled: true }, [Validators.required]),
    language: new UntypedFormControl(this.data.currentUser.language, [Validators.required]),
  });

  roles = ['orgAdmin', 'caseManager'];

  constructor(
    private dialogRef: MatDialogRef<AddEditUserInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddEditUserInfoDialogData,
    private documentService: DocumentService,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {}

  onFileSelected(image: CustomImage): void {
    this.image = image;
  }

  close(): void {
    this.dialogRef.close();
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      try {
        this.isLoading = true;
        const user: PutUserInfoPayload = {
          firstname: this.formGroup.get('firstname').value,
          lastname: this.formGroup.get('lastname').value,
          email: this.formGroup.get('email').value,
          language: this.formGroup.get('language').value,
        };
        this.localizationService.changeLanguage(user.language);
        if (this.image) {
          const response = await this.documentService.postDocument(this.image.file);
          user.imageID = response.id;
        }

        await this.authService.putUserInformation(user);

        this.isLoading = false;
        this.dialogRef.close();
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
}
