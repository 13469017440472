import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { SnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'lgv-login',
  templateUrl: './login.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
      .form {
        min-height: 200px;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  isLoading = false;
  hidePassword = true;

  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private snackbarService: SnackbarService, private authService: AuthService) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((field) => {
      if (field.password.length < 8) {
        this.getField('password').setErrors({ notValid: true });
      } else {
        this.getField('password').setErrors(null);
      }
    });
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
    } else {
      try {
        this.isLoading = true;
        const username = this.getField('email').value;
        const password = this.getField('password').value;
        await this.authService.login({ password, username });
      } catch (error) {
        this.getField('password').setValue('');
        this.snackbarService.showError('auth_login_info_loginfailed');
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
}
