/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { SnackbarService } from '@shared/services/snackbar.service';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'lgv-upload-field',
  templateUrl: './upload-field.component.html',
  styleUrls: ['./upload-field.component.scss'],
})
export class UploadFieldComponent {
  @Input() fileTypes: string[];
  @Output() filesEmitter = new EventEmitter<File[]>();

  @ViewChild('fileInput') inputField: ElementRef;
  @ViewChild('fileUpload') fileUpload: ElementRef;

  selectedFile: File;
  errorMessage = false;
  filesNotSupported = [];
  backgroundColorHover = false;

  constructor(private snackBarService: SnackbarService) {}

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    if (!this.selectedFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.backgroundColorHover = true;
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    if (!this.selectedFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.backgroundColorHover = false;
    }
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    if (!this.selectedFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.backgroundColorHover = false;
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.handleFiles(files);
      }
    }
  }

  uploadClicked() {
    this.inputField.nativeElement.click();
  }

  onFileSelected(event) {
    this.handleFiles(event.target.files);
    this.inputField.nativeElement.value = null;
  }

  handleFiles(files: FileList) {
    this.errorMessage = false;
    this.filesNotSupported = [];
    this.selectedFile = this.checkArrayItem(files[0]);
    this.checkIfErrorMessageNeeded();
    if (this.selectedFile) {
      this.filesEmitter.emit([this.selectedFile]);
    }
  }

  clearSelectedFileClicked() {
    this.selectedFile = undefined;
  }

  checkArrayItem(item: File) {
    if (item.size > 20000000 || !this.fileTypes.includes(item.type)) {
      this.errorMessage = true;
      this.filesNotSupported.push(item.name);
      return undefined;
    } else {
      return item;
    }
  }

  checkIfErrorMessageNeeded() {
    if (this.errorMessage) {
      const result = ['Please upload a supported filetype ', 'with max size 2 MB!', ' Not supported files: '];
      let errorstring = result[0] + result[1];
      for (let i = 0; i < this.filesNotSupported.length; i++) {
        if (i === 0) {
          errorstring += result[2];
        }
        errorstring += this.filesNotSupported[i];
        if (!(i + 1 === this.filesNotSupported.length)) {
          errorstring += ', ';
        }
      }
      this.snackBarService.showError(errorstring);
    }
  }

  getHumanReadableFileSize(size: number) {
    return Utils.getHumanReadableSize(size);
  }
}
