import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PostForgotPasswordCompletePayload } from '@schema/APITypes';
import { AuthService } from '@app/services/auth.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { AUTH_PATHS_ABSOLUTE } from '@modules/auth/auth.paths';

@Component({
  selector: 'lgv-set-new-password',
  templateUrl: './set-new-password.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
      .form {
        min-height: 200px;
      }
    `,
  ],
})
export class SetNewPasswordComponent implements OnInit {
  isLoading = false;
  formGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
    repeatPassword: new UntypedFormControl('', [Validators.required]),
  });
  token = '';

  constructor(
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.token = this.activatedRoute.snapshot.queryParams.token;
  }

  ngOnInit(): void {
    if (!this.token) {
      this.snackbarService.showError('auth_setpassword_info_tokennotfound');
      this.router.navigateByUrl('/');
    }
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      try {
        this.isLoading = true;
        const password = this.getField('password').value;

        const payload: PostForgotPasswordCompletePayload = {
          token: this.token,
          password: password,
        };

        await this.authService.sendForgotPasswordEmailComplete(payload);
        this.snackbarService.showInfo('auth_setpassword_info_resetpasswordcomplete');
        this.router.navigateByUrl(AUTH_PATHS_ABSOLUTE.AUTH_LOGIN);
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
