import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageIDS } from 'src/app/app.module';

export interface IDeleteDialogData {
  title: MessageIDS;
  message: MessageIDS;
  button: MessageIDS;
  deleteFunction: () => Promise<void>;
}

export interface IDeleteDialogReturnData {
  message: 'ok' | 'cancel';
}

@Component({
  selector: 'lgv-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent, IDeleteDialogReturnData>,
    @Inject(MAT_DIALOG_DATA) public data: IDeleteDialogData
  ) {}

  ngOnInit(): void {
    if (!this.data.message) {
      this.data.message = 'shared_component_dialog_delete_message';
    }

    if (!this.data.button) {
      this.data.button = 'shared_component_dialog_delete_ok';
    }

    if (!this.data.title) {
      this.data.title = 'shared_component_dialog_delete_title';
    }
  }

  async submit(): Promise<void> {
    this.isLoading = true;
    try {
      await this.data.deleteFunction();
      this.dialogRef.close({ message: 'ok' });
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  close(): void {
    this.dialogRef.close({ message: 'cancel' });
  }
}
