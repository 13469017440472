<div class="form-wrapper">
  <form [formGroup]="formGroup">
    <input [ngClass]="{ 'custom-border': border }" [formControl]="search" [placeholder]="placeholder | translate" #input
      autocomplete="off" [class]="getAdditionalInputClasses()" />
  </form>
  <mat-icon class="input-icon material-icons-outlined" (click)="onSearchIconClicked()">{{ getMatIconText()
    }}</mat-icon>
</div>

<button *ngIf="actionList.length > 0" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon class="action-icon">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onActionClicked.emit(item.action)" *ngFor="let item of actionList">
    <mat-icon>{{ item.icon }}</mat-icon>
    {{ item.label | translate }}
  </button>
</mat-menu>