import { Injectable } from '@angular/core';

import { AuthService } from '@app/services/auth.service';
import { OrganisationFeaturePermission } from '@schema/APITypes';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authService: AuthService) {}

  hasPermissionObservable(permission: OrganisationFeaturePermission): Observable<boolean> {
    return from(this.hasPermissionPromise(permission));
  }

  async hasPermissionPromise(permission: OrganisationFeaturePermission): Promise<boolean> {
    if (!this.authService.userInfo) {
      await this.authService.getUserInformation();
    }

    return this.authService.userInfo.organisation.organisationFeaturePermission.includes(permission);
  }

  includeDomainObservable(domain: string): Observable<boolean> {
    return from(this.includeDomainPromise(domain));
  }
  async includeDomainPromise(domain: string): Promise<boolean> {
    if (!this.authService.userInfo) {
      await this.authService.getUserInformation();
    }

    return this.authService.userInfo.email.includes(domain);
  }
}
