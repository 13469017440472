<lgv-auth-wrapper text="auth_setpassword_headline">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill">
      <mat-label translate>auth_setpassword_password_label</mat-label>
      <input matInput formControlName="password" type="password" minlength="8" />
      <mat-error *ngIf="this.getField('password').hasError('minlength')" translate>
        auth_setpassword_password_error
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label translate>auth_setpassword_repeatpassword_label</mat-label>
      <input matInput formControlName="repeatPassword" type="password" />
      <mat-error *ngIf="this.getField('repeatPassword').hasError('mismatch')" translate>
        auth_setpassword_repeatpassword_error
      </mat-error>
    </mat-form-field>
    <lgv-auth-button [isLoading]="isLoading"></lgv-auth-button>
  </form>
  <button mat-button color="primary" routerLink="/login">
    {{ 'auth_setpassword_repeatpassword_button_login' | translate }}
  </button>
</lgv-auth-wrapper>
