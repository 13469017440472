import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';
import { NoAuthGuard } from '@app/guards/no-auth.guard';
import { AUTH_PATHS } from './auth.paths';

import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { RegistrationLinkComponent } from './pages/registration-link/registration-link.component';
import { SetNewPasswordComponent } from './pages/set-new-password/set-new-password.component';

export const AUTH_ROUTES: Routes = [
  { path: AUTH_PATHS.AUTH_ROOT, redirectTo: AUTH_PATHS.AUTH_LOGIN, pathMatch: 'full' },
  { path: AUTH_PATHS.AUTH_LOGIN, canActivate: [NoAuthGuard], component: LoginComponent },
  { path: AUTH_PATHS.AUTH_LOGOUT, canActivate: [AuthGuard], component: LogoutComponent },
  { path: AUTH_PATHS.AUTH_FORGOT_PASSWORD, canActivate: [NoAuthGuard], component: ForgotPasswordComponent },
  { path: AUTH_PATHS.AUTH_SET_NEW_PASSWORD, component: SetNewPasswordComponent },
  { path: AUTH_PATHS.AUTH_REGISTRATION_LINK, component: RegistrationLinkComponent },
];

@NgModule({
  imports: [RouterModule.forChild(AUTH_ROUTES)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
