import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth.routing';
import { LoginComponent } from './pages/login/login.component';
import { SetNewPasswordComponent } from './pages/set-new-password/set-new-password.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthFooterComponent } from '@modules/auth/components/auth-footer/auth-footer.component';
import { AuthWrapperComponent } from '@modules/auth/components/auth-wrapper/auth-wrapper.component';
import { RegistrationLinkComponent } from './pages/registration-link/registration-link.component';
import { TranslateModule } from '@ngx-translate/core';

import { AuthButtonComponent } from './components/auth-button/auth-button.component';
import { GenericTableModule } from '@shared/shared-modules/generic-table/generic-table.module';

@NgModule({
  declarations: [
    LoginComponent,
    SetNewPasswordComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    AuthWrapperComponent,
    AuthFooterComponent,
    RegistrationLinkComponent,
    AuthButtonComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, SharedModule, TranslateModule, GenericTableModule],
})
export class AuthModule {}
