<lgv-dialog-wrapper
  [titleText]="data.user ? 'shared_component_dialog_user_edituser' : 'shared_component_dialog_user_adduser'"
>
  <lgv-profile-image-picker
    (fileChanged)="onFileSelected($event)"
    [imageURL]="data.user?.imageID | getDocument | async"
    style="justify-content: center; margin-bottom: 32px"
  ></lgv-profile-image-picker>

  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label translate>shared_component_dialog_user_firstname</mat-label>
      <input matInput formControlName="firstname" />
      <mat-error *ngIf="this.getField('firstname').invalid">
        {{ 'shared_component_dialog_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>shared_component_dialog_user_lastname</mat-label>
      <input matInput formControlName="lastname" />
      <mat-error *ngIf="this.getField('lastname').invalid">
        {{ 'shared_component_dialog_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>shared_component_dialog_user_role</mat-label>
      <mat-select formControlName="scope">
        <mat-option *ngFor="let item of roles" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="this.getField('scope').hasError('required')">
        {{ 'shared_component_dialog_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>shared_component_dialog_user_email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="this.getField('email').invalid">
        {{ 'shared_component_dialog_user_required' | translate }}
      </mat-error>
    </mat-form-field>

    <lgv-button-container
      buttonLeftText="shared_component_dialog_user_buttonleft"
      buttonRightText="shared_component_dialog_user_buttonright"
      buttonRightType="submit"
      [disabled]="isLoading"
      (onLeftClicked)="close()"
    >
    </lgv-button-container>
  </form>
</lgv-dialog-wrapper>
