import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimemanagementService } from '@app/services/timemanagement.service';
import {
  CalendarData,
  EventObject,
  ExtendedPostTimemanagementCalendarResponseItem,
} from '@modules/dashboard/components/calendar-pro/calendar.pro.helper';
import { TranslateService } from '@ngx-translate/core';
import {
  GetRequestListResponseItem,
  GetServiceResponseItem,
  PostTimemanagementCalendarPayload,
  PostTimemanagementCalendarResponse,
  TimemanagementItem,
} from '@schema/APITypes';
import { ColoringService } from '@shared/services/color-toolkit.service';
import { Utils } from '@shared/utils/utils';
import { addDays, endOfWeek, isBefore } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lgv-print-calendar',
  template: `
    <lgv-print-calendar-item
      [eventsChanged]="eventsChanged"
      [isClientMode]="isClientMode"
      [currentDate]="date"
      *ngFor="let date of calendarDateList"
    >
    </lgv-print-calendar-item>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
    `,
  ],
})
export class PrintCalendarComponent implements OnInit {
  eventsChanged = new BehaviorSubject<CalendarData>(null);
  calendarDateList: Date[] = [];
  isClientMode = false;
  constructor(
    private route: ActivatedRoute,
    private timemanagementService: TimemanagementService,
    private coloringService: ColoringService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const from = new Date(params.from);
      const to = new Date(params.to);
      to.setSeconds(0);
      to.setMilliseconds(0);
      const fromCopy = new Date(from);
      fromCopy.setDate(fromCopy.getDate() + 1);
      this.calendarDateList.push(fromCopy);
      while (
        isBefore(
          Utils.getUTCDate(endOfWeek(this.calendarDateList[this.calendarDateList.length - 1], { weekStartsOn: 1 })),
          to,
        )
      ) {
        // const calcValue = Utils.getUTCDate(
        //   endOfWeek(this.calendarDateList[this.calendarDateList.length - 1], { weekStartsOn: 1 })
        // );
        this.calendarDateList.push(addDays(this.calendarDateList[this.calendarDateList.length - 1], 7));
      }

      this.timemanagementService.colorByEmployee.next(params.employee ? false : true);
      this.isClientMode = params.client ? true : false;

      // add one day to from
      // from.setDate(from.getDate() + 1);

      const payload: PostTimemanagementCalendarPayload = {
        clientIDs: params.client ? [params.client] : [],
        employeeIDs: params.employee ? [params.employee] : [],
        from,
        to,
      };
      const response = await this.timemanagementService.postTimemanagementCalendar(payload);
      const transTmTypeKeys = await Utils.getTmTypeTranslationKeys(this.translate);
      const data = this.formatEventData(response, transTmTypeKeys, from, to);
      document.title = 'Calendar Print ' + params.from + ' - ' + params.to;
      this.eventsChanged.next(data);
      setTimeout(() => {
        window.print();
      }, 3500);
      window.onafterprint = () => {
        window.close();
      };
    });
  }

  formatEventData(
    apiData: PostTimemanagementCalendarResponse,
    transTmTypeKeys: {
      [key: string]: string;
    },
    filterFrom: Date,
    filterTo: Date,
  ): CalendarData {
    const eventRows: EventObject[] = [];
    const resourcesRows = [];
    const timeManagementItems = apiData.timemanagementItems;
    const allEvents: ExtendedPostTimemanagementCalendarResponseItem[] = [
      ...apiData.appointments.map((item: ExtendedPostTimemanagementCalendarResponseItem, index) => {
        item.eventId = `appointment-${index}`;
        return item;
      }),
      ...apiData.blocked.map((item: ExtendedPostTimemanagementCalendarResponseItem, index) => {
        item.eventId = `blocked-${index}`;
        return item;
      }),
    ];
    allEvents.map((event) => {
      const employee = event.employee;
      const client = event.client;

      const tm = timeManagementItems.find((x) => x.id === event.timemanagementID);

      if (
        !this.timemanagementService.colorByEmployee.getValue() &&
        client &&
        !resourcesRows.find((x) => x.id === client.id)
      ) {
        resourcesRows.push({
          id: client.id,
          name: Utils.getRequestFullName(client, true),
        });
      }

      if (
        this.timemanagementService.colorByEmployee.getValue() &&
        employee &&
        !resourcesRows.find((x) => x.id === employee.id)
      ) {
        resourcesRows.push({
          id: employee.id,
          name: Utils.getRequestFullName(employee, true),
        });
      }

      if (
        this.timemanagementService.colorByEmployee.getValue() &&
        !employee &&
        !resourcesRows.find((x) => x.id === client.id)
      ) {
        resourcesRows.push({
          id: client.id,
          name: Utils.getRequestFullName(client, true),
        });
      }

      const eventObj: EventObject = {
        timemanagementId: event.timemanagementID,
        startDate: event.from,
        endDate: event.to,
        name: this.getCalendarEventName(tm, client, transTmTypeKeys, employee),
        resourceId: this.timemanagementService.colorByEmployee.getValue() && employee ? employee.id : client.id,
        employee: employee,
        client: client,
        service: tm.service,
        type: tm.tmType,
        eventId: event.eventId,
        notes: tm.notes,
      };

      eventObj.eventColor = !!employee ? this.coloringService.getCalendarColorForAppointment(event) : '#808080';
      eventRows.push(eventObj);
    });
    return {
      success: true,
      resources: { rows: resourcesRows },
      events: {
        rows: eventRows.filter((event) => {
          // startdate or enddate is in the range of the filter
          const result =
            (new Date(event.startDate) >= new Date(filterFrom) && new Date(event.startDate) <= new Date(filterTo)) ||
            (new Date(event.endDate) >= new Date(filterFrom) && new Date(event.endDate) <= new Date(filterTo));
          // console.log('result', result);
          // console.log('event.startDate', event.startDate);
          // console.log('event.endDate', event.endDate);
          // console.log('------');
          // console.log(new Date(event.startDate));
          // console.log(new Date(event.endDate));
          return result;
        }),
      },
    };
  }

  getServiceWiithoutNumber(service: GetServiceResponseItem): string {
    const serviceSplit = service.name.split('-');
    if (serviceSplit.length === 1) {
      return service.name;
    } else {
      return service.name.split('-')[1].trim();
    }
  }

  getCalendarEventName(
    tm: TimemanagementItem,
    client: GetRequestListResponseItem,
    transTmTypeKeys: {
      [key: string]: string;
    },
    employee?: GetRequestListResponseItem,
  ): string {
    if (!employee) {
      return `${Utils.getRequestFullName(client, true)} - ${transTmTypeKeys[tm.tmType]}`;
    }
    const serviceName = this.getServiceWiithoutNumber(tm.service);
    if (this.isClientMode) {
      return `${Utils.getRequestLastnameWithFirstnameShort(employee)}` + ` - ${serviceName}`;
    } else {
      return `${Utils.getRequestLastnameWithFirstnameShort(client)}` + ` - ${serviceName}`;
    }
  }
}
