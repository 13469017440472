import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { environment } from '@environment/environment';
import { GeneralService } from '@shared/services/general.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  LOGIN_ROUTE = 'auth/login';
  STATUS_CODE_FORBIDDEN = 401;
  REFRESH_ROUTE = 'auth/refresh';
  constructor(
    private router: Router,
    private snackbarService: SnackbarService,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialog,
    private http: HttpClient,
    private generalService: GeneralService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          console.error(error);
          errorMessage = 'global_errorhandling_client';
          this.snackbarService.showError(errorMessage);

          // errorMessage = `Error: ${error.error.message}`;
        } else {
          if (error.status === 0) {
            errorMessage = 'global_errorhandling_api';
            this.apiService.resetAuth();
            this.router.navigateByUrl(this.LOGIN_ROUTE);
          } else if (error.status === 401) {
            if (error.url.includes('api/v1/auth')) {
              errorMessage = 'global_errorhandling_pleaseloginagain';
              this.apiService.resetAuth();
              this.dialogRef.closeAll();
              this.router.navigateByUrl(this.LOGIN_ROUTE);
            } else {
              console.log(
                `%cHandling "Forbidden ${this.STATUS_CODE_FORBIDDEN}" error from API...`,
                'background: #eee; color: #666;',
              );
              return this.http
                .post(environment.API_BASE_URL + this.REFRESH_ROUTE, {
                  // eslint-disable-next-line camelcase
                  refresh_token: this.localStorageService.get('refreshToken'),
                })
                .pipe(
                  switchMap((response: any) => {
                    // If reload successful update tokens
                    if (response) {
                      // Update tokens
                      this.apiService.saveTokens(response);
                      // Clone our fieled request ant try to resend it
                      request = request.clone({
                        setHeaders: {
                          Authorization: `Bearer ${this.localStorageService.get('accessToken')}`,
                        },
                      });
                      return next.handle(request);
                    } else {
                      // Logout from account
                      this.apiService.resetAuth();
                      this.dialogRef.closeAll();
                      this.router.navigateByUrl(this.LOGIN_ROUTE);
                    }
                  }),
                );
            }
          } else {
            // server-side error
            let message = error.name;
            if (error.error?.title) {
              message = error.error.title;
            }
            errorMessage = `Error Code: ${error.status}\nMessage: ${message}`;
            console.error(error);
            this.snackbarService.showError(errorMessage);
          }
        }
        this.generalService.handeledByHTTPIntercepter = true;
        return throwError(() => errorMessage);
      }),
    );
  }
}
