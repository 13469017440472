<div
  #fileUpload
  class="excel-upload"
  [ngClass]="{
    backgroundColorHover: backgroundColorHover,
    backgroundColor: !backgroundColorHover
  }"
>
  <!--no file selected-->
  <div class="center-container" *ngIf="!selectedFile" (click)="uploadClicked()">
    <mat-icon class="material-icons-outlined">file_upload</mat-icon>
    <span [innerHTML]="'shared_component_uploadfield_dragfile' | translate"></span>
  </div>
  <div class="bottom-container" *ngIf="!selectedFile">
    <span translate>shared_component_uploadfield_error</span>
  </div>
  <!--File Selected-->
  <div class="close-container" [style.visibility]="selectedFile ? 'visible' : 'hidden'">
    <button mat-button (click)="clearSelectedFileClicked()" *ngIf="selectedFile" class="close-button">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
  </div>
  <div class="center-container" *ngIf="selectedFile">
    <mat-icon class="material-icons-outlined">description</mat-icon>
    <span>{{ selectedFile.name }}</span>
    <p>{{ getHumanReadableFileSize(selectedFile.size) }}</p>
  </div>

  <input
    style="display: none"
    #fileInput
    type="file"
    [multiple]="false"
    [accept]="fileTypes.join(',')"
    (change)="onFileSelected($event)"
  />
</div>
