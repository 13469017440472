import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SplitOperator, TimemanagementItem } from '@schema/APITypes';
import { getWeek } from 'date-fns';
import { RRule } from 'rrule';
import { MessageIDS } from 'src/app/app.module';

export interface IEditDeleteRecurringDialogData {
  title: MessageIDS;
  isFromDragDropAndOtherDay?: boolean;
  splitUntilMinDate?: Date;
  tm: TimemanagementItem;
}

export interface IEditDeleteRecurringDialogResult {
  splitOperator: SplitOperator;
  untilDate: Date | null;
}

@Component({
  selector: 'lgv-edit-delete-recurring-dialog',
  templateUrl: './edit-delete-recurring-dialog.component.html',
  styles: [
    `
      .button-container {
        display: flex;
        justify-content: flex-end;
      }

      .radio-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        user-select: none;
      }

      .radio-button {
        margin: 5px;
      }

      .today-date-class {
        border: 1px solid orange;
        border-radius: 100%;
      }

      .event-date-class {
        border: 1px solid green;
        border-radius: 100%;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EditDeleteRecurringDialogComponent {
  options: { key: string; value: MessageIDS }[] = [
    { key: 'one', value: 'dashboard_dialogs_editdeleterecurring_one' },
    { key: 'next', value: 'dashboard_dialogs_editdeleterecurring_next' },
    { key: 'until', value: 'dashboard_dialogs_editdeleterecurring_until' },
    { key: 'all', value: 'dashboard_dialogs_editdeleterecurring_all' },
  ];
  selectedOption: SplitOperator = 'one';
  untilDate: Date | null = null;
  untilDateError = false;
  constructor(
    private dialogRef: MatDialogRef<EditDeleteRecurringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditDeleteRecurringDialogData
  ) {}

  onOkClicked(): void {
    if (this.selectedOption === 'until' && this.untilDate === null) {
      this.untilDateError = true;
      return;
    } else {
      this.untilDateError = false;
    }

    if (this.untilDate) {
      // convert to UTC with same day
      this.untilDate = new Date(
        Date.UTC(this.untilDate.getFullYear(), this.untilDate.getMonth(), this.untilDate.getDate())
      );
    }
    const result: IEditDeleteRecurringDialogResult = {
      splitOperator: this.selectedOption,
      untilDate: this.untilDate,
    };
    this.dialogRef.close(result);
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  myFilter = (d: Date | null): boolean => {
    if (this.data.tm.frequencyType === 'weekly' && this.data.tm.interval > 1) {
      return this.weeklyFilter(d);
    }

    // if (this.data.tm.frequencyType === 'daily' && this.data.tm.interval > 1) {
    //   return this.dailyFilter(d);
    // }
    return true;
  };

  // dailyFilter = (d: Date | null): boolean => {
  //   // this.data.tm.dtStart start date
  //   // d?.setDate(d?.getDate() - 1);
  //   let dayDiff = Math.floor((d?.getTime() - new Date(this.data.tm.dtStart).getTime()) / (1000 * 60 * 60 * 24));
  //   dayDiff -= 1;
  //   if (dayDiff < 0) {
  //     return false;
  //   }
  //   const dayDiffMod = dayDiff % this.data.tm.interval;
  //   if (dayDiffMod !== 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  weeklyFilter = (d: Date | null): boolean => {
    // this.data.tm.dtStart start date
    const week = getWeek(new Date(this.data.tm.dtStart), { weekStartsOn: 1 });
    const weekOfDate = getWeek(d, {
      weekStartsOn: 1,
    });

    // calc if week is possible with interval
    const weekDiff = weekOfDate - week;
    if (weekDiff === 0) {
      return true;
    }
    const weekDiffMod = weekDiff % this.data.tm.interval;
    if (weekDiffMod !== 0) {
      return false;
    } else {
      return true;
    }
  };

  dateClass = (d: Date) => {
    if (
      this.data.splitUntilMinDate.getMonth() === d.getMonth() &&
      this.data.splitUntilMinDate.getDate() === d.getDate()
    ) {
      return 'today-date-class';
    }

    // check if date is in the rrule

    const rruleObject = {
      freq: this.data.tm.frequencyType === 'weekly' ? RRule.WEEKLY : RRule.DAILY,
      interval: this.data.tm.interval,
      dtstart: new Date(this.data.tm.dtStart),
      byweekday: [],
      until: null,
    };

    if (this.data.tm.frequencyType === 'weekly') {
      rruleObject.byweekday = this.data.tm.weekdays.map((wd) => {
        switch (wd) {
          case 0:
            return RRule.MO;
          case 1:
            return RRule.TU;
          case 2:
            return RRule.WE;
          case 3:
            return RRule.TH;
          case 4:
            return RRule.FR;
          case 5:
            return RRule.SA;
          case 6:
            return RRule.SU;
        }
      });
    }

    if (this.data.tm.until) {
      rruleObject.until = new Date(this.data.tm.until);
    }

    const rule = new RRule(rruleObject);
    const result = rule.between(
      new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1),
      new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
    );
    for (const date of result) {
      if (date.getDate() === d.getDate()) {
        return 'event-date-class';
      }
    }
  };
}
