<lgv-dialog-wrapper [titleText]="
    data.organisation
      ? 'shared_component_dialog_organisation_editorganisation'
      : 'shared_component_dialog_organisation_addorganisation'
  ">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label translate>shared_component_dialog_organisation_name</mat-label>
      <input matInput formControlName="name" autocomplete="off" />
      <mat-error *ngIf="this.getField('name').invalid" translate>
        shared_component_dialog_organisation_error
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate>shared_component_dialog_organisation_companynumber</mat-label>
      <input matInput formControlName="companyNumber" autocomplete="off" />
      <mat-error *ngIf="this.getField('companyNumber').invalid" translate>
        shared_component_dialog_organisation_error
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>shared_component_dialog_organisation_website</mat-label>
      <input matInput formControlName="website" autocomplete="off" />
      <mat-error *ngIf="this.getField('website').invalid" translate>
        shared_component_dialog_organisation_error
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate>shared_component_dialog_organisation_phoneNumber</mat-label>
      <input matInput formControlName="phoneNumber" autocomplete="off" />
      <mat-error *ngIf="this.getField('phoneNumber').invalid" translate>
        shared_component_dialog_organisation_error
      </mat-error>
    </mat-form-field>

    <lgv-address-form [address]="data.organisation?.address"
      (formGroupOuput)="getAddressFormGroup($event)"></lgv-address-form>

    <lgv-button-container buttonLeftText="shared_component_dialog_organisation_buttonleft"
      buttonRightText="shared_component_dialog_organisation_buttonright" buttonRightType="submit" [disabled]="isLoading"
      (onLeftClicked)="close()">
    </lgv-button-container>
  </form>
</lgv-dialog-wrapper>