import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsGuard } from '@app/guards/cms.guard';
import { OrgGuard } from '@app/guards/org.guard';
import { AUTH_PATHS_ABSOLUTE } from '@modules/auth/auth.paths';
import { APP_PATHS } from './app.paths';
import { AdminWrapperComponent } from './wrapper/admin-wrapper/admin-wrapper.component';
import { DashboardWrapperComponent } from './wrapper/dashboard-wrapper/dashboard-wrapper.component';
import { NotFoundComponent } from './wrapper/not-found/not-found.component';

const routes: Routes = [
  {
    path: APP_PATHS.ROOT,
    redirectTo: AUTH_PATHS_ABSOLUTE.AUTH_LOGIN,
    pathMatch: 'full',
  },
  {
    path: APP_PATHS.DASHBOARD,
    canActivate: [OrgGuard],
    data: { breadcrumb: 'dashboard_routing_root' },
    component: DashboardWrapperComponent,
    loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: APP_PATHS.ADMIN,
    canActivate: [CmsGuard],
    data: { breadcrumb: 'admin_routing_root' },
    component: AdminWrapperComponent,
    loadChildren: () => import('@modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: APP_PATHS.PRINT,
    loadChildren: () => import('@modules/print/print.module').then((m) => m.PrintModule),
  },
  {
    path: APP_PATHS.FORM,
    loadChildren: () => import('@modules/form/form.module').then((m) => m.FormModule),
  },
  {
    path: APP_PATHS.AUTH,
    loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
