<div class="root-container">
  <div class="sub-root-container">
    <div class="left-container">
      <div>{{ 'auth_wrapper_welcome' | translate | uppercase }}</div>
      <div class="auth-text2" translate>auth_wrapper_welcome_text</div>
    </div>

    <div class="right-container">
      <div class="black-box">
        <div style="display: flex; flex-direction: column">
          <div style="display: flex; justify-content: center; min-height: 90px">
            <img src="assets/images/logo-color.png" width="80%" />
          </div>
          <div class="headline3" style="padding: 30px 0; text-align: center" translate>
            {{ text | translate }}
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<lgv-auth-footer></lgv-auth-footer>
