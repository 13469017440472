import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Address } from '@schema/APITypes';
import { CustomValidators } from '@shared/validators/customvalidators';
import * as countryList from 'country-list';
import { KeyValueItem } from '../custom-form-field/custom-autocomplete/custom-autocomplete.component';

@Component({
  selector: 'lgv-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() address: Address;
  @Input() set disabled(value: boolean) {
    this.innerDisabled = value;
  }
  @Output() formGroupOuput = new EventEmitter<UntypedFormGroup>();
  formGroup = new UntypedFormGroup({
    street: new UntypedFormControl('', [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    zipCode: new UntypedFormControl('', [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    city: new UntypedFormControl('', [
      Validators.required,
      CustomValidators.notOnlyWhiteSpaceValidator,
      Validators.maxLength(2000),
    ]),
    country: new UntypedFormControl('', [Validators.required]),
  });

  countries: KeyValueItem[] = Object.keys(countryList.getCodeList()).map((c) => {
    return {
      key: countryList.getCodeList()[c],
      value: countryList.getCodeList()[c],
    } as KeyValueItem;
  });

  innerDisabled: boolean;

  ngOnInit(): void {
    if (this.address) {
      this.formGroup.get('street').setValue(this.address.street);
      this.formGroup.get('zipCode').setValue(this.address.zipCode);
      this.formGroup.get('city').setValue(this.address.city);
      this.formGroup.get('country').setValue(this.address.country);
    }
    this.formGroupOuput.emit(this.formGroup);
  }

  getField(name: string): AbstractControl {
    return this.formGroup.get(name);
  }

  getFormControls(str: string): UntypedFormControl {
    return this.formGroup.controls[str] as UntypedFormControl;
  }
}
